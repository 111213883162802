import {Modal} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import {CountdownCircleTimer, TimeProps} from 'react-countdown-circle-timer';
import warnIcon from '../../../../../assets/images/icons/confirm-modal.svg';

interface Props {
	show: boolean;
	remainingSeconds: number;
	handleStillHere: () => void;
}

const IdleLogoutModal = ({show, remainingSeconds, handleStillHere}: Props) => {
	const renderTime = ({remainingTime, color}: TimeProps) => {
		if (remainingTime === 0) {
			return (
				<div className='fs-2 fw-bold' style={{color}}>
					Time's Up!
				</div>
			);
		}

		return (
			<div className='timer'>
				<div className='fs-2x fw-bolder' style={{color}}>
					{remainingTime}
				</div>
				<div className='text'>{remainingTime < 2 ? 'second' : 'seconds'}</div>
			</div>
		);
	};

	return (
		<Modal
			id='kt_modal_idle_logout_confirm'
			tabIndex={-1}
			aria-hidden='true'
			dialogClassName='modal-dialog modal-dialog-centered'
			show={show}
			onHide={handleStillHere}
			backdrop
		>
			<div className='modal-body scroll-y text-center'>
				<div className='container pt-4'>
					<div className='mb-12'>
						<SVG src={warnIcon} />
					</div>
					<p className='fs-4 mb-8' style={{color: '#3F4254'}}>
						You've been inactive for a long period of time. You'll be logged out automatically in
					</p>
					<div className='d-flex justify-content-center'>
						<CountdownCircleTimer
							isPlaying
							duration={remainingSeconds}
							colors={['#004777', '#F7B801', '#A30000', '#A30000']}
							colorsTime={[remainingSeconds / 2, remainingSeconds / 4, remainingSeconds / 6, 0]}
							onComplete={() => ({shouldRepeat: false})}
						>
							{renderTime}
						</CountdownCircleTimer>
					</div>
				</div>
			</div>

			<div className='modal-footer justify-content-center border-top-0'>
				<button
					type='button'
					className='btn px-5 py-3 fw-semibold fs-4 rounded-3 mx-4'
					style={{
						background: '#47BE7D',
						color: '#FFFFFF',
						border: '1px solid #47BE7D',
					}}
					onClick={handleStillHere}
				>
					I'm still here
				</button>
			</div>
		</Modal>
	);
};

export default IdleLogoutModal;
