export function arrayLast<T>(array: Array<T>) {
	return array[array.length - 1];
}

export function range(size: number, startAt = 1) {
	return Array(size)
		.fill(0)
		.map((v, i) => i + startAt);
}

export function addOrRemove<T>(arr: Array<T>, el: T) {
	const idx = arr.indexOf(el);
	if (idx >= 0) {
		arr.splice(idx, 1);
	} else {
		arr.push(el);
	}
	return [...arr];
}
