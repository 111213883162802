import {Modal} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import warnIcon from '../../../../assets/images/icons/confirm-modal.svg';
import InButtonLoader from '../InButtonLoader';

interface Props {
	id: string;
	message: string;
	show: boolean;
	onClose: (choice: boolean) => void;
	isLoading?: boolean;
	showIcon?: boolean;
	confirmColor: 'red' | 'white' | 'blue';
	confirmText: string;
	cancelColor: 'green' | 'white' | 'blue';
	cancelText: string;
}

export default function ConfirmModal({
	id,
	message,
	show,
	confirmColor,
	confirmText,
	cancelColor,
	cancelText,
	onClose,
	showIcon = false,
	isLoading = false,
}: Props) {
	const getCancelBtnBgColor = () => {
		if (cancelColor === 'blue') return '#0095E8';
		if (cancelColor === 'white') return '#FFFFFF';
		return '#47BE7D';
	};

	const getCancelBtnColor = () => {
		if (cancelColor === 'blue') return '#FFFFFF';
		if (cancelColor === 'white') return '#0095E8';
		return '#FFFFFF';
	};

	const getCancelBtnBorderColor = () => {
		if (cancelColor === 'blue') return '#0095E8';
		if (cancelColor === 'white') return '#0095E8';
		return '#47BE7D';
	};

	const getConfirmBtnBgColor = () => {
		if (confirmColor === 'blue') return '#0095E8';
		if (confirmColor === 'white') return '#FFFFFF';
		return '#D9214E';
	};

	const getConfirmBtnColor = () => {
		if (confirmColor === 'blue') return '#FFFFFF';
		if (confirmColor === 'white') return '#0095E8';
		return '#FFFFFF';
	};

	const getConfirmBtnBorderColor = () => {
		if (confirmColor === 'blue') return '#0095E8';
		if (confirmColor === 'white') return '#0095E8';
		return '#D9214E';
	};

	return (
		<Modal
			id={`kt_modal_confirm_${id}`}
			tabIndex={-1}
			aria-hidden='true'
			dialogClassName='modal-dialog modal-dialog-centered'
			show={show}
			onHide={() => onClose(false)}
			backdrop
		>
			<div className='modal-body scroll-y text-center'>
				<div className='container pt-4'>
					{showIcon && (
						<div className='mb-12'>
							<SVG src={warnIcon} />
						</div>
					)}
					<div className='fs-1 fw-semibold mb-4' style={{color: '#3F4254'}}>
						Are you sure?
					</div>
					<p className='fs-4' style={{color: '#3F4254'}}>
						{message}
					</p>
				</div>
			</div>

			<div className='modal-footer justify-content-center border-top-0'>
				<button
					type='button'
					className='btn px-5 py-3 fw-semibold fs-4 rounded-3 mx-4'
					style={{
						background: getCancelBtnBgColor(),
						color: getCancelBtnColor(),
						border: `1px solid ${getCancelBtnBorderColor()}`,
					}}
					onClick={() => onClose(false)}
					disabled={isLoading}
				>
					{cancelText}
				</button>
				<button
					type='button'
					className='btn px-5 py-3 fw-semibold fs-4 rounded-3 mx-4'
					style={{
						background: getConfirmBtnBgColor(),
						color: getConfirmBtnColor(),
						border: `1px solid ${getConfirmBtnBorderColor()}`,
					}}
					onClick={() => onClose(true)}
					disabled={isLoading}
				>
					<InButtonLoader isLoading={isLoading} showIcon showText>
						{confirmText}
					</InButtonLoader>
				</button>
			</div>
		</Modal>
	);
}
