import {useEffect} from 'react';
import {useNavigate, useSearchParams} from 'react-router-dom';
import DataTable from '../../common/components/data-table/DataTable';
import {Header, Row} from '../../common/components/data-table/Types';
import {PageLink, PageTitle} from '../../common/providers/PageDataProvider';
import CardHeader from '../users/common/components/CardHeader';
import './MauallyVerifiedList.css';
import WhiteCard from '../../common/components/card/WhiteCard';
import {ManuallyVerifiedListType} from './MauallyVerifiedListType';
import useManuallyVerifiedList from './useManuallyVerifiedList';
import MauallyVerifiedListReportFilter from './ManuallyVerifiedListReportFilter';

const breadcrumbs: Array<PageLink> = [
	{
		title: 'Report',
		path: '/ekyc-reports',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const ManuallyVerifiedList = () => {
	const {
		data,
		isLoading,
		dateRange,
		downloadUrl,
		setCurrentPage,
		changeItemPerPage,
		setDateRange,
		setStatus,
		changeQuery,
	} = useManuallyVerifiedList();

	const headers: Array<Header> = [
		{
			text: 'Reference ID',
			isSortable: false,
		},
		{
			text: 'Wallet No',
			isSortable: false,
		},
		{
			text: 'NID',
			isSortable: false,
		},
		{
			text: 'Date of Birth',
			isSortable: false,
		},
		{
			text: 'Status',
			isSortable: false,
		},
		{
			text: 'Verification Time',
			isSortable: false,
		},
	];

	const rows: Array<Row> = (data?.data || []).map((item: ManuallyVerifiedListType) => {
		return {
			key: item.referenceId,
			items: [item.referenceId, item.wallet, item.nid, item.dob, item.status, item.verificationTime],
		};
	});

	const [searchParams, setSearchParams] = useSearchParams();
	const status = searchParams.get('status');
	const navigate = useNavigate();

	useEffect(() => {
		window.onload = function () {
			navigate('/manually-verified-list');
		};
	}, [navigate]);

	return (
		<div>
			<PageTitle breadcrumbs={[]}>Manually Verified List Report</PageTitle>
			<MauallyVerifiedListReportFilter
				dateRange={dateRange}
				setDateRange={setDateRange}
				setStatus={setStatus}
				changeQuery={changeQuery}
			/>

			{status && (
				<div className='alert  alert-success alert-dismissible fade show' role='alert'>
					File uploaded successfully! Posow Verification is in process!
					<button
						onClick={() => {
							navigate('/manually-verified-list');
						}}
						type='button'
						className='btn-close'
						data-bs-dismiss='alert'
						aria-label='Close'
					/>
				</div>
			)}

			<WhiteCard
				leftHeader={<CardHeader title='Manually Verified List Report' />}
				rightHeader={
					<button
						className='btn btn-primary'
						onClick={() => {
							window.open(downloadUrl, '_blank');
						}}
					>
						Download Report
					</button>
				}
				extraClasses='p-9'
			>
				<div className='merchant-table'>
					<DataTable
						headers={headers}
						isLoading={isLoading}
						data={rows}
						changeItemPerPage={changeItemPerPage}
						meta={data?.meta}
						links={data?.links}
						setCurrentPage={setCurrentPage}
					/>
				</div>
			</WhiteCard>
		</div>
	);
};

export default ManuallyVerifiedList;
