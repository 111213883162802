import {useState} from 'react';
import {StatusCounter} from '../Types';
import Card from './Card';
import './Chart.css';
import CounterCard from './CounterCard';
import Donut from './Donut';

interface Props {
	product: string;
	counters: StatusCounter;
}

export default function ProductStatusCard({product, counters}: Props) {
	const {in_queue: inQueue, accepted, rejected, escalated} = counters;
	const [isTodaySelected, setIsTodaySelected] = useState(true);

	const todayProductStatusSeries = [
		inQueue.today_count,
		accepted.today_count,
		escalated.today_count,
		rejected.today_count,
	];
	const yesterdayProductStatusSeries = [
		inQueue.yesterday_count,
		accepted.yesterday_count,
		escalated.yesterday_count,
		rejected.yesterday_count,
	];

	return (
		<Card title={product} setIsTodaySelected={setIsTodaySelected} isTodaySelected={isTodaySelected}>
			<div className='row d-flex flex-row'>
				<div className='col-4'>
					<Donut
						series={isTodaySelected ? todayProductStatusSeries : yesterdayProductStatusSeries}
						labels={['In queue', 'Accepted', 'Escalated', 'Rejected']}
					/>
				</div>
				<div className='col-4'>
					<CounterCard
						count={isTodaySelected ? inQueue.today_count : inQueue.yesterday_count}
						status='In queue'
						color='#0095E8'
						background='#ECF8FF'
						marginBottom
					/>
					<CounterCard
						count={isTodaySelected ? rejected.today_count : rejected.yesterday_count}
						status='Rejected'
						color='#D9214E'
						background='#FFF5F8'
					/>
				</div>
				<div className='col-4'>
					<CounterCard
						count={isTodaySelected ? accepted.today_count : accepted.yesterday_count}
						status='Accepted'
						color='#47BE7D'
						background='#E8FFF3'
						marginBottom
					/>
					<CounterCard
						count={isTodaySelected ? escalated.today_count : escalated.yesterday_count}
						status='Escalated'
						color='#F79B11'
						background='#FFF5E7'
					/>
				</div>
			</div>
		</Card>
	);
}
