import React, {useEffect, useRef} from 'react';
import {Chart, ChartType, registerables} from 'chart.js';
import {ChartPoint} from '../../Types';

Chart.register(...registerables);

interface CustomChartProps {
	chartData: ChartPoint;
}

const CustomChart = ({chartData}: CustomChartProps) => {
	const chartRef = useRef<HTMLCanvasElement | null>(null);
	const chartInstance = useRef<Chart | null>(null);

	useEffect(() => {
		if (!chartRef.current || !chartData.timeLabels.length || !chartData.values.length) return;

		if (chartInstance.current) {
			chartInstance.current.destroy();
		}

		const ctx = chartRef.current.getContext('2d');
		if (!ctx) return;

		const {timeLabels, values, tooltip} = chartData;
		const maxValue = Math.max(...values, 100);
		const roundedMaxValue = Math.ceil(maxValue / 10) * 10;

		chartInstance.current = new Chart(ctx, {
			type: 'line' as ChartType,
			data: {
				labels: timeLabels,
				datasets: [
					{
						label: 'Submitted Values',
						data: values,
						backgroundColor: 'rgba(255, 99, 132, 0.2)',
						borderColor: 'rgba(255, 99, 132, 1)',
						borderWidth: 3,
						pointBackgroundColor: 'rgba(255, 99, 132, 0.8)',
						pointBorderColor: '#fff',
						pointBorderWidth: 2,
						pointRadius: 12,
						pointHoverRadius: 10,
						tension: 0.1,
					},
				],
			},
			options: {
				responsive: true,
				maintainAspectRatio: false,
				plugins: {
					tooltip: {
						callbacks: {
							title: (tooltipItems) => tooltipItems[0].label,
							label: (context) => {
								const index = context.dataIndex;
								const data = tooltip[index];
								return [`Submitted: ${data.submitted}`, `Min: ${data.min} Min`, `Max: ${data.max} Min`];
							},
						},
						backgroundColor: 'rgba(100, 100, 100, 0.8)',
						padding: 10,
						cornerRadius: 4,
					},
					legend: {display: false},
				},
				scales: {
					x: {ticks: {color: '#666'}, grid: {color: 'rgba(200, 200, 200, 0.3)', lineWidth: 1}},
					y: {
						beginAtZero: true,
						min: 0,
						max: roundedMaxValue,
						ticks: {color: '#666', stepSize: roundedMaxValue / 10},
						grid: {color: 'rgba(200, 200, 200, 0.3)', lineWidth: 1.5},
					},
				},
			},
		});

		// eslint-disable-next-line consistent-return
		return () => {
			if (chartInstance.current) {
				chartInstance.current.destroy();
			}
		};
	}, [chartData]);

	return (
		<div className='w-full h-96 px-8'>
			<canvas ref={chartRef} style={{width: '100%', height: '300px'}} />
		</div>
	);
};

export default CustomChart;
