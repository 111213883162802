import debounce from 'lodash.debounce';
import {ChangeEvent, useCallback, useMemo} from 'react';
import {KTSVG} from '../../../_metronic/helpers';

interface Props {
	setSearchTerm: (val: string) => void;
	placeholder: string;
	setLogs?: (logs: string) => void;
}

export default function DebouncedSearch({setSearchTerm, placeholder, setLogs}: Props) {
	const debouncedSearch = useMemo(
		() =>
			debounce((val: string) => {
				setSearchTerm(val);
			}, 750),
		[setSearchTerm],
	);

	const handleSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			setLogs?.(e.target.value.trim());
			debouncedSearch(e.target.value);
		},
		[debouncedSearch, setLogs],
	);

	return (
		<>
			<KTSVG path='/media/icons/duotune/general/gen021.svg' className='svg-icon-1 position-absolute ms-3' />
			<input
				type='text'
				data-kt-user-table-filter='search'
				className='form-control form-control-solid ps-14 w-2010px'
				placeholder={placeholder}
				onChange={handleSearch}
				style={{backgroundColor: '#ffffff'}}
			/>
		</>
	);
}
