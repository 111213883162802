/* eslint-disable @typescript-eslint/return-await */

import {useCallback} from 'react';
import {useMutation as useMutationBase, useQuery as useQueryBase, useQueryClient} from 'react-query';
import {useAuth} from '../../modules/auth/providers/AuthProvider';
import {useApiUrl} from '../providers/ConfigProvider';
import {getHttp, HttpHeaders, postHttp, deleteHttp, addAuthHeader} from './ApiClient';
import {useCallWithRefreshedToken} from './TokenRefresher';

type ResultBase = {
	isLoading: boolean;
	error: unknown;
};

type UseQuery = <T>(
	queryKey: string,
	path: string,
	headers?: HttpHeaders,
) => {
	data: T | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	refetch: () => any;
} & ResultBase;

const useQuery: UseQuery = <T>(queryKey: string, path: string, headers: HttpHeaders = {}) => {
	const url = useApiUrl(path);
	const query = useQueryBase(queryKey, () => getHttp<T>(url, headers));
	return {
		data: query.data,
		isLoading: query.isLoading,
		error: query.error,
		refetch: query.refetch,
	};
};

const useAuthedQuery: UseQuery = <T>(queryKey: string, path: string, headers: HttpHeaders = {}) => {
	const {currentUser} = useAuth();
	const url = useApiUrl(path);
	const callWithRefreshedToken = useCallWithRefreshedToken<T>();
	const query = useQueryBase(queryKey, async () => {
		return await callWithRefreshedToken(
			() => getHttp<T>(url, addAuthHeader(headers, currentUser?.token)),
			(newToken: string) => {
				return getHttp<T>(url, addAuthHeader(headers, newToken));
			},
		);
	});

	return {
		data: query.data,
		isLoading: query.isLoading,
		error: query.error,
		refetch: query.refetch,
	};
};

const useCachedQuery: UseQuery = <T>(queryKey: string, path: string, headers: HttpHeaders = {}) => {
	const {currentUser} = useAuth();
	const url = useApiUrl(path);
	const callWithRefreshedToken = useCallWithRefreshedToken<T>();
	const query = useQueryBase(queryKey, async () => {
		if (localStorage.getItem('preload')) {
			const {version} = JSON.parse(localStorage.getItem('preload') as string);
			if (version) {
				const res: any = await callWithRefreshedToken(
					() => getHttp<T>(url, addAuthHeader({version}, currentUser?.token)),
					(newToken: string) => {
						return getHttp<T>(url, addAuthHeader({version}, newToken));
					},
				);
				if (version !== res.version) {
					const newRes: any = await callWithRefreshedToken(
						() => getHttp<T>(url, addAuthHeader({version}, currentUser?.token)),
						(newToken: string) => {
							return getHttp<T>(url, addAuthHeader({version}, newToken));
						},
					);
					localStorage.setItem('preload', JSON.stringify(newRes));
				}
			} else {
				const res = await callWithRefreshedToken(
					() => getHttp<T>(url, addAuthHeader({}, currentUser?.token)),
					(newToken: string) => {
						return getHttp<T>(url, addAuthHeader({}, newToken));
					},
				);
				localStorage.setItem('preload', JSON.stringify(res));
			}

			return JSON.parse(localStorage.getItem('preload') as string);
		}

		const info = await callWithRefreshedToken(
			() => getHttp<T>(url, addAuthHeader(headers, currentUser?.token)),
			(newToken: string) => {
				return getHttp<T>(url, addAuthHeader(headers, newToken));
			},
		);

		localStorage.setItem('preload', JSON.stringify(info));
		return info;
	});

	return {
		data: query.data,
		isLoading: query.isLoading,
		error: query.error,
		refetch: query.refetch,
	};
};

interface MutationRequest {
	data?: object;
	headers?: HttpHeaders;
}

type UseMutation = <T>(path: string) => {
	mutate(req: MutationRequest): Promise<T>;
} & ResultBase;

const useMutation: UseMutation = <T>(path: string) => {
	const url = useApiUrl(path);
	const mutation = useMutationBase((req: MutationRequest) => {
		return postHttp<T>(url, req.data ?? {}, req.headers ?? {});
	});
	return {
		mutate: (req: MutationRequest) => mutation.mutateAsync(req),
		isLoading: mutation.isLoading,
		error: mutation.error,
	};
};

const useAuthedMutation: UseMutation = <T>(path: string) => {
	const {currentUser} = useAuth();
	const mutation = useMutation<T>(path);
	const callWithRefreshedToken = useCallWithRefreshedToken<T>();
	return {
		...mutation,
		mutate: async (req: MutationRequest) => {
			const addTokenToReq = (token: string | undefined) => {
				return {data: req.data, headers: addAuthHeader(req.headers, token)};
			};

			return await callWithRefreshedToken(
				() => mutation.mutate(addTokenToReq(currentUser?.token)),
				(newToken: string) => {
					return mutation.mutate(addTokenToReq(newToken));
				},
			);
		},
	};
};

interface DeletionRequest {
	headers?: HttpHeaders;
}

type UseDeletion = <T>(path: string) => {
	doDelete(req: DeletionRequest): Promise<T>;
} & ResultBase;

const useDeletion: UseDeletion = <T>(path: string) => {
	const url = useApiUrl(path);
	const mutation = useMutationBase((req: DeletionRequest) => {
		return deleteHttp<T>(url, req.headers ?? {});
	});
	return {
		doDelete: (req: DeletionRequest) => mutation.mutateAsync(req),
		isLoading: mutation.isLoading,
		error: mutation.error,
	};
};

const useAuthedDeletion: UseDeletion = <T>(path: string) => {
	const {currentUser} = useAuth();
	const mutation = useDeletion<T>(path);
	const callWithRefreshedToken = useCallWithRefreshedToken<T>();
	return {
		...mutation,
		doDelete: async (req: DeletionRequest) => {
			const addTokenToReq = (token: string | undefined) => {
				return {headers: addAuthHeader(req.headers, token)};
			};

			return await callWithRefreshedToken(
				() => mutation.doDelete(addTokenToReq(currentUser?.token)),
				(newToken: string) => {
					return mutation.doDelete(addTokenToReq(newToken));
				},
			);
		},
	};
};

const useInvalidateQuery = (queryKey: string) => {
	const client = useQueryClient();

	const invalidate = useCallback(() => {
		client.invalidateQueries({queryKey: [queryKey]});
	}, [queryKey, client]);

	return invalidate;
};

export {
	useQuery,
	useMutation,
	useAuthedQuery,
	useAuthedMutation,
	useAuthedDeletion,
	useInvalidateQuery,
	useCachedQuery,
};
