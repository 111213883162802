/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import {FC, useState} from 'react';
import {useAuth} from '../../../../modules/auth/providers/AuthProvider';
import {titleCase} from '../../../helpers';
import useLogout from '../../../hooks/useLogout';
import ConfirmModal from '../../modals/ConfirmModal';
import ErrorModal from '../../modals/ErrorModal';

const HeaderUserMenu: FC = () => {
	const {currentUser} = useAuth();
	const [show, setShow] = useState(false);

	const {logout, isLoading, showError, errorMessage, setShowError} = useLogout({
		onLogout: () => setShow(false),
	});

	if (!currentUser) return null;
	const {info: currentUserInfo, isReady} = currentUser;

	const onCloseModal = async (choice: boolean) => {
		if (!choice) {
			setShow(false);
			return;
		}
		try {
			await logout();
			window.localStorage.clear();
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			<div
				className='sajid-show menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'
				// data-kt-menu='true'
				style={{position: 'absolute', top: '64px', right: '23px'}}
			>
				<div className='menu-item px-3'>
					<div className='menu-content d-flex align-items-center px-3'>
						<div className='symbol symbol-50px me-5'>
							<img alt='Profile' src={currentUserInfo.profile_image} />
						</div>

						<div className='d-flex flex-column'>
							<div className='fw-bolder d-flex align-items-center fs-5'>
								{currentUserInfo.name}
								<span className='badge badge-light-success fw-bolder fs-8 px-2 py-1 ms-2'>
									{titleCase(currentUserInfo.role)}
								</span>
							</div>
							<a href='#' className='fw-bold text-muted text-hover-primary fs-7'>
								{currentUserInfo.email}
							</a>
						</div>
					</div>
				</div>
				<div className='separator my-2' />

				<div className='menu-item px-5'>
					{!isReady && (
						<a onClick={() => setShow(true)} className='menu-link px-5'>
							Sign Out
						</a>
					)}
					{isReady && (
						<a
							className='menu-link px-5 disabled'
							style={{cursor: 'not-allowed'}}
							data-toggle='tooltip'
							data-placement='left'
							title='You can not logout while you are online'
						>
							Sign Out
						</a>
					)}
				</div>
			</div>

			<ConfirmModal
				id='logout'
				show={show}
				message={`You'll be considered offline once you logout from the portal.`}
				onClose={onCloseModal}
				confirmColor='red'
				confirmText='Yes'
				cancelColor='green'
				cancelText='No'
				isLoading={isLoading}
				showIcon
			/>

			<ErrorModal
				show={showError}
				title='Error!'
				message={errorMessage}
				onClose={() => {
					setShowError(false);
				}}
			/>
		</>
	);
};

export {HeaderUserMenu};
