import {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {DrawerComponent} from '../../../../../_metronic/assets/ts/components';
import {useLayout} from '../../../../../_metronic/layout/core';
import useReady from '../../../hooks/useReady';
import {HeaderWrapperWithNavbar} from './HeaderWrapperWithNavbar';
import ReadyModal from './ReadyModal';

export function HeaderWrapper() {
	const {config, classes, fixedHeaderUnderNavbar} = useLayout();
	const {isReady, isLoading, toggleReady, showOfflineBanner} = useReady();

	const location = useLocation();
	const [show, setShow] = useState(false);

	useEffect(() => {
		DrawerComponent.hideAll();
	}, [location]);

	const header = <HeaderWrapperWithNavbar config={config} classes={classes} />;

	if (!fixedHeaderUnderNavbar && !showOfflineBanner) return header;

	return (
		<div className='d-flex flex-column'>
			{header}
			<div className='fixed-header-under-navbar'>
				{fixedHeaderUnderNavbar}
				{showOfflineBanner && (
					<div className='container-fluid d-flex bg-warning py-4 ps-6'>
						<p className='me-6' style={{position: 'relative', top: '5px'}}>
							You are currently Offline
						</p>
						<button
							style={{color: 'rgb(225 118 23)', borderRadius: '8px', border: 'none'}}
							onClick={() => {
								setShow(true);
							}}
						>
							Go Online
						</button>

						<ReadyModal
							isReady={isReady}
							isLoading={isLoading}
							show={show}
							setShow={setShow}
							toggleReady={toggleReady}
						/>
					</div>
				)}
			</div>
		</div>
	);
}
