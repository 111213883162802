import {getElementActualHeight, isVisibleElement} from './DomHelpers';
import {ElementAnimateUtil} from './ElementAnimateUtil';
import {DataUtil} from './_DataUtil';
import {ElementStyleUtil} from './_ElementStyleUtil';

function slide(el: HTMLElement, dir: string, speed: number, callback: any) {
	if (!el || (dir === 'up' && isVisibleElement(el) === false) || (dir === 'down' && isVisibleElement(el) === true)) {
		return;
	}

	speed = speed || 600;
	const calcHeight = getElementActualHeight(el);
	let calcPaddingTop = 0;
	let calcPaddingBottom = 0;

	if (ElementStyleUtil.get(el, 'padding-top') && DataUtil.get(el, 'slide-padding-top') !== true) {
		DataUtil.set(el, 'slide-padding-top', ElementStyleUtil.get(el, 'padding-top'));
	}

	if (ElementStyleUtil.get(el, 'padding-bottom') && DataUtil.has(el, 'slide-padding-bottom') !== true) {
		DataUtil.set(el, 'slide-padding-bottom', ElementStyleUtil.get(el, 'padding-bottom'));
	}

	if (DataUtil.has(el, 'slide-padding-top')) {
		const data = DataUtil.get(el, 'slide-padding-top') as string;
		calcPaddingTop = parseInt(data as string);
	}

	if (DataUtil.has(el, 'slide-padding-bottom')) {
		const data = DataUtil.get(el, 'slide-padding-bottom') as string;
		calcPaddingBottom = parseInt(data);
	}

	if (dir === 'up') {
		// up
		el.style.cssText = 'display: block; overflow: hidden;';

		if (calcPaddingTop) {
			ElementAnimateUtil.animate(0, calcPaddingTop, speed, function (value: number) {
				el.style.paddingTop = `${calcPaddingTop - value}px`;
			});
		}

		if (calcPaddingBottom) {
			ElementAnimateUtil.animate(0, calcPaddingBottom, speed, function (value: number) {
				el.style.paddingBottom = `${calcPaddingBottom - value}px`;
			});
		}

		ElementAnimateUtil.animate(
			0,
			calcHeight || 0,
			speed,
			function (value: number) {
				el.style.height = `${(calcHeight || 0) - value}px`;
			},
			function () {
				el.style.height = '';
				el.style.display = 'none';

				if (typeof callback === 'function') {
					callback();
				}
			},
		);
	} else if (dir === 'down') {
		// down
		el.style.cssText = 'display: block; overflow: hidden;';

		if (calcPaddingTop) {
			ElementAnimateUtil.animate(
				0,
				calcPaddingTop,
				speed,
				(value: number) => {
					//
					el.style.paddingTop = `${value}px`;
				},
				() => {
					el.style.paddingTop = '';
				},
			);
		}

		if (calcPaddingBottom) {
			ElementAnimateUtil.animate(
				0,
				calcPaddingBottom,
				speed,
				(value: number) => {
					el.style.paddingBottom = `${value}px`;
				},
				() => {
					el.style.paddingBottom = '';
				},
			);
		}

		ElementAnimateUtil.animate(
			0,
			calcHeight || 0,
			speed,
			(value: number) => {
				el.style.height = `${value}px`;
			},
			() => {
				el.style.height = '';
				el.style.display = '';
				el.style.overflow = '';

				if (typeof callback === 'function') {
					callback();
				}
			},
		);
	}
}

function slideUp(el: HTMLElement, speed: number, callback: any) {
	slide(el, 'up', speed, callback);
}

function slideDown(el: HTMLElement, speed: number, callback: any) {
	slide(el, 'down', speed, callback);
}

export {slide, slideUp, slideDown};
