import {UserModel} from './Types';

const AUTH_LOCAL_STORAGE_KEY = 'bkash-qc-auth';

const getLocalUser = (): UserModel | null => {
	if (!localStorage) return null;

	const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY);
	if (!lsValue) return null;

	try {
		const auth: UserModel = JSON.parse(lsValue) as UserModel;

		// You can easily check auth_token expiration also
		if (auth) return auth;
	} catch (error) {
		console.error('AUTH LOCAL STORAGE PARSE ERROR', error);
	}

	return null;
};

const setLocalUser = (auth: UserModel) => {
	if (!localStorage) return;

	try {
		const lsValue = JSON.stringify(auth);
		localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue);
	} catch (error) {
		console.error('AUTH LOCAL STORAGE SAVE ERROR', error);
	}
};

const removeLocalUser = () => {
	if (!localStorage) return;

	try {
		localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY);
	} catch (error) {
		console.error('AUTH LOCAL STORAGE REMOVE ERROR', error);
	}
};

export {getLocalUser, setLocalUser, removeLocalUser};
