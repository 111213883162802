import WhiteCard from '../../../../common/components/card/WhiteCard';
import DataTable from '../../../../common/components/data-table/DataTable';
import {Header, Row} from '../../../../common/components/data-table/Types';
import {PageLink, PageTitle} from '../../../../common/providers/PageDataProvider';
import CardHeader from '../../../users/common/components/CardHeader';
import ReportFilter from './EkycReportFilter';
import './EkycReportTable.css';
import {Ekyc} from './EkycTypes';
import useReport from './useReport';

const breadcrumbs: Array<PageLink> = [
	{
		title: 'Report',
		path: '/ekyc-reports',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const EkycReportTable = () => {
	const {data, isLoading, setCurrentPage, changeItemPerPage} = useReport();

	const headers: Array<Header> = [
		{
			text: 'Application ID',
			isSortable: false,
		},
		{
			text: 'Applied On Date & Time',
			isSortable: false,
		},
		{
			text: 'Machine QC Date & Time',
			isSortable: false,
		},
		{
			text: 'Wallet Number',
			isSortable: false,
		},
		{
			text: 'Product',
			isSortable: false,
		},
		{
			text: 'NID',
			isSortable: false,
		},
		{
			text: 'DOB',
			isSortable: false,
		},
		{
			text: 'Gender',
			isSortable: false,
		},
		{
			text: 'Business Type',
			isSortable: false,
		},
		{
			text: 'Monthly Income',
			isSortable: false,
		},
		{
			text: 'Region',
			isSortable: false,
		},
		{
			text: 'Thana',
			isSortable: false,
		},
		{
			text: 'District',
			isSortable: false,
		},
		{
			text: 'Division',
			isSortable: false,
		},
		{
			text: 'Machine QC Status',
			isSortable: false,
		},
		{
			text: 'Machine QC Reject Reason',
			isSortable: false,
		},
		{
			text: 'Assigned to',
			isSortable: false,
		},
		{
			text: 'Application Pick Date & Time',
			isSortable: false,
		},
		{
			text: 'Application Waiting Time',
			isSortable: false,
		},
		{
			text: 'QC Submission Date & Time',
			isSortable: false,
		},
		{
			text: 'QC Duration',
			isSortable: false,
		},
		{
			text: 'Human QC Status',
			isSortable: false,
		},
		{
			text: 'Human QC Reject Reason',
			isSortable: false,
		},
		{
			text: 'QC Done by login ID',
			isSortable: false,
		},
		{
			text: 'QC Done by Emp. ID',
			isSortable: false,
		},
		{
			text: 'QC Done by Emp. Name',
			isSortable: false,
		},
		{
			text: 'QC Done by Unit',
			isSortable: false,
		},
		{
			text: 'Account Creation Date & Time',
			isSortable: false,
		},
	];

	const rows: Array<Row> = (data?.data || []).map((ekyc: Ekyc) => {
		return {
			key: ekyc.id,
			items: [
				ekyc.application_id,
				ekyc.applied_on,
				ekyc.mqc_on,
				ekyc.wallet,
				ekyc.product,
				ekyc.nid,
				ekyc.dob,
				ekyc.gender,
				ekyc.business_type,
				ekyc.monthly_income,
				ekyc.region,
				ekyc.thana,
				ekyc.district,
				ekyc.division,
				ekyc.mqc_status,
				ekyc.mqc_reject_reason,
				ekyc.assigned_to,
				ekyc.application_picked_on,
				ekyc.application_waiting_time,
				ekyc.qc_submitted_on,
				ekyc.qc_duration,
				ekyc.hqc_status,
				ekyc.hqc_reject_reason,
				ekyc.qc_done_by_login_id,
				ekyc.qc_done_by_emp_id,
				ekyc.qc_done_by_emp_name,
				ekyc.qc_done_by_unit,
				ekyc.account_created_on,
			],
		};
	});

	return (
		<>
			<PageTitle breadcrumbs={breadcrumbs}>Reporting</PageTitle>
			<ReportFilter />

			<WhiteCard
				leftHeader={<CardHeader title='Latest Activities' />}
				rightHeader={<button className='btn btn-primary'>Download Report</button>}
				extraClasses='p-9'
			>
				<DataTable
					headers={headers}
					isLoading={isLoading}
					data={rows}
					changeItemPerPage={changeItemPerPage}
					meta={data?.meta}
					links={data?.links}
					setCurrentPage={setCurrentPage}
				/>
			</WhiteCard>
		</>
	);
};
export default EkycReportTable;
