import {createContext, FC, useCallback, useContext, useEffect, useMemo, useRef, useState} from 'react';
import {WithChildren} from '../../../../_metronic/helpers';
import {LayoutSplashScreen} from '../../../../_metronic/layout/core';
import {convertNodeToElement} from '../../../common/helpers';
import {Product} from '../../../common/types/Products';
import {getLocalUser, removeLocalUser, setLocalUser} from '../common/Helpers';
import {RoleMode, userHelpers, UserInfo, UserModel} from '../common/Types';

type AuthContextProps = {
	currentUser: UserModel | null;
	currentUserInfo: UserInfo | undefined;
	shouldShowOfflineBanner: boolean;
	login: (data: UserModel) => void;
	logoutWithoutReadyCheck: () => void;
	logout: () => void;
	updateToken: (token: string) => void;
	setReady: (isReady: boolean) => void;
	setRoleMode: (mode: RoleMode) => void;
	switchProduct: (product: Product) => void;
	isLoggedIn: () => boolean;
};

const localUser = getLocalUser();

const AuthContext = createContext<AuthContextProps>({
	currentUser: localUser,
	currentUserInfo: localUser?.info,
	shouldShowOfflineBanner: false,
	login: (data: UserModel) => {
		console.log(data);
	},
	logoutWithoutReadyCheck: () => {},
	logout: () => {},
	updateToken: (token: string) => {
		console.log(token);
	},
	setReady: (isReady: boolean) => {
		console.log(isReady);
	},
	setRoleMode: (mode: RoleMode) => {
		console.log(mode);
	},
	switchProduct: (product: Product) => {
		console.log(product);
	},
	isLoggedIn: () => false,
});

const useAuth = () => {
	return useContext(AuthContext);
};

const AuthProvider: FC<WithChildren> = ({children}) => {
	const [currentUser, setCurrentUser] = useState<UserModel | null>(getLocalUser());
	const currentUserRef = useRef(currentUser);
	const currentUserInfo = currentUser?.info;
	const [shouldShowOfflineBanner, setShouldShowOfflineBanner] = useState<boolean>(false);

	const updateUser = useCallback((user: UserModel) => {
		setCurrentUser(user);
		currentUserRef.current = user;
		setLocalUser(user);
	}, []);

	const login = useCallback(
		(data: UserModel) => {
			const user = {...data};
			if (!user?.isReady) {
				user.isReady = false;
				setShouldShowOfflineBanner(userHelpers.isOfflineForTooLong(user.info));
			}
			updateUser(user);
		},
		[updateUser],
	);

	const logoutWithoutReadyCheck = useCallback(() => {
		setCurrentUser(null);
		removeLocalUser();
	}, []);

	const logout = useCallback(() => {
		if (!currentUser) return;
		if (currentUser.isReady) return;

		logoutWithoutReadyCheck();
	}, [currentUser, logoutWithoutReadyCheck]);

	const updateToken = useCallback(
		(token: string) => {
			if (!currentUser) return;

			const user: UserModel = {...currentUser};
			user.token = token;
			updateUser(user);
		},
		[currentUser, updateUser],
	);

	const setReady = useCallback(
		(isReady: boolean) => {
			if (!currentUser) return;

			const user: UserModel = {...currentUser};
			user.isReady = isReady;
			if (!isReady) {
				setTimeout(() => {
					if (!currentUserRef.current?.isReady) {
						setShouldShowOfflineBanner(true);
					}
				}, userHelpers.offlineTimerInMinute * 60 * 1000);
			} else {
				setShouldShowOfflineBanner(false);
			}

			updateUser(user);
		},
		[currentUser, updateUser],
	);

	const setRoleMode = useCallback(
		(mode: RoleMode) => {
			if (!currentUser) return;

			const user: UserModel = {...currentUser};
			user.info.current_mode = mode;
			updateUser(user);
		},
		[currentUser, updateUser],
	);

	const switchProduct = useCallback(
		(product: Product) => {
			if (!currentUser) return;

			const user: UserModel = {...currentUser};
			user.info.current_product = product;
			updateUser(user);
		},
		[currentUser, updateUser],
	);

	const value = useMemo(
		() => ({
			currentUser,
			currentUserInfo,
			shouldShowOfflineBanner,
			login,
			logoutWithoutReadyCheck,
			logout,
			updateToken,
			setReady,
			setRoleMode,
			switchProduct,
			isLoggedIn: (): boolean => currentUser !== null,
		}),
		[
			currentUser,
			currentUserInfo,
			shouldShowOfflineBanner,
			login,
			logoutWithoutReadyCheck,
			logout,
			updateToken,
			setReady,
			setRoleMode,
			switchProduct,
		],
	);

	return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

const AuthInit: FC<WithChildren> = ({children}) => {
	const {currentUser, login, logout} = useAuth();
	const didRequest = useRef(false);
	const [showSplashScreen, setShowSplashScreen] = useState(true);
	// We should request user by authToken (IN OUR EXAMPLE IT'S API_TOKEN) before rendering the application
	useEffect(() => {
		const requestUser = async (user: UserModel) => {
			try {
				if (!didRequest.current) {
					login(user);
				}
			} catch (error) {
				console.error(error);
				if (!didRequest.current) {
					logout();
				}
			} finally {
				setShowSplashScreen(false);
			}

			return () => {
				didRequest.current = true;
				return didRequest.current;
			};
		};

		if (currentUser) {
			requestUser(currentUser);
		} else {
			logout();
			setShowSplashScreen(false);
		}
		// eslint-disable-next-line
	}, []);

	return showSplashScreen ? <LayoutSplashScreen /> : convertNodeToElement(children);
};

export {AuthProvider, AuthInit, useAuth};
