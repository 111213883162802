import {
	AppLifecycleStats,
	AppOverTime,
	AppStats,
	AvgQcTimes,
	BarValue,
	CPSStats,
	EcadAppStats,
	MqcStats,
	Pool,
	Pool1AppStats,
	Pool2AppStats,
	SupervisorAppStats,
	TimeData,
	Tooltip,
	UserStats,
} from '../Types';

export function getUserStats(data: UserStats | undefined) {
	return [
		{
			status: 'Logged Out',
			amount: data?.logged_out,
			percentage: data?.logged_out_percent,
			circleColor: '#F1B44C',
		},
		{
			status: 'Logged In',
			amount: data?.logged_in,
			percentage: data?.logged_in_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Offline',
			amount: data?.offline,
			percentage: data?.offline_percent,
			circleColor: '#FF0000',
		},
		{
			status: 'Online',
			amount: data?.online,
			percentage: data?.online_percent,
			circleColor: '#00DD07',
		},
		{
			status: 'Doing QC',
			amount: data?.doing_qc,
			percentage: data?.doing_qc_percent,
			circleColor: '#00FF66',
		},
		{
			status: 'Idle',
			amount: data?.idle,
			percentage: data?.idle_percent,
			circleColor: '#FD9800',
		},
	];
}

export function getAppStats(data: AppStats | undefined) {
	return [
		{
			status: 'Submitted',
			amount: data?.submitted,
			percentage: data?.submitted_percent,
			circleColor: '#F1B44C',
		},
		{
			status: 'Approved',
			amount: data?.approved,
			percentage: data?.approved_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Rejected',
			amount: data?.rejected,
			percentage: data?.rejected_percent,
			circleColor: '#FF0000',
		},
		{
			status: 'Escalated',
			amount: data?.escalated,
			percentage: data?.escalated_percent,
			circleColor: '#00DD07',
		},
		{
			status: 'Force Reject',
			amount: data?.force_rejected,
			percentage: data?.force_rejected_percent,
			circleColor: '#00FF66',
		},
	];
}

export function getMqcStats(data: MqcStats | undefined) {
	return [
		{
			status: 'Succeed',
			amount: data?.passed,
			percentage: data?.passed_percent,
			circleColor: '#F1B44C',
		},
		{
			status: 'Pending',
			amount: data?.pending,
			percentage: data?.pending_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Failed',
			amount: data?.rejected,
			percentage: data?.rejected_percent,
			circleColor: '#FF0000',
		},
	];
}

export function getPool1Stats(data: Pool1AppStats | undefined) {
	return [
		{
			status: 'Approved',
			amount: data?.approved,
			percentage: data?.approved_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Rejected',
			amount: data?.rejected,
			percentage: data?.rejected_percent,
			circleColor: '#FF0000',
		},
		{
			status: 'Escalated',
			amount: data?.escalated,
			percentage: data?.escalated_percent,
			circleColor: '#00DD07',
		},
	];
}

export function getPool2Stats(data: Pool2AppStats | undefined) {
	return [
		{
			status: 'Approved',
			amount: data?.approved,
			percentage: data?.approved_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Rejected',
			amount: data?.rejected,
			percentage: data?.rejected_percent,
			circleColor: '#FF0000',
		},
		{
			status: 'Escalated',
			amount: data?.escalated,
			percentage: data?.escalated_percent,
			circleColor: '#00DD07',
		},
	];
}

export function getSupervisorStats(data: SupervisorAppStats | undefined) {
	return [
		{
			status: 'Pending',
			amount: data?.pending,
			percentage: data?.pending_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Rejected',
			amount: data?.rejected,
			percentage: data?.rejected_percent,
			circleColor: '#FF0000',
		},
		{
			status: 'Approved',
			amount: data?.approved,
			percentage: data?.approved_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Escalated',
			amount: data?.escalated,
			percentage: data?.escalated_percent,
			circleColor: '#00DD07',
		},
	];
}

export function getEcadStats(data: EcadAppStats | undefined) {
	return [
		{
			status: 'Pending',
			amount: data?.pending,
			percentage: data?.pending_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Reject',
			amount: data?.rejected,
			percentage: data?.rejected_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Approved',
			amount: data?.approved,
			percentage: data?.approved_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Force Reject',
			amount: data?.force_rejected,
			percentage: data?.rejected_percent,
			circleColor: '#37B900',
		},
	];
}

export function getCpsStats(data: CPSStats | undefined) {
	return [
		{
			status: 'Full Wallet',
			amount: data?.full_wallet_opened,
			percentage: data?.full_wallet_opened_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Partial Wallet',
			amount: data?.limited_wallet_opened,
			percentage: data?.limited_wallet_opened_percent,
			circleColor: '#37B900',
		},
		{
			status: 'Disputed',
			amount: data?.disputed_count,
			percentage: data?.disputed_percent,
			circleColor: '#37B900',
		},
	];
}

export function getLifecycleStats(data: AppLifecycleStats | undefined) {
	return [
		{header: 'Machine QC Stats', stats: getMqcStats(data?.mqc_stats)},
		{header: 'Human QC (Pool 1)', stats: getPool1Stats(data?.pool1_app_stats)},
		{header: 'Human QC (Pool 2)', stats: getPool2Stats(data?.pool2_app_stats)},
		{header: 'Human QC (Supervisor)', stats: getSupervisorStats(data?.supervisor_app_stats)},
		{header: 'Human QC (ECAD)', stats: getEcadStats(data?.ecad_app_stats)},
		{header: 'CPS Statistics', stats: getSupervisorStats(data?.supervisor_app_stats)},
	];
}

export const extractTimeLabels = (data: AvgQcTimes | undefined): string[] => {
	if (!data) return [];

	const timeLabels: string[] = [];

	Object.values(data).forEach((pool: Pool) => {
		Object.values(pool).forEach((timeDatas: TimeData[] | null) => {
			if (Array.isArray(timeDatas)) {
				timeDatas.forEach((timeData: TimeData) => {
					if (!timeLabels.includes(timeData.time)) {
						timeLabels.push(timeData.time);
					}
				});
			}
		});
	});

	return timeLabels;
};

export const calculateAveragesByTime = (data: AvgQcTimes | undefined): number[] => {
	if (!data) return [];

	const times = extractTimeLabels(data);

	const averages: number[] = [];

	times.forEach((time, index) => {
		let sum = 0;
		let count = 0;

		Object.values(data).forEach((pool: Pool) => {
			Object.values(pool).forEach((timeDatas: TimeData[]) => {
				if (Array.isArray(timeDatas)) {
					timeDatas.forEach((timeData: TimeData) => {
						if (timeData.time === time) {
							const avgValue = timeData.avg;
							sum += avgValue;
							count += 1;
						}
					});
				}
			});
		});

		if (count > 0) {
			averages.push(sum / count);
		} else {
			averages.push(0);
		}
	});

	return averages;
};

interface TimeStats {
	min: number;
	max: number;
	submitted: number;
}

export const calculateTimeStats = (data: AvgQcTimes): TimeStats[] => {
	const times = extractTimeLabels(data);

	const statsArray: TimeStats[] = [];

	times.forEach((time, index) => {
		let minValue = Infinity;
		let maxValue = -Infinity;
		let totalSubmitted = 0;

		Object.values(data).forEach((pool: Pool) => {
			Object.values(pool).forEach((timeDatas: TimeData[]) => {
				if (Array.isArray(timeDatas)) {
					timeDatas.forEach((timeData: TimeData) => {
						minValue = Math.min(minValue, timeData.min);
						maxValue = Math.max(maxValue, timeData.max);
						totalSubmitted += timeData.submitted;
					});
				}
			});
		});

		statsArray.push({
			min: minValue,
			max: maxValue,
			submitted: totalSubmitted,
		});
	});

	return statsArray;
};

export const getStatsArrays = (data: AvgQcTimes | undefined): Tooltip[] => {
	if (!data) return [];
	const stats = calculateTimeStats(data);

	return stats;
};

export function getAvgQcTimes(data: AvgQcTimes | undefined) {
	return {
		timeLabels: extractTimeLabels(data),
		values: calculateAveragesByTime(data),
		tooltip: getStatsArrays(data),
	};
}

const aggregateApplicationOverTime = (data: AppOverTime | undefined) => {
	if (!data) return [];

	const timeMap: {[key: string]: number} = {};
	const monthOrder: {[key: string]: number} = {
		Jan: 1,
		Feb: 2,
		Mar: 3,
		Apr: 4,
		May: 5,
		Jun: 6,
		Jul: 7,
		Aug: 8,
		Sep: 9,
		Oct: 10,
		Nov: 11,
		Dec: 12,
	};

	const timePoints: {month: string; year: number; display: string}[] = [];

	Object.values(data).forEach((entries) => {
		if (entries) {
			entries.forEach(({month, year, count}: BarValue) => {
				const displayKey = `${month} '${year.toString().slice(-2)}`;
				timeMap[displayKey] = (timeMap[displayKey] || 0) + count;

				if (!timePoints.some((tp) => tp.month === month && tp.year === year)) {
					timePoints.push({month, year, display: displayKey});
				}
			});
		}
	});

	timePoints.sort((a, b) => {
		if (a.year !== b.year) return a.year - b.year;
		return monthOrder[a.month] - monthOrder[b.month];
	});

	return timePoints.map(({display}) => ({
		name: display,
		value: timeMap[display],
	}));
};

export function getAppOverTime(data: AppOverTime | undefined) {
	if (!data) return [];
	return aggregateApplicationOverTime(data);
}

export const formatTime = (seconds: number | null) => {
	if (!seconds) return '00:00';

	const minutes = Math.floor(seconds / 60);
	const secs = seconds % 60;
	return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
};

function getMonthName(monthIndex: number) {
	const months = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	return months[monthIndex];
}

export function getLast6MonthOptions() {
	const currentDate = new Date();
	let currentMonth = currentDate.getMonth();
	let currentYear = currentDate.getFullYear();
	const result = [{month: 'MTD', monthNumber: currentMonth + 1, year: currentYear}];

	for (let i = 0; i < 5; i++) {
		if (currentMonth === 0) {
			currentMonth = 11;
			currentYear--;
		} else {
			currentMonth--;
		}
		result.push({month: getMonthName(currentMonth), monthNumber: currentMonth + 1, year: currentYear});
	}

	return result;
}

export const extractMonth = (selectedOption: string) => {
	const monthNames = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const month = monthNames.indexOf(selectedOption) + 1;
	return month;
};

export function getMonthNoAndYear(
	monthName: string,
	monthOptions: {month: string; monthNumber: number; year: number}[],
) {
	const foundOption = monthOptions.find((option) => option.month === monthName);
	return foundOption ? {year: foundOption.year, monthNumber: foundOption.monthNumber} : null;
}

export const filterBarByProduct = (data: AppOverTime | undefined, product: string) => {
	if (!data || !data[product as keyof AppOverTime]) return [];

	const timeMap: {[key: string]: number} = {};
	const monthOrder: {[key: string]: number} = {
		Jan: 1,
		Feb: 2,
		Mar: 3,
		Apr: 4,
		May: 5,
		Jun: 6,
		Jul: 7,
		Aug: 8,
		Sep: 9,
		Oct: 10,
		Nov: 11,
		Dec: 12,
	};

	const timePoints: {month: string; year: number; display: string}[] = [];

	(data[product as keyof AppOverTime] as BarValue[]).forEach(({month, year, count}: BarValue) => {
		const displayKey = `${month} '${year.toString().slice(-2)}`;
		timeMap[displayKey] = (timeMap[displayKey] || 0) + count;

		if (!timePoints.some((tp) => tp.month === month && tp.year === year)) {
			timePoints.push({month, year, display: displayKey});
		}
	});

	timePoints.sort((a, b) => {
		if (a.year !== b.year) return a.year - b.year;
		return monthOrder[a.month] - monthOrder[b.month];
	});

	return timePoints.map(({display}) => ({
		name: display,
		value: timeMap[display],
	}));
};

export const filterChartByProduct = (data: AvgQcTimes | undefined, product: string) => {
	if (!data) return {timeLabels: [], values: [], tooltip: []};

	const filteredData: Partial<AvgQcTimes> = {};

	if (product === 'lite_a' && data.lite_a) {
		filteredData.lite_a = data.lite_a;
	} else if (product === 'lite_b' && data.lite_b) {
		filteredData.lite_b = data.lite_b;
	} else if (product === 'pra' && data.pra) {
		filteredData.pra = data.pra;
	} else {
		return {timeLabels: [], values: [], tooltip: []};
	}

	return {
		timeLabels: extractTimeLabels(filteredData as AvgQcTimes),
		values: calculateAveragesByTime(filteredData as AvgQcTimes),
		tooltip: getStatsArrays(filteredData as AvgQcTimes),
	};
};

export const filterChartByPool = (data: AvgQcTimes | undefined, pool: string) => {
	if (!data) return {timeLabels: [], values: [], tooltip: []};

	const filteredData: Partial<AvgQcTimes> = {};

	Object.keys(data).forEach((productKey) => {
		const product = data[productKey as keyof AvgQcTimes];
		const filteredProduct: Partial<Pool> = {};

		if (product.pool_1 && pool === 'pool_1') {
			filteredProduct.pool_1 = product.pool_1;
		}
		if (product.pool_2 && pool === 'pool_2') {
			filteredProduct.pool_2 = product.pool_2;
		}
		if (product.supervisor && pool === 'supervisor') {
			filteredProduct.supervisor = product.supervisor;
		}
		if (product.ecad && pool === 'ecad') {
			filteredProduct.ecad = product.ecad;
		}

		if (Object.keys(filteredProduct).length > 0) {
			filteredData[productKey as keyof AvgQcTimes] = filteredProduct as Pool;
		}
	});

	if (Object.keys(filteredData).length === 0) {
		return {timeLabels: [], values: [], tooltip: []};
	}

	return {
		timeLabels: extractTimeLabels(filteredData as AvgQcTimes),
		values: calculateAveragesByTime(filteredData as AvgQcTimes),
		tooltip: getStatsArrays(filteredData as AvgQcTimes),
	};
};

export const filterChartByProductPool = (data: AvgQcTimes | undefined, product: string, pool: string) => {
	if (!data) return {timeLabels: [], values: [], tooltip: []};

	const filteredProductData: Partial<AvgQcTimes> = {};

	if (product === 'lite_a' && data.lite_a) {
		filteredProductData.lite_a = data.lite_a;
	} else if (product === 'lite_b' && data.lite_b) {
		filteredProductData.lite_b = data.lite_b;
	} else if (product === 'pra' && data.pra) {
		filteredProductData.pra = data.pra;
	} else {
		return {timeLabels: [], values: [], tooltip: []};
	}

	const filteredData: Partial<AvgQcTimes> = {};

	Object.keys(filteredProductData).forEach((productKey) => {
		const productData = filteredProductData[productKey as keyof AvgQcTimes];

		if (productData) {
			const filteredProduct: Partial<Pool> = {};

			if (productData.pool_1 && pool === 'pool_1') {
				filteredProduct.pool_1 = productData.pool_1;
			}
			if (productData.pool_2 && pool === 'pool_2') {
				filteredProduct.pool_2 = productData.pool_2;
			}
			if (productData.supervisor && pool === 'supervisor') {
				filteredProduct.supervisor = productData.supervisor;
			}
			if (productData.ecad && pool === 'ecad') {
				filteredProduct.ecad = productData.ecad;
			}

			if (Object.keys(filteredProduct).length > 0) {
				filteredData[productKey as keyof AvgQcTimes] = filteredProduct as Pool;
			}
		}
	});

	if (Object.keys(filteredData).length === 0) {
		return {timeLabels: [], values: [], tooltip: []};
	}

	return {
		timeLabels: extractTimeLabels(filteredData as AvgQcTimes),
		values: calculateAveragesByTime(filteredData as AvgQcTimes),
		tooltip: getStatsArrays(filteredData as AvgQcTimes),
	};
};
