import {useCallback, useEffect, useState} from 'react';
import {QueryTypes, QueryTypesDropDown} from '../application/pages/application-list/Queuetypes';

const useManuallyVerifiedListSearch = () => {
	const [queryTypesData, setQueryTypesData] = useState<QueryTypes>({
		data: [
			{key: 'reference_id', value: 'Reference Id'},

			{key: 'nid', value: 'NID'},

			{key: 'wallet', value: 'Wallet Number'},
		],
	});

	const [currentQueryType, setCurrentQueryType] = useState<QueryTypesDropDown | null>(null);

	useEffect(() => {
		if (!queryTypesData) return;
		if (queryTypesData.data.length === 0) return;
		setCurrentQueryType(queryTypesData.data[0]);
	}, [queryTypesData]);

	const changeCurrentQueryType = useCallback(
		(value: string) => {
			if (!queryTypesData) return;
			queryTypesData.data.forEach((v: QueryTypesDropDown) => {
				if (v.key === value) setCurrentQueryType(v);
			});
		},
		[queryTypesData],
	);

	return {queryTypesData, currentQueryType, changeCurrentQueryType};
};

export default useManuallyVerifiedListSearch;
