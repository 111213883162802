import {useState, useEffect} from 'react';
import {useReportQuery} from '../../../common/hooks/QueryHooks';
import {BarPoint, ChartPoint, DashboardStats} from '../Types';
import {
	formatTime,
	getAppOverTime,
	getAvgQcTimes,
	getCpsStats,
	getEcadStats,
	getMqcStats,
	getPool1Stats,
	getPool2Stats,
	getSupervisorStats,
	getUserStats,
} from '../helpers/DashboardHelper';

const useReportDashboard = () => {
	const {data, isLoading, refetch} = useReportQuery<DashboardStats>('dashboard', '/dashboard');
	const [remainingTime, setRemainingTime] = useState<number | null>(null);

	// useEffect(() => {
	// 	if (data?.data?.ttl) {
	// 		setRemainingTime(data.data.ttl);
	// 	}
	// }, [data]);

	// useEffect(() => {
	// 	if (remainingTime === null || remainingTime <= 0) return;

	// 	const intervalId = setInterval(() => {
	// 		setRemainingTime((prevTime) => {
	// 			if (!prevTime || prevTime <= 1) {
	// 				window.location.reload();
	// 				return null;
	// 			}
	// 			return prevTime - 1;
	// 		});
	// 	}, 1000);

	// 	// eslint-disable-next-line consistent-return
	// 	return () => clearInterval(intervalId);
	// }, [remainingTime, refetch]);

	const userStats = getUserStats(data?.data.user_stats);
	const mqcStats = getMqcStats(data?.data.app_lifecycle_stats.mqc_stats);
	const pool1Stats = getPool1Stats(data?.data.app_lifecycle_stats.pool1_app_stats);
	const pool2Stats = getPool2Stats(data?.data.app_lifecycle_stats.pool2_app_stats);
	const supervisorStats = getSupervisorStats(data?.data.app_lifecycle_stats.supervisor_app_stats);
	const ecadStats = getEcadStats(data?.data.app_lifecycle_stats.ecad_app_stats);
	const cpsStats = getCpsStats(data?.data.app_lifecycle_stats.cps_stats);
	const avgQcTimes: ChartPoint = getAvgQcTimes(data?.data.average_qc_time);
	const appOverTime: BarPoint[] = getAppOverTime(data?.data.application_over_time);
	const totalUsers = data?.data.user_stats.total_users;

	const refreshData = () => {
		window.location.reload();
	};

	return {
		data,
		isLoading,
		remainingTime: formatTime(remainingTime),
		userStats,
		totalUsers,
		mqcStats,
		pool1Stats,
		pool2Stats,
		supervisorStats,
		ecadStats,
		cpsStats,
		avgQcTimes,
		appOverTime,
		refreshData,
		refetch,
	};
};

export default useReportDashboard;
