import {useEffect, useState} from 'react';
import {trimCharStart} from '../../common/helpers';
import {convertToMysqlDateString} from '../../common/helpers/DateHelper';
import {addQueryParamsToUrl} from '../../common/helpers/UrlHelper';
import {useAuthedQuery} from '../../common/hooks/QueryHooks';
import {useAuth} from '../auth';
import {useApiUrl} from '../../common/providers/ConfigProvider';
import {AutoVerifiedListReport} from './AutoVerifiedListType';

const useAutoVerifiedList = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [status, setStatus] = useState(<string | null>null);
	const [dateRange, setDateRange] = useState([new Date(), new Date(new Date().setDate(new Date().getDate() + 30))]);
	const [queryType, setQueryType] = useState<string | null>(null);
	const [queryValue, setQueryValue] = useState<string | null>(null);
	const [isManual, setIsMaual] = useState(0);

	const auth = useAuth().currentUser;

	const filters = {
		page: currentPage,
		limit: itemPerPage,
		status,
		queryType,
		queryValue,
		token: auth?.token,
		isManual,
		created_at: [
			['start', convertToMysqlDateString(dateRange[0])],
			['end', convertToMysqlDateString(dateRange[1])],
		],
	};

	const url = addQueryParamsToUrl('/posow-verification', filters);
	const downloadUrl = `${addQueryParamsToUrl(useApiUrl('/posow-verification/download'), filters)}&isManual=${isManual}`;

	const changeItemPerPage = (page: number) => {
		setCurrentPage(1);
		setItemPerPage(page);
	};

	const changeQuery = (t: string | null, v: string) => {
		if (t === null || v === '') {
			setQueryType(null);
			setQueryValue(null);
		} else {
			setQueryType(t);
			setQueryValue(v);
		}
	};

	useEffect(() => {
		setCurrentPage(1);
	}, [status]);

	const {data, isLoading} = useAuthedQuery<AutoVerifiedListReport>(trimCharStart(url, '/'), url);

	return {
		data,
		isLoading,
		dateRange,
		downloadUrl,
		setCurrentPage,
		changeItemPerPage,
		setDateRange,
		setStatus,
		changeQuery,
	};
};

export default useAutoVerifiedList;
