import clsx from 'clsx';
import {FC} from 'react';
import {Link, useLocation} from 'react-router-dom';
import {checkIsActive, WithChildren} from '../../../../../../_metronic/helpers';
import {useLayout} from '../../../../../../_metronic/layout/core';

type Props = {
	to: string;
	title: string;
	icon?: string;
	fontIcon?: string;
	hasBullet?: boolean;
};

const SidebarMenuItem: FC<Props & WithChildren> = ({children, to, title, icon, fontIcon, hasBullet = false}) => {
	const {pathname} = useLocation();
	const isActive = checkIsActive(pathname, to);
	const {config} = useLayout();
	const {app} = config;

	return (
		<div className='menu-item mb-2'>
			<Link
				className={clsx('menu-link without-sub p-6', {active: isActive})}
				to={to}
				style={{backgroundColor: isActive ? '#FCE8F3' : ''}}
			>
				{hasBullet && (
					<span className='menu-bullet'>
						<span className='bullet bullet-dot' />
					</span>
				)}
				{icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
					<span className='menu-icon'>
						{' '}
						<img alt={title} src={icon} className='h-24px w-24px' />
					</span>
				)}
				{fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && <i className={clsx('bi fs-3', fontIcon)} />}
				<span className='menu-title text-black'>{title}</span>
			</Link>
			{children}
		</div>
	);
};

export {SidebarMenuItem};
