import React from 'react';
import {BarChart, Bar, XAxis, YAxis, Tooltip, CartesianGrid, ResponsiveContainer} from 'recharts';
import {BarPoint} from '../../Types';

interface CustomBarProps {
	barData: BarPoint[];
}

const CustomBar = ({barData}: CustomBarProps) => {
	if (barData.length === 0) {
		return <p className='text-center text-gray-500'>No data available</p>;
	}

	return (
		<ResponsiveContainer width='100%' height={300}>
			<BarChart data={barData} barCategoryGap='20%'>
				<CartesianGrid strokeDasharray='3 3' />
				<XAxis
					dataKey='name'
					scale='band'
					padding={{left: 10, right: 10}}
					tick={{fontSize: 14}}
					tickLine={false}
					axisLine
					tickMargin={10}
					angle={-15}
					textAnchor='middle'
				/>
				<YAxis domain={[0, (dataMax: number) => Math.ceil(dataMax / 10) * 10]} tickCount={11} allowDecimals={false} />
				<Tooltip cursor={{fill: 'rgba(200,200,200,0.2)'}} />
				<Bar dataKey='value' fill='#12b7b5' barSize={70} />
			</BarChart>
		</ResponsiveContainer>
	);
};

export default CustomBar;
