import {useEffect, useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {useParams, useNavigate} from 'react-router-dom';
import {RoleDetails} from '../RoleDetails';
import {useAuthedMutation, useAuthedQuery} from '../../../../common/hooks/QueryHooks';
import {Permission, Permissions} from '../Permissions';
import {addOrRemove} from '../../../../common/helpers';

const schema = Yup.object().shape({
	name: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Name is required'),
	description: Yup.string().nullable(true).min(3, 'Minimum 3 symbols').max(250, 'Maximum 250 symbols'),
});

const initialValues = {
	name: '',
	description: '',
};

function makeInitialStateForPermissions(taggedPermissions: Array<Permission> | undefined) {
	if (taggedPermissions === undefined) return [];
	return taggedPermissions?.map((item) => {
		return item.name;
	});
}

const useUpdateRole = () => {
	const {id} = useParams();
	const {data: permissions} = useAuthedQuery<Permissions>('permissions', `/users/role/permissions`);
	const {data, isLoading} = useAuthedQuery<{data: RoleDetails}>('roles', `/users/role/details/${id}`);
	const taggedPermissions = data?.data.permissions;
	const {mutate, isLoading: isUpdating} = useAuthedMutation(`/users/role/update/${id}`);
	const [selectedPermissions, setSelectedPermissions] = useState<Array<string>>([]);
	const navigate = useNavigate();

	useEffect(() => {
		setSelectedPermissions(makeInitialStateForPermissions(taggedPermissions));
	}, [taggedPermissions]);
	const togglePermission = (permission: string) => {
		setSelectedPermissions([...addOrRemove(selectedPermissions, permission)]);
	};
	const formik = useFormik({
		initialValues: data?.data.role || initialValues,
		validationSchema: schema,
		enableReinitialize: true,
		onSubmit: async (values, {setStatus, setSubmitting}) => {
			try {
				const formValues = {name: values.name, description: values.description, permissions: selectedPermissions};
				await mutate({data: formValues});
				setStatus({message: 'Role Successfully updated.', isError: false});
				navigate('/roles');
			} catch (error) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				setStatus({message: error.response.data.message, isError: true});
				setSubmitting(false);
			}
		},
	});

	return {isLoading, formik, permissions, togglePermission, selectedPermissions};
};

export default useUpdateRole;
