import clsx from 'clsx';
import {FC} from 'react';
import {Link} from 'react-router-dom';
import {useLayout} from '../../../../../_metronic/layout/core';
import {arrayLast} from '../../../helpers';
import {usePageData} from '../../../providers/PageDataProvider';

const Breadcrumb: FC = () => {
	const {pageTitle, pageBreadcrumbs, showTitleInBreadcrumb} = usePageData();
	const {config} = useLayout();

	if (!(pageBreadcrumbs && pageBreadcrumbs.length > 0)) return null;

	const lastTitle = showTitleInBreadcrumb ? pageTitle : arrayLast(pageBreadcrumbs).title;
	const breadcrumbs = showTitleInBreadcrumb ? pageBreadcrumbs : pageBreadcrumbs.slice(0, -1);

	return (
		<>
			{config.app?.pageTitle?.direction === 'row' && <span className='h-20px border-gray-300 border-start mx-4' />}
			<ul className='breadcrumb breadcrumb-separatorless fw-semibold fs-7 my-0 mb-7'>
				{breadcrumbs?.map((item) => (
					<li
						className={clsx('breadcrumb-item', {
							'text-dark': !item.isSeparator && item.isActive,
							'text-muted': !item.isSeparator && !item.isActive,
						})}
						key={`${item.path}`}
					>
						{!item.isSeparator ? (
							<Link className='text-muted text-hover-primary' to={item.path}>
								{item.title}
							</Link>
						) : (
							<span className='text-muted'>/</span>
						)}
					</li>
				))}
				<li className='breadcrumb-item text-dark'>{lastTitle}</li>
			</ul>
		</>
	);
};

export default Breadcrumb;
