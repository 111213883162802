import ConfirmModal from '../../modals/ConfirmModal';

interface Props {
	isReady: boolean;
	toggleReady: () => void;
	isLoading: boolean;
	show: boolean;
	setShow: (show: boolean) => void;
}

export default function ReadyModal({isReady, toggleReady, isLoading, show, setShow}: Props) {
	const getModalText = () => {
		if (isReady) return 'Once you go offline you wil not receive any applications.';

		return 'Once you go online you will start receiving applications.';
	};

	const onCloseModal = async (choice: boolean) => {
		if (choice) {
			await toggleReady();
		}

		setShow(false);
	};

	return (
		<ConfirmModal
			id='ready'
			show={show}
			message={getModalText()}
			onClose={onCloseModal}
			confirmColor={isReady ? 'white' : 'blue'}
			confirmText={`Go ${isReady ? 'Offline' : 'Online'}`}
			cancelColor={isReady ? 'blue' : 'white'}
			cancelText='Close'
			isLoading={isLoading}
			showIcon={isReady}
		/>
	);
}
