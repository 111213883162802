/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable @typescript-eslint/no-non-null-assertion */

import clsx from 'clsx';
import {Fragment} from 'react';
import {snakeCase} from '../../helpers';
import Pagination from './Pagination';
import {Header, PaginationProps, Row, SortOrder} from './Types';

interface Props {
	headers: Array<Header>;
	isLoading: boolean;
	data: Array<Row>;
	sortColumn?: (column: string) => void;
}

const getSortClass = (order: SortOrder) => {
	return clsx(order !== undefined && `table-sort-${order}`);
};

export default function DataTable({
	headers,
	isLoading,
	data,
	sortColumn,
	changeItemPerPage,
	meta,
	links,
	setCurrentPage,
}: Props & PaginationProps) {
	const isEmpty = () => {
		return data && data.length === 0 && !isLoading;
	};
	const isNotEmpty = () => {
		return data && data.length > 0 && !isLoading;
	};
	return (
		<>
			<div className='table-responsive mb-9'>
				<table className='table bkash-data-table'>
					<thead className='fw-bold'>
						<tr>
							{headers.map((header) => (
								<Fragment key={header.text}>
									{header.isSortable && (
										<th
											className={getSortClass(header.sortOrder)}
											style={{cursor: 'pointer'}}
											onClick={() => {
												sortColumn!(header.sortKey!);
											}}
										>
											<div className='ekychead'>{header.text}</div>
										</th>
									)}
									{!header.isSortable && <th>{header.text}</th>}
								</Fragment>
							))}
						</tr>
					</thead>

					<tbody className='fs-6 fw-semibold'>
						{isLoading && (
							<tr>
								<td colSpan={headers.length} className='text-center'>
									Loading...
								</td>
							</tr>
						)}
						{isEmpty() && (
							<tr>
								<td colSpan={headers.length} className='text-center'>
									No Records Found
								</td>
							</tr>
						)}
						{isNotEmpty() &&
							data.map((row) => {
								return (
									<tr key={row.key}>
										{row.items.map((item, i) => (
											<td key={`${row.key}_${snakeCase(headers[i]?.text)}`}>{item}</td>
										))}
									</tr>
								);
							})}
					</tbody>
				</table>
			</div>

			{isNotEmpty() && (
				<Pagination changeItemPerPage={changeItemPerPage} meta={meta} links={links} setCurrentPage={setCurrentPage} />
			)}
		</>
	);
}
