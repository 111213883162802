import {useRef, useState} from 'react';
import {useNavigate} from 'react-router-dom';
import axios from 'axios';
import uploadImg from '../../../assets/images/icons/cloud-upload.svg';
import demoFile from '../../../assets/images/icons/excel.png';
import {useApiUrl} from '../../common/providers/ConfigProvider';
import {useAuth} from '../auth';
import './DropFile.css';
import {PageTitle} from '../../common/providers/PageDataProvider';
import ErrorModal from '../../common/components/modals/ErrorModal';
import SuccessModal from '../../common/components/modals/SuccessModal';

const DropFile = () => {
	const wrapperRef = useRef<HTMLDivElement | null>(null);
	const [file, setFile] = useState<any>(null);
	const [loading, setLoading] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string | undefined>(undefined);
	const [open, setOpen] = useState(false);
	const [success, setSuccess] = useState(false);
	const url = useApiUrl('/posow-verification/upload');
	const token = useAuth().currentUser?.token;
	const {currentUserInfo} = useAuth();
	const navigate = useNavigate();

	const onDragEnter = () => {
		if (wrapperRef.current) {
			wrapperRef.current.classList.add('dragover');
		}
	};

	const onDragLeave = () => {
		if (wrapperRef.current) {
			wrapperRef?.current.classList.remove('dragover');
		}
	};

	const onDrop = () => {
		if (wrapperRef.current) {
			wrapperRef?.current.classList.remove('dragover');
		}
	};

	const onFileDrop = (e: any) => {
		const newFile = e.target.files[0];
		if (newFile) {
			if (!newFile.name.includes('.xlsx') || !newFile.name.includes('.xls')) {
				setErrorMsg('Only .xlsx file is accepted.');
				setOpen(true);
				return;
			}
			setFile(newFile);
			setErrorMsg(undefined);
		}
	};

	const handleSubmit = async (event: any) => {
		event.preventDefault();
		setLoading(true);
		setErrorMsg(undefined);
		if (file) {
			const formData = new FormData();
			formData.append('file', file);
			const config = {
				headers: {
					'content-type': 'multipart/form-data',
					Accept: 'application/json',
					Authorization: `Bearer ${token}`,
				},
			};
			axios
				.post(url, formData, config)
				.then((response) => {
					if (currentUserInfo?.can_access_posow_read && currentUserInfo.can_access_posow_upload) {
						navigate('/manually-verified-list?status=true');
					} else {
						setSuccess(true);
					}
					setLoading(false);
				})
				.catch((error) => {
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment
					// @ts-ignore

					if (error) {
						setLoading(false);
						if (error.response.data.message) {
							setErrorMsg(error.response.data.message);
							setOpen(true);
							return;
						}

						if (error.response.data.error) {
							setErrorMsg(error.response.data.error);
							setOpen(true);
						}
					}
				});
		}
	};

	return (
		<>
			<PageTitle breadcrumbs={[]}>Posow Upload</PageTitle>
			<form onSubmit={handleSubmit}>
				<div
					ref={wrapperRef}
					className='drop-file-input'
					onDragEnter={onDragEnter}
					onDragLeave={onDragLeave}
					onDrop={onDrop}
				>
					<div className='drop-file-input__label'>
						<img src={uploadImg} alt='' />
						<p style={{fontSize: '16px'}}>Upload or Drag & Drop your file here</p>
					</div>
					<input accept='.xlsx, .xls, .csv' type='file' value='' onChange={onFileDrop} />
				</div>
				{file && (
					<div className='d-flex align-items-center justify-content-center gap-5 mt-3'>
						<div className='position-relative mt-5 shadow-sm' style={{width: '396px'}}>
							<div
								style={{paddingTop: '10px', paddingBottom: '10px', paddingRight: '10px'}}
								className='d-flex align-items-center bg-white rounded  position-relative text-break'
							>
								<img width={60} src={demoFile} alt='' />
								<p className='mb-0'>{file?.name}</p>
							</div>
							<button
								style={{paddingLeft: 8, paddingRight: 8, top: '-12px', right: '-8px'}}
								className='border-0 position-absolute bg-danger  text-white  rounded-circle'
								onClick={() => setFile(null)}
							>
								x
							</button>
						</div>

						<button className='btn btn-danger d-flex mt-5 align-items-center' type='submit'>
							{!loading && <span>Upload</span>}
							{loading && (
								<span className='indicator-progress' style={{display: 'block'}}>
									Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2' />
								</span>
							)}
						</button>
					</div>
				)}

				{/* {file && (
					<button className='btn btn-danger d-flex mt-5 align-items-center' type='submit'>
						{!loading && <span>Upload</span>}
						{loading && (
							<span className='indicator-progress' style={{display: 'block'}}>
								Please wait... <span className='spinner-border spinner-border-sm align-middle ms-2' />
							</span>
						)}
					</button>
				)} */}
			</form>
			<ErrorModal
				show={open}
				title={errorMsg}
				onClose={() => {
					setOpen(false);
				}}
			/>
			<SuccessModal
				title='File uploaded successfully! Posow Verification is in process.'
				show={success}
				onClose={() => {
					setSuccess(false);
				}}
			/>
		</>
	);
};

export default DropFile;
