import {useEffect} from 'react';
import {Outlet} from 'react-router-dom';
import Illustration from './Illustration';

const AuthLayout = () => {
	useEffect(() => {
		const root = document.getElementById('root');
		if (root) {
			root.style.height = '100%';
		}
		return () => {
			if (root) {
				root.style.height = 'auto';
			}
		};
	}, []);

	return (
		<div className='d-flex flex-column flex-lg-row flex-column-fluid h-100'>
			<div
				className='d-flex flex-lg-row-fluid w-lg-40 bgi-size-cover bgi-position-center order-1 order-lg-1'
				style={{backgroundColor: `#1F1E2E`}}
			>
				<Illustration />
			</div>

			<div className='d-flex flex-column flex-lg-row-fluid w-lg-60 p-10 order-2 order-lg-2'>
				<div className='d-flex flex-center flex-column flex-lg-row-fluid'>
					<div className='w-lg-500px p-10'>
						<Outlet />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AuthLayout;
