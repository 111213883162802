interface Duration<T extends number | string> {
	minute: T;
	second: T;
}

export function covertSecondsToMinutesAndSeconds(seconds: number): Duration<number> {
	return {
		second: seconds % 60,
		minute: Math.floor(seconds / 60),
	};
}

export function padTime(time: number) {
	return time.toString().padStart(2, '0');
}

export function covertSecondsToPaddedMinutesAndSeconds(seconds: number): Duration<string> {
	const converted = covertSecondsToMinutesAndSeconds(seconds);
	return {
		minute: padTime(converted.minute),
		second: padTime(converted.second),
	};
}

export function convertToMysqlDateString(date: Date): string {
	return `${date.getFullYear()}-${padTime(1 + date.getMonth())}-${padTime(date.getDate())}`;
}

export const getLastWeeksDate = () => {
	const now = new Date();
	return new Date(now.getFullYear(), now.getMonth(), now.getDate() - 7);
};
