import clsx from 'clsx';
import {useState} from 'react';
import {Modal} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import {Link} from 'react-router-dom';
import warnIcon from '../../../../../../assets/images/icons/confirm-modal.svg';
import InButtonLoader from '../../../../../common/components/InButtonLoader';
import {useAuth} from '../../../../auth';
import useUpdateRole from '../useUpdateRole';
import styles from './RoleTable.module.scss';

export default function RoleTable() {
	const {isLoading, formik, permissions, togglePermission, selectedPermissions} = useUpdateRole();
	const [confirmation, setConfirmation] = useState<boolean>(false);
	const {currentUserInfo} = useAuth();

	return (
		<div>
			{formik.status && (
				<div className={`mb-4 alert  ${formik.status.isError ? `alert-danger` : `alert-success`}`}>
					<div className='alert-text font-weight-bold'>{formik.status.message}</div>
				</div>
			)}
			<div className={`card ${styles.createRole}`}>
				<div className='card-body p-9'>
					<div className='d-flex flex-row justify-content-between'>
						<h3 className='card-title d-flex flex-column justify-content-center'>Update Role</h3>
						<Link to='/roles/create' className='px-5 py-3 fw-bold fs-6 rounded-3 table-action-btn mx-4'>
							Create New Roles
						</Link>
					</div>
					{/* <form onSubmit={formik.handleSubmit} noValidate> */}
					<div className='form-group'>
						<label className='py-3 fw-bold'>Role Name</label>
						<input
							type='text'
							{...formik.getFieldProps('name')}
							className={clsx(
								'form-control form-control-solid',
								{'is-invalid': formik.touched.name && formik.errors.name},
								{
									'is-valid': formik.touched.name && !formik.errors.name,
								},
							)}
							name='name'
							disabled={formik.isSubmitting}
						/>
						{formik.touched.name && formik.errors.name && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{formik.errors.name}</div>
							</div>
						)}
					</div>
					<div className='form-group'>
						<label className='py-3 fw-bold'>Description</label>
						<input
							type='text'
							{...formik.getFieldProps('description')}
							className={clsx(
								'form-control form-control-solid',
								{'is-invalid': formik.touched.description && formik.errors.description},
								{
									'is-valid': formik.touched.description && !formik.errors.description,
								},
							)}
							name='description'
							disabled={formik.isSubmitting}
						/>
						{formik.touched.description && formik.errors.description && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{formik.errors.description}</div>
							</div>
						)}
					</div>
					<div className='permissions'>
						<h6 className='card-title pt-9 pb-2 d-flex flex-column justify-content-center'>Permission</h6>
						<div className='row'>
							{permissions?.data.map((item) => (
								<div key={item.module} className='form-group col-md-3 mb-4'>
									<label className='pb-3 fs-6'>{item.module}</label>
									{item.permission.map((permission) => (
										<label key={permission.name} className="'form-check checkbox d-flex flex-row">
											<input
												type='checkbox'
												className='form-check-input d-flex flex-row pt-3'
												name='permissions[]'
												value={permission.name}
												onChange={() => {
													togglePermission(permission.name);
												}}
												checked={selectedPermissions.includes(permission.name)}
											/>
											<span />
											<p className='px-2 fs-6 fw-semibold'>{permission.label}</p>
										</label>
									))}
								</div>
							))}
							<div />
						</div>
					</div>
					<div className='form-group py-2'>
						<button
							type='submit'
							className='btn btn-primary py-3 px-7 rounded-3'
							style={{
								fontSize: '12px',
							}}
							disabled={!formik.values.name}
							onClick={() => {
								setConfirmation(true);
							}}
						>
							Update Role
						</button>
					</div>
					<Modal show={confirmation} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered'>
						<div>
							<div className='modal-body scroll-y text-center'>
								<div className='container'>
									<div className='mb-12'>
										<SVG src={warnIcon} />
									</div>

									<div className='fs-1 fw-semibold mb-4' style={{color: '#3F4254'}}>
										Are you sure?
									</div>
									<div>
										<p className='fs-4'>You want to create role?</p>
									</div>
								</div>
							</div>
							<div className='modal-footer justify-content-center'>
								<button
									type='button'
									className='btn btn-danger py-3 px-7 rounded-3'
									onClick={() => {
										setConfirmation(false);
									}}
								>
									No
								</button>
								<form onSubmit={formik.handleSubmit} noValidate>
									<button type='submit' className='px-5 py-3 fw-bold fs-6 rounded-3	btn btn-success mx-4'>
										<InButtonLoader isLoading={formik.isSubmitting} showIcon showText>
											Yes
										</InButtonLoader>
									</button>
								</form>
							</div>
						</div>
					</Modal>
					{/* </form> */}
				</div>
			</div>
		</div>
	);
}
