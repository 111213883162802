import DropButton from '../DropButton';
import AtomCard from './AtomCard';
import {getLast6MonthOptions} from '../../helpers/DashboardHelper';
import useMonthlyAppStats from '../../hooks/useMonthlyAppStats';
import {AppStats} from '../../Types';

interface Props {
	header: string;
	appStats: AppStats | undefined;
}

const ApplicationStats = ({header, appStats}: Props) => {
	const last6Months = getLast6MonthOptions();
	const monthOptions = last6Months.map((item) => item.month);

	const {monthlyAppStats, isLoading, handleOptionChange} = useMonthlyAppStats(last6Months[0].month, appStats);

	return (
		<div className='card px-8 pt-5 pb-8' style={{backgroundColor: '#FFFFFF'}}>
			<div className='d-flex flex-column gap-8'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex gap-5 align-items-center'>
						<span style={{color: '#0F172A', fontWeight: 'bold', fontSize: '16px'}}>{header}</span>
					</div>
					<DropButton selectedOption={monthOptions[0]} options={monthOptions} onOptionChange={handleOptionChange} />
				</div>

				<div className='d-flex gap-5 flex-wrap'>
					{isLoading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								height: '100%',
								textAlign: 'center',
								minHeight: '200px',
							}}
						>
							<span>Loading...</span>
						</div>
					) : (
						monthlyAppStats.map((stat) => <AtomCard key={`${stat.status}-${stat.amount}`} stats={stat} />)
					)}
				</div>
			</div>
		</div>
	);
};

export default ApplicationStats;
