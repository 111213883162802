import {useState} from 'react';
import {trimCharStart} from '../../../../../common/helpers';
import {addQueryParamsToUrl} from '../../../../../common/helpers/UrlHelper';
import {useAuthedQuery} from '../../../../../common/hooks/QueryHooks';
import {HistoryTableData} from '../Types';

const useHistoryData = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);

	const url = addQueryParamsToUrl('/pdf/downloaded', {
		page: currentPage,
		limit: itemPerPage
	});

	const changeItemPerPage = (page: number) => {
		setCurrentPage(1);
		setItemPerPage(page);
	};

	const {data, isLoading} = useAuthedQuery<HistoryTableData>(trimCharStart(url, '/'), url);

	return {
		data,
		isLoading,
		setCurrentPage,
		changeItemPerPage,
	};
};

export default useHistoryData;
