import clsx from 'clsx';
import $ from 'jquery';
import {userInfo} from 'os';
import {useEffect, useState} from 'react';
import {KTSVG} from '../../../../../_metronic/helpers';
import {useAuth} from '../../../../modules/auth';
import {userHelpers} from '../../../../modules/auth/common/Types';
import {titleCase} from '../../../helpers';
import useNotifications from '../../../hooks/useNotifications';
import useReady from '../../../hooks/useReady';
import useUpdateNotifications from '../../../hooks/useUpdateNotifications';
import {HeaderUserMenu} from './HeaderUserMenu';
import Notifications from './Notifications';
import ProductSwitcher from './ProductSwitcher';
import ReadyButton from './ReadyButton';
import UserRoleModeButton from './UserRoleModeButton';

const itemClass = 'ms-1 ms-lg-4';
const userAvatarClass = 'symbol-35px symbol-md-40px';

const Navbar = () => {
	const {currentUserInfo} = useAuth();
	const {currentUser} = useAuth();
	const {canChangeRoleMode, canBeReady, canSwitchProduct} = userHelpers;
	const [isNotify, setIsNotify] = useState(false);
	const [count, setCount] = useState(0);
	const {data, refetch} = useNotifications({initialItemPerPage: 5});
	const {mutate} = useUpdateNotifications();
	const [showHeaderUser, setShowHeaderUser] = useState(false);
	const {isReady} = useReady();

	const onNotificationClick = async (idStr: string) => {
		try {
			await mutate({data: {id: idStr}});
			refetch();
		} catch (error) {
			console.log('Notification');
		}
	};

	const showPopUp = () => {
		if (!isReady || isReady) $('.sajid-show').toggle();
	};

	$(document).on('mouseup', function () {
		if ($('.sajid-show').css('display') === 'block') {
			$('.sajid-show').hide();
		}
	});

	useEffect(() => {
		if (data?.data) {
			// const isReadArray = (data?.data || []).map((obj) => obj.is_read);
			// const currentCount = isReadArray.filter((value) => value === false).length;
			// setCount(currentCount);
			setCount(data?.unread_count as number);
			// if (currentCount > 0) setIsNotify(true);
			if ((data?.unread_count as number) > 0) setIsNotify(true);
			else setIsNotify(false);
		}
	}, [data]);

	if (!currentUserInfo) return null;

	const isAdminOrCustomerSupport = ['admin', 'customer support'].includes(
		currentUser?.info.role.toLowerCase() as string,
	);

	return (
		<div className='app-navbar flex-shrink-0'>
			{canSwitchProduct(currentUserInfo) && (
				<div className={clsx('app-navbar-item', itemClass)}>
					<ProductSwitcher current={currentUserInfo.current_product} assigned={currentUserInfo.assigned_products} />
				</div>
			)}

			{canBeReady(currentUserInfo) && (
				<div className={clsx('app-navbar-item', itemClass)}>
					<ReadyButton />
				</div>
			)}

			{canChangeRoleMode(currentUserInfo) && (
				<div className={clsx('app-navbar-item', itemClass)}>
					<UserRoleModeButton />
				</div>
			)}

			<div className={clsx('app-navbar-item', itemClass)}>
				<div className='d-flex flex-column mx-5 fs-7 text-center fw-bold'>
					<div style={{color: '#B5B5C3'}}>{currentUserInfo.name}</div>
					<div style={{color: '#464E5F'}}>{titleCase(currentUserInfo.role)}</div>
				</div>
				<button
					className={clsx('cursor-pointer symbol', userAvatarClass)}
					onClick={showPopUp}
					style={{border: 'none', backgroundColor: 'white'}}
					// data-kt-menu-trigger='click'
					// data-kt-menu-attach='parent'
					// data-kt-menu-placement='bottom-end'
				>
					<img src={currentUserInfo.profile_image} alt='profile pic' style={{width: '28px', height: '28px'}} />
				</button>
				<HeaderUserMenu />
			</div>

			{currentUserInfo.can_access_cs_portal && isAdminOrCustomerSupport ? null : (
				<div className={clsx('app-navbar-item', itemClass)}>
					<div
						className={clsx('cursor-pointer symbol', userAvatarClass)}
						data-kt-menu-trigger="{default: 'click'}"
						data-kt-menu-attach='parent'
						data-kt-menu-placement='bottom-end'
					>
						<div className='d-flex align-items-center' data-kt-search-element='toggle' id='kt_header_search_toggle'>
							<div className='btn btn-icon btn-active-light-primary w-30px h-30px w-md-40px h-md-40px'>
								<KTSVG
									optionalStyle={isNotify ? {color: '#6D5D6E'} : {}}
									path='/media/icons/duotune/general/gen007.svg'
									className='svg-icon-1'
								/>

								{isNotify ? (
									<span
										style={{
											color: 'white',
											borderRadius: '50%',
											background: 'red',
											padding: '1px',
											height: '17px',
											width: '19px',
											position: 'absolute',
											top: '3px',
											right: '-2px',
											fontSize: '11px',
										}}
									>
										{/* {count > 9 ? <span>9+</span> : <span>{count}</span>} */}
										<span>{count}</span>
									</span>
								) : (
									''
								)}
							</div>
						</div>
					</div>

					<Notifications data={data} onNotificationClick={onNotificationClick} />
				</div>
			)}
		</div>
	);
};

export {Navbar};
