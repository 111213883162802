import React, {useState, useEffect} from 'react';
import DropButton from '../DropButton';
import CustomChart from './CustomChart';
import useReportDashboard from '../../hooks/useReportDashboard';
import {filterChartByPool, filterChartByProduct, filterChartByProductPool} from '../../helpers/DashboardHelper';
import useAvgQc from '../../hooks/useAvgQc';
import TimeRangeButton from '../TimeRangeButton';
import {ChartPoint} from '../../Types';

const DashboardCharts = () => {
	const poolOptions = ['Pools', 'Pool 1', 'Pool 2', 'Supervisor', 'ECAD'];
	const productOptions = ['All Products', 'Lite-A', 'Lite-B', 'PRA'];
	const defaultRange: [string, string] = ['00:00', '23:59'];
	const header = 'Application Average QC Time';

	// State for filters
	const [selectedPool, setSelectedPool] = useState(poolOptions[0]);
	const [selectedProduct, setSelectedProduct] = useState(productOptions[0]);
	const [timeRange, setTimeRange] = useState<[string, string]>(defaultRange);

	// Fetch data
	const {data, avgQcTimes} = useReportDashboard();
	const {filteredAvgQcTimes, timeFilteredData} = useAvgQc(timeRange);

	// State for chart data
	const [chartData, setChartData] = useState<ChartPoint>({
		timeLabels: [],
		values: [],
		tooltip: [],
	});

	// Filter states
	const [isFilteredByPool, setIsFilteredByPool] = useState(false);
	const [isFilteredByProduct, setIsFilteredByProduct] = useState(false);
	const [isFilteredByTime, setIsFilteredByTime] = useState(false);

	// Function to determine filtered data
	const getChartData = () => {
		const isFilteredByPoolProduct = isFilteredByPool && isFilteredByProduct;
		const isFilteredByPoolProductTime = isFilteredByPoolProduct && isFilteredByTime;
		const isFilteredByPoolTime = isFilteredByPool && isFilteredByTime;
		const isFilteredByProductTime = isFilteredByProduct && isFilteredByTime;

		if (isFilteredByPoolProductTime) return filterChartByProductPool(timeFilteredData, selectedProduct, selectedPool);
		if (isFilteredByPoolProduct)
			return filterChartByProductPool(data?.data.average_qc_time, selectedProduct, selectedPool);
		if (isFilteredByPoolTime) return filterChartByPool(timeFilteredData, selectedPool);
		if (isFilteredByProductTime) return filterChartByProduct(timeFilteredData, selectedProduct);
		if (isFilteredByPool) return filterChartByPool(data?.data.average_qc_time, selectedPool);
		if (isFilteredByProduct) return filterChartByProduct(data?.data.average_qc_time, selectedProduct);
		if (isFilteredByTime) return filteredAvgQcTimes;

		return avgQcTimes;
	};

	// Set default chart data when `avgQcTimes` is available
	useEffect(() => {
		if (avgQcTimes) {
			setChartData(avgQcTimes);
		}
	}, [avgQcTimes]);

	// Handle time range change
	const handleTimeChange = (range: [string, string]) => {
		setTimeRange(range);
		setIsFilteredByTime(range !== defaultRange);
		setChartData(getChartData());
	};

	// Handle dropdown changes
	const handleProductChange = (option: string) => {
		setSelectedProduct(option);
		setIsFilteredByProduct(option !== productOptions[0]);
		setChartData(getChartData());
	};

	const handlePoolChange = (option: string) => {
		setSelectedPool(option);
		setIsFilteredByPool(option !== poolOptions[0]);
		setChartData(getChartData());
	};

	return (
		<div className='card px-8 pt-5 pb-8' style={{backgroundColor: '#FFFFFF'}}>
			<div className='d-flex flex-column gap-8'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex gap-5 align-items-center'>
						<span style={{color: '#0F172A', fontWeight: 'bold', fontSize: '16px'}}>{header}</span>
					</div>
					<div className='d-flex gap-3'>
						<DropButton selectedOption={poolOptions[0]} options={poolOptions} onOptionChange={handlePoolChange} />
						<DropButton
							selectedOption={productOptions[0]}
							options={productOptions}
							onOptionChange={handleProductChange}
						/>
						<TimeRangeButton onTimeChange={handleTimeChange} />
					</div>
				</div>
				<CustomChart chartData={chartData} />
			</div>
		</div>
	);
};

export default DashboardCharts;
