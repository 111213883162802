import useReportDashboard from '../../hooks/useReportDashboard';
import ApplicationStats from './ApplicationStats';
import UserStats from './UserStats';
import LifecycleStats from './lifecycleStats/LifecycleStats';

const DashboardStats = () => {
	const {data, remainingTime, userStats, totalUsers} = useReportDashboard();

	return (
		<div className='d-flex flex-column gap-8'>
			<UserStats
				userStats={userStats}
				totalUsers={totalUsers}
				remainingTime={remainingTime}
				header={`People's Statistics`}
			/>

			<ApplicationStats appStats={data?.data.app_stats} header='Application Statistics' />

			<LifecycleStats lifecycleStats={data?.data.app_lifecycle_stats} header='Application Lifecycle Statisitcs' />
		</div>
	);
};

export default DashboardStats;
