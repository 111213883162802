import {FC} from 'react';
import Lottie from 'react-lottie';
import * as animationData from '../../../../assets/images/auth/403-forbidden.json';
import ReturnHomeButton from '../common/ReturnHomeButton';

const Error403: FC = () => {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<>
			{/* begin::Title */}
			<h1 className='fw-bolder fs-2hx text-gray-900 mb-4'>Oops!</h1>
			{/* end::Title */}
			{/* begin::Text */}
			<div className='fw-semibold fs-6 text-gray-500 mb-7'>You Have No Permission To Access.</div>
			{/* end::Text */}
			{/* begin::Illustration */}
			<div className='mb-7'>
				<Lottie options={defaultOptions} height={300} width={300} />
			</div>
			{/* end::Illustration */}

			<ReturnHomeButton />
		</>
	);
};

export {Error403};
