// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {ChangeEvent} from 'react';
import DebouncedSearch from '../../common/components/DebouncedSearch';
import './DateRangePicker.css';
import useLogs from './hooks/useLogs';
import useLogsSearch from './hooks/useLogsSearch';

interface Props {
	handleSearch: (e: ChangeEvent<HTMLInputElement>) => void;
	dateRange?: Array<Date>;
	setDateRange?: (dates: Array<Date>) => void;
	changeQuery: (queryType: string | null, queryValue: string) => void;
	setUserNameType: (type: number | null) => void;
	setLogs?: (logs: string) => void;
}

const TopHeader = ({handleSearch, dateRange, setDateRange, changeQuery, setUserNameType, setLogs}: Props) => {
	const {queryTypesDatas, currentQueryType, changeCurrentQueryType, userData} = useLogsSearch();
	const {data} = useLogs();

	const handleUserChange = (user: string) => {
		const userId = user === '0' ? null : parseInt(user, 10);
		setUserNameType(userId);
	};

	return (
		<div className='row s-searchSection mb-14'>
			<div className='col-md-12'>
				<div className='row'>
					<div className='col-md-7 d-flex'>
						<div className='me-6'>
							<div className='calendarWrap ' style={{backgroundColor: 'white'}}>
								<DateRangePicker
									calendarAriaLabel='Toggle calendar'
									clearAriaLabel='Clear value'
									dayAriaLabel='Day'
									monthAriaLabel='Month'
									nativeInputAriaLabel='Date'
									onChange={setDateRange}
									value={dateRange}
									yearAriaLabel='Year'
									clearIcon={null}
									maxDate={new Date()}
								/>
							</div>
						</div>
						<div className='me-6'>
							{/* <select
								className='form-select form-select-solid '
								data-kt-select2='true'
								data-placeholder='Role'
								data-allow-clear='true'
								data-kt-user-table-filter='role'
								data-hide-search='true'
								style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
								onChange={(event) => {
									handleUserChange(event.target.value);
								}}
							>
								<option style={{color: '#A1A5B7'}} value='0'>
									User
								</option>
								{userData &&
									userData?.data.map((user) => {
										return (
											<option key={user.id} style={{color: '#A1A5B7'}} value={user.id}>
												{user.name}
											</option>
										);
									})}
							</select> */}
						</div>
					</div>
					<div className='col-md-5 '>
						<div className='d-flex justify-content-end'>
							<select
								className='form-select form-select-solid me-3'
								data-kt-select2='true'
								data-placeholder='Role'
								data-allow-clear='true'
								data-kt-user-table-filter='role'
								data-hide-search='true'
								style={{backgroundColor: '#ffffff', borderRadius: '6px', width: '80%'}}
								onChange={(e) => changeCurrentQueryType(e.target.value)}
							>
								{queryTypesDatas &&
									queryTypesDatas?.data.map((qt) => (
										<option value={qt.key} key={qt.key}>
											{qt.value}
										</option>
									))}
							</select>

							<div className='d-flex align-items-center position-relative float-end '>
								<DebouncedSearch
									setLogs={setLogs}
									setSearchTerm={(searchTerm: string) => {
										changeQuery(currentQueryType?.key ?? null, searchTerm);
									}}
									placeholder={`Search by ${currentQueryType?.value}`}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TopHeader;

// {
// 	/* <div className='d-flex  mb-7'>
// 	<div className='p-3'>
// 		<div className='calendarWrap'>
// 			<DateRangePicker
// 				calendarAriaLabel='Toggle calendar'
// 				clearAriaLabel='Clear value'
// 				dayAriaLabel='Day'
// 				monthAriaLabel='Month'
// 				nativeInputAriaLabel='Date'
// 				onChange={setDaterange}
// 				value={daterange}
// 				yearAriaLabel='Year'
// 				clearIcon={null}
// 			/>
// 		</div>
// 	</div>
// 	<div className='me-6 float-left'>
// 		<select
// 			className='form-select form-select-solid '
// 			data-kt-select2='true'
// 			data-placeholder='Role'
// 			data-allow-clear='true'
// 			data-kt-user-table-filter='role'
// 			data-hide-search='true'
// 			style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
// 		>
// 			<option style={{color: '#A1A5B7'}}>Product Category</option>
// 		</select>
// 	</div>
