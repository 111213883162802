/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import clsx from 'clsx';

interface Props {
	text: string;
	onClick: () => void;
	isActive?: boolean;
	isDisabled?: boolean;
	isPrevious?: boolean;
	isNext?: boolean;
}

const makeKey = (isPrevious: boolean, isNext: boolean, text: string) => {
	if (isPrevious) return 'p';
	if (isNext) return 'n';
	return text;
};

export default function PageSelector({
	text,
	onClick,
	isActive = false,
	isDisabled = false,
	isPrevious = false,
	isNext = false,
}: Props) {
	return (
		<li
			key={makeKey(isPrevious, isNext, text)}
			className={clsx('page-item', {
				active: isActive,
				disabled: isDisabled,
				previous: isPrevious,
				next: isNext,
			})}
		>
			<a
				className={clsx('page-link', {
					'page-text': false,
					'me-5': false,
				})}
				onClick={onClick}
				style={{cursor: 'pointer'}}
			>
				{text}
			</a>
		</li>
	);
}
