import React, {useState} from 'react';

interface Props {
	selectedOption: string;
	options: string[];
	onOptionChange: (option: any) => void;
}

const DropButton = ({selectedOption, options, onOptionChange}: Props) => {
	const [selected, setSelected] = useState(selectedOption);

	const handleOptionClick = (option: string) => {
		setSelected(option);
		onOptionChange(option);
	};

	return (
		<div className='dropdown'>
			<button
				className='btn dropdown-toggle'
				type='button'
				id='liveDropdown'
				data-bs-toggle='dropdown'
				aria-expanded='false'
				style={{
					minWidth: '83px',
					width: 'auto',
					height: '36px',
					borderRadius: '8px',
					border: '1px solid #E5E7EB',
					padding: '0 16px',
					fontSize: '14px',
					fontWeight: '500',
					color: '#6B7280',
					backgroundColor: '#F9FAFB',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '12px',
				}}
			>
				{selected}
			</button>
			<ul className='dropdown-menu' aria-labelledby='liveDropdown'>
				{options.map((option) => (
					<li key={option}>
						<button
							className={`dropdown-item ${selected === option ? 'active' : ''} dropdown-item-hover`}
							type='button'
							onClick={() => handleOptionClick(option)}
							style={{
								fontWeight: selected === option ? 'bold' : 'normal',
								backgroundColor: selected === option ? '#E57EAB' : 'transparent',
							}}
						>
							{option}
						</button>
					</li>
				))}
			</ul>
		</div>
	);
};

export default DropButton;
