interface Props {
	count: number;
	status: string;
	color: string;
	background: string;
	marginBottom?: boolean;
}

export default function CounterCard({count, status, color, background, marginBottom = false}: Props) {
	return (
		<div className={`p-3 px-4 rounded-3 ${marginBottom ? 'mb-5' : ''}`} style={{background}}>
			<div className='fw-bold fs-3' style={{color}}>
				{count}
			</div>
			<div className='fw-semibold fs-7' style={{color: '#7E8299'}}>
				{status}
			</div>
		</div>
	);
}
