// import Lottie from 'react-lottie';
import Lottie from 'lottie-react';
import * as animationData from '../../../assets/images/bkash-logo-animation.json';

interface Props {
	height?: string;
}

export default function BkashLoader({height = '80vh'}: Props) {
	const defaultOptions = {
		loop: true,
		autoplay: true,
		animationData,
		rendererSettings: {
			preserveAspectRatio: 'xMidYMid slice',
		},
	};

	return (
		<div className='d-flex flex-column justify-content-center align-items-center' style={{height}}>
			{/* <Lottie options={defaultOptions} height={100} width={70} /> */}
			<Lottie animationData={animationData} loop style={{height: 100, width: 70}} />
			<span className='fs-4 mt-4'>Loading ...</span>
		</div>
	);
}
