import {useState} from 'react';
import {Modal} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import {useAuth} from '../../../../modules/auth';
import useRoleMode from '../../../hooks/useRoleMode';
import InButtonLoader from '../../InButtonLoader';
import corssIcon from '../../../../../assets/images/application/only_cross.svg';

const UserRoleModeButton = () => {
	const {toggleText, toggleRoleMode, isLoading, errorModal, setErrorModal} = useRoleMode();
	const [openModal, setOpenModal] = useState<boolean>(false);

	const isQcMode = toggleText.toLowerCase() === 'switch to qc mode';
	const isSupervisorMode = toggleText.toLowerCase() !== 'switch to qc mode';
	const qcMode = {color: '#D9214E', background: '#FFF5F8', border: '1px solid #D9214E'};
	const supervisorMode = {color: '#0095E8', background: '#FFFFFF', border: '1px solid #0095E8'};

	return (
		<>
			<button
				className='px-5 py-3 fw-bold fs-6 rounded-3'
				style={isQcMode ? qcMode : supervisorMode}
				onClick={() => {
					setOpenModal(true);
				}}
				disabled={isLoading}
			>
				<InButtonLoader isLoading={isLoading} showIcon showText>
					{toggleText}
				</InButtonLoader>
			</button>
			{/* Open Modal */}
			<Modal show={openModal} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered'>
				<div className='modal-body border-none scroll-y text-center'>
					<div className='container'>
						<div className='fs-1 fw-semibold mb-4' style={{color: '#3F4254'}}>
							{isQcMode && (
								<>
									<p className='fs-1 fw-semibold mb-4'>Are you sure?</p>
									<p className='fs-4' style={{color: '#3f4254'}}>
										You want to switch QC Mode
									</p>
								</>
							)}
							{isSupervisorMode && (
								<>
									<p className='fs-1 fw-semibold mb-4'>Are you sure?</p>
									<p className='fs-4' style={{color: '#3f4254'}}>
										You want to switch Supervising Mode
									</p>
								</>
							)}
						</div>
					</div>
				</div>

				<div>
					<div className='d-flex align-items-center justify-content-center py-5'>
						<button
							type='button'
							className='px-5 py-3 fw-bold fs-6 rounded-3	btn btn-danger mx-4'
							onClick={() => {
								setOpenModal(false);
							}}
						>
							No
						</button>
						<button
							type='button'
							className='px-5 py-3 fw-bold fs-6 rounded-3	btn btn-success mx-4'
							onClick={() => {
								toggleRoleMode();
								setOpenModal(false);
							}}
						>
							Yes
						</button>
					</div>
				</div>
			</Modal>
			{/* Error Modal */}
			<Modal show={errorModal} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered'>
				<div className='modal-body border-none scroll-y text-center'>
					<div className='container' style={{paddingTop: 25}}>
						<div className='mb-12'>
							<SVG style={{width: 50, height: 50}} src={corssIcon} />
						</div>
						<div className='fs-1 fw-semibold' style={{color: '#3F4254'}}>
							{isQcMode && (
								<p className='fs-4' style={{color: '#3f4254'}}>
									You can not switch to Qc mode , if you have a pending application as QC. Please take action for your
									assigned application first.
								</p>
							)}
							{isSupervisorMode && (
								<p className='fs-4' style={{color: '#3f4254'}}>
									You can not switch to Supervisor mode , if you have a pending application as Supervisor. Please take
									action for your assigned application first.
								</p>
							)}
						</div>
					</div>
				</div>

				<div>
					<div className='d-flex align-items-center justify-content-center py-5'>
						<button
							type='button'
							className='px-5 py-3 fw-bold fs-6 rounded-3	btn btn-danger mx-4'
							onClick={() => {
								setErrorModal(false);
							}}
						>
							Close
						</button>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default UserRoleModeButton;
