/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/anchor-is-valid */

import clsx from 'clsx';
import {Link} from 'react-router-dom';
import WhiteCard from '../../../../common/components/card/WhiteCard';
import {SortOrder} from '../../../../common/components/data-table/Types';
import {PageLink, PageTitle} from '../../../../common/providers/PageDataProvider';
import {useAuth} from '../../../auth';
import CardHeader from '../../common/components/CardHeader';
import useRoleList from '../../common/useRoleList';

const breadcrumbs: Array<PageLink> = [
	{
		title: 'Roles',
		path: '/roles',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
	{
		title: 'View All Roles',
		path: '',
		isSeparator: false,
		isActive: false,
	},
];

export default function RoleList() {
	const {data, isLoading, sortColumns, sortColumn} = useRoleList();
	const {currentUserInfo} = useAuth();

	const getSortClass = (order: SortOrder) => {
		return clsx(order !== undefined && `table-sort-${order}`);
	};

	return (
		<>
			<PageTitle breadcrumbs={breadcrumbs} showTitleInBreadcrumb={false}>
				Roles
			</PageTitle>

			<WhiteCard
				leftHeader={<CardHeader title='Roles' />}
				rightHeader={
					currentUserInfo?.can_create_role ? (
						<Link to='/roles/create' className='px-5 py-3 fw-bold fs-6 rounded-3 table-action-btn mx-4'>
							Create New Role
						</Link>
					) : null
				}
				extraClasses='p-9'
			>
				<div className='table-responsive mb-9'>
					<table className='table bkash-data-table'>
						<thead className='fw-bold'>
							<tr>
								<th>#</th>
								<th
									className={getSortClass(sortColumns.name)}
									style={{cursor: 'pointer'}}
									onClick={() => {
										sortColumn('name');
									}}
								>
									Name
								</th>
								<th>Description</th>
								<th> </th>
							</tr>
						</thead>
						<tbody className='fs-6 fw-semibold'>
							{(!data || isLoading) && (
								<tr>
									<td colSpan={7} className='text-center'>
										Loading...
									</td>
								</tr>
							)}
							{data && data.data.length === 0 && (
								<tr>
									<td colSpan={7} className='text-center'>
										No Records Found
									</td>
								</tr>
							)}
							{data &&
								data.data.map((role) => {
									return (
										<tr key={role.id}>
											<td>#{role.id}</td>
											<td>{role.name}</td>
											<td>{role.description}</td>
											<td>
												<Link
													to={`/roles/${role.id}`}
													className='fs-8 px-3 py-8px rounded-1'
													onClick={(e) => {
														if (!currentUserInfo?.can_update_role) {
															e.preventDefault();
														}
													}}
													style={{
														background: '#F3F6F9',
														opacity: !currentUserInfo?.can_update_role ? '0.5' : '1',
													}}
												>
													<i className='fa-solid fa-pen-to-square w-1 h-1' style={{color: '#3699FF'}} />
												</Link>
											</td>
										</tr>
									);
								})}
						</tbody>
					</table>
				</div>
			</WhiteCard>
		</>
	);
}
