import {ReactNode} from 'react';

export interface PaginationMeta {
	current_page: number;
	last_page: number;
	per_page: number;
	total: number;
}

export interface PaginationLinks {
	first: string;
	last: string;
	prev: string | null;
	next: string | null;
}

export type SortOrder = 'asc' | 'desc' | undefined;

export const toggleSortOrder = (order: SortOrder): SortOrder => {
	return order === 'asc' ? 'desc' : 'asc';
};

export interface Header {
	text: string;
	isSortable: boolean;
	sortOrder?: SortOrder;
	sortKey?: string;
}

export interface Row {
	key: string | number;
	items: Array<ReactNode>;
}

export interface PaginationProps {
	meta?: PaginationMeta;
	links?: PaginationLinks;
	changeItemPerPage: (count: number) => void;
	setCurrentPage: (page: number) => void;
}
