import {useState} from 'react';
import {useIdleTimer} from 'react-idle-timer';
import {WithChildren} from '../../../../../_metronic/helpers';
import IdleLogoutModal from './IdleLogoutModal';
import useIdleLogout from '../../../hooks/useIdleLogout';

const IdleChecker = ({children}: WithChildren) => {
	const timeout = 900_000;
	const promptBeforeIdle = 30_000;

	const [showModal, setShowModal] = useState<boolean>(false);

	const {logout} = useIdleLogout({
		onLogout: () => setShowModal(false),
	});

	const onIdle = () => {
		logout();
	};

	const onActive = () => {
		setShowModal(false);
	};

	const onPrompt = () => {
		setShowModal(true);
	};

	const {getRemainingTime, activate} = useIdleTimer({
		onIdle,
		onActive,
		onPrompt,
		timeout,
		promptBeforeIdle,
		throttle: 500,
	});

	const handleStillHere = () => {
		activate();
	};

	return (
		<>
			{children}

			<IdleLogoutModal
				show={showModal}
				handleStillHere={handleStillHere}
				remainingSeconds={Math.ceil(getRemainingTime() / 1000)}
			/>
		</>
	);
};

export default IdleChecker;
