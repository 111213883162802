export function trimCharStart(str: string, charlist: string): string {
	if (charlist === undefined) return str;

	return str.replace(new RegExp(`^[${charlist}]+`), '');
}

export function trimCharEnd(str: string, charlist: string): string {
	if (charlist === undefined) return str;

	return str.replace(new RegExp(`[${charlist}]+$`), '');
}

export function trimChar(str: string, charlist: string): string {
	let r = trimCharStart(str, charlist);
	r = trimCharEnd(r, charlist);
	return r;
}

export function ucFirst(str: string): string {
	return str.charAt(0).toUpperCase() + str.slice(1);
}

export function ucWords(str: string, separator = ' '): string {
	return str
		.split(separator)
		.map((word) => {
			return word[0].toUpperCase() + word.substring(1);
		})
		.join(' ');
}

export function ucWordsFromSnakeCase(str: string): string {
	return ucWords(str, '_');
}

export function titleCase(str: string): string {
	return str
		.toLowerCase()
		.split(' ')
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(' ');
}

export function snakeCase(str: string): string {
	return str?.toLowerCase().split(' ').join('_');
}

export function abbreviate(str: string, maxLength = 30): string {
	return `${str.substring(0, maxLength)}...`;
}
