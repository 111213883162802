import React from 'react';
import {QcStatusCount} from '../Types';
import UserStatusItem from './UserStatusItem';

interface Props {
	qcStatusCount: QcStatusCount;
}

const UserStatus = ({qcStatusCount}: Props) => {
	return (
		<div className='d-flex justify-content-between'>
			<UserStatusItem
				colCount={2}
				count={qcStatusCount.logged_in_count}
				status='Logged In Users'
				color='#0095E8'
				background='#FFFFFF'
				marginBottom
				marginRight
			/>
			<UserStatusItem
				colCount={2}
				count={qcStatusCount.online_count}
				status='Online Users'
				color='#2BA579'
				background='#FFFFFF'
				marginBottom
				marginRight
			/>
			<UserStatusItem
				colCount={2}
				count={qcStatusCount.offline_count}
				status='Offline Users'
				color='#D9214E'
				background='#FFFFFF'
				marginBottom
				marginRight
			/>
			<UserStatusItem
				colCount={2}
				count={qcStatusCount.in_queue_count}
				status='Total Queue'
				color='#F1BC00'
				background='#FFFFFF'
				marginBottom
				marginRight
			/>
			<UserStatusItem
				colCount={2}
				count={qcStatusCount.max_in_queue_time}
				unit='min'
				status='Max Queue'
				color='#F69B11'
				background='#FFFFFF'
				marginBottom
				marginRight
			/>
		</div>
	);
};

export default UserStatus;
