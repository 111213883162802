import {Route, Routes} from 'react-router-dom';
import Login from './pages/login/Login';
import AuthLayout from './common/components/AuthLayout';
import AzureAuth from './pages/login/AzureAuth';

const AuthRoutes = () => (
	<Routes>
		<Route element={<AuthLayout />}>
			<Route path='azure' element={<AzureAuth />} />
			<Route path='login' element={<Login />} />
			<Route index element={<Login />} />
		</Route>
	</Routes>
);

export {AuthRoutes};
