import {createContext, FC, useCallback, useContext, useMemo} from 'react';
import {WithChildren} from '../../../_metronic/helpers';

interface DataContextProps {
	query: () => void;
	command: () => void;
	authQuery: () => void;
	authCommand: () => void;
}

const DataContext = createContext<DataContextProps>({
	query: () => {},
	command: () => {},
	authQuery: () => {},
	authCommand: () => {},
});

export function useData() {
	return useContext(DataContext);
}

const DataProvider: FC<WithChildren> = ({children}) => {
	const query = useCallback(() => {}, []);
	const command = useCallback(() => {}, []);
	const authQuery = useCallback(() => {}, []);
	const authCommand = useCallback(() => {}, []);

	const value = useMemo(
		() => ({
			query,
			command,
			authQuery,
			authCommand,
		}),
		[query, command, authQuery, authCommand],
	);

	return <DataContext.Provider value={value}>{children}</DataContext.Provider>;
};

export default DataProvider;
