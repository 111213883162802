import {covertSecondsToPaddedMinutesAndSeconds} from '../../../common/helpers/DateHelper';

interface Props {
	title: string;
	sla: number;
	color: string;
}

export default function SLAItem({title, sla, color}: Props) {
	const {minute, second} = covertSecondsToPaddedMinutesAndSeconds(sla);
	return (
		<div className='px-2 mb-6' style={{color}}>
			<div className='fw-semibold fs-5'>{title}</div>
			<div className='fw-bold fs-2tx'>
				{minute !== '00' && (
					<>
						{minute}
						<span className='fs-1 fw-normal'>m</span>&nbsp;
					</>
				)}
				{second}
				<span className='fs-1 fw-normal'>s</span>&nbsp;
			</div>
		</div>
	);
}
