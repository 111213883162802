import {useState} from 'react';
import {useReportQuery} from '../../../common/hooks/QueryHooks';
import {getAppStats, getLast6MonthOptions} from '../helpers/DashboardHelper';
import {AppStats, MonthlyAppStats} from '../Types';

const useMonthlyAppStats = (initialMonth: string, appStats: AppStats | undefined) => {
	const [selectedOption, setSelectedOption] = useState<string>(initialMonth);
	const last6Months = getLast6MonthOptions();
	const monthData = last6Months.find((opt) => opt.month === selectedOption);

	const year = monthData?.year || new Date().getFullYear();
	const month = monthData?.monthNumber || new Date().getMonth() + 1;
	const url = `/dashboard/app/stats?year=${year}&month=${month}`;

	const {data, isLoading} = useReportQuery<MonthlyAppStats>(url, url);

	const monthlyAppStats = selectedOption === last6Months[0].month ? getAppStats(appStats) : getAppStats(data?.data);

	const handleOptionChange = (option: string) => {
		setSelectedOption(option);
	};

	return {monthlyAppStats, isLoading, handleOptionChange};
};

export default useMonthlyAppStats;
