import DataTable from '../../common/components/data-table/DataTable';
import {Header, Row} from '../../common/components/data-table/Types';
import {PageLink, PageTitle} from '../../common/providers/PageDataProvider';
import CardHeader from '../users/common/components/CardHeader';
import './AutoVerifiedList.css';

import AutoVerifiedListReportFilter from './AutoVerifiedListReportFilter';
import useAutoVerifiedList from './useAutoVerifiedList';
import WhiteCard from '../../common/components/card/WhiteCard';
import {AutoVerifiedListType} from './AutoVerifiedListType';

const breadcrumbs: Array<PageLink> = [
	{
		title: 'Report',
		path: '/ekyc-reports',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
];

const AutoVerifiedList = () => {
	const {
		data,
		isLoading,
		dateRange,
		downloadUrl,
		setCurrentPage,
		changeItemPerPage,
		setDateRange,
		setStatus,
		changeQuery,
	} = useAutoVerifiedList();

	const headers: Array<Header> = [
		{
			text: 'Reference ID',
			isSortable: false,
		},
		{
			text: 'Application ID',
			isSortable: false,
		},
		{
			text: 'Wallet No',
			isSortable: false,
		},
		{
			text: 'Mao No',
			isSortable: false,
		},
		{
			text: 'NID',
			isSortable: false,
		},
		{
			text: 'Date of Birth',
			isSortable: false,
		},
		{
			text: 'Application Status',
			isSortable: false,
		},
		{
			text: 'MQC Status',
			isSortable: false,
		},
		{
			text: 'Status',
			isSortable: false,
		},
		{
			text: 'Verification Time',
			isSortable: false,
		},
	];

	const rows: Array<Row> = (data?.data || []).map((item: AutoVerifiedListType) => {
		return {
			key: item.referenceId,
			items: [
				item.referenceId,
				item.applicationId,
				item.wallet,
				item.maoNumber,
				item.nid,
				item.dob,
				item.applicationStatus,
				item.mqcStatus,
				item.status,
				item.verificationTime,
			],
		};
	});

	return (
		<div>
			<PageTitle breadcrumbs={[]}>Auto Verified List Report</PageTitle>
			<AutoVerifiedListReportFilter
				dateRange={dateRange}
				setDateRange={setDateRange}
				setStatus={setStatus}
				changeQuery={changeQuery}
			/>

			<WhiteCard
				leftHeader={<CardHeader title='Auto Verified List Report' />}
				rightHeader={
					<button
						className='btn btn-primary'
						onClick={() => {
							window.open(downloadUrl, '_blank');
						}}
					>
						Download Report
					</button>
				}
				extraClasses='p-9'
			>
				<div className='merchant-table'>
					<DataTable
						headers={headers}
						isLoading={isLoading}
						data={rows}
						changeItemPerPage={changeItemPerPage}
						meta={data?.meta}
						links={data?.links}
						setCurrentPage={setCurrentPage}
					/>
				</div>
			</WhiteCard>
		</div>
	);
};

export default AutoVerifiedList;
