import {UserInfo} from '../../modules/auth/common/Types';

export function getHomePageRoute(user: UserInfo | undefined): string {
	if (user?.can_read_dashboard) {
		return '/dashboard';
	}
	if (user?.can_read_application) {
		return '/applications/assigned-to-me';
	}
	if (user?.can_access_cs_portal) {
		return '/applications/customer-support';
	}
	return '/errors/403';
}
