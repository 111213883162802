import {Routes, Route} from 'react-router-dom';
import CreateRole from './pages/create-role/CreateRole';
import RoleList from './pages/role-list/RoleList';
import UpdateRole from './pages/update-role/UpdateRole';

const RoleRoutes = () => (
	<Routes>
		<Route path='/' element={<RoleList />} />
		<Route path='/create' element={<CreateRole />} />
		<Route path='/:id' element={<UpdateRole />} />
	</Routes>
);

export default RoleRoutes;
