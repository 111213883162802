export const editorToolbarSettings = {
	options: ['inline', 'fontSize', 'fontFamily', 'link', 'image'],
	inline: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
		options: ['bold', 'italic', 'underline', 'strikethrough'],
	},
	fontSize: {
		options: [8, 9, 10, 11, 12, 14, 16, 18, 24, 30, 36, 48, 60, 72, 96],
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
	},
	fontFamily: {
		options: ['Arial', 'Georgia', 'Impact', 'Tahoma', 'Times New Roman', 'Verdana'],
		className: undefined,
		component: undefined,
		dropdownClassName: undefined,
	},

	link: {
		inDropdown: false,
		className: undefined,
		component: undefined,
		popupClassName: undefined,
		dropdownClassName: undefined,
		showOpenOptionOnHover: true,
		defaultTargetOption: '_self',
		options: ['link', 'unlink'],
		link: {className: undefined},
		unlink: {className: undefined},
		linkCallback: undefined,
	},
	image: {
		className: undefined,
		component: undefined,
		popupClassName: undefined,
		urlEnabled: true,
		uploadEnabled: true,
		alignmentEnabled: true,
		uploadCallback: undefined,
		previewImage: false,
		inputAccept: 'image/gif,image/jpeg,image/jpg,image/png,image/svg',
		alt: {present: false, mandatory: false},
		defaultSize: {
			height: 'auto',
			width: 'auto',
		},
	},
};
