/* eslint-disable @typescript-eslint/return-await */

import {useCallback} from 'react';
import {useAuth} from '../../modules/auth/providers/AuthProvider';
import {isUnauthorizedHttpError, postHttp, addAuthHeader} from './ApiClient';
import {useApiUrl} from '../providers/ConfigProvider';

interface RefreshProps {
	refreshUrl: string;
	error: unknown;
	currentToken: string;
	logoutWithoutReadyCheck: () => void;
	updateToken: (token: string) => void;
}

interface RefreshResponse {
	token: string;
}

class TokenRefresher {
	private static ongoingRequest?: Promise<RefreshResponse> = undefined;

	private static getRequest(refreshUrl: string, currentToken: string) {
		if (TokenRefresher.ongoingRequest === undefined) {
			TokenRefresher.ongoingRequest = postHttp<RefreshResponse>(refreshUrl, {}, addAuthHeader({}, currentToken));
		}

		return TokenRefresher.ongoingRequest;
	}

	private static removeRequest() {
		TokenRefresher.ongoingRequest = undefined;
	}

	static async refresh({refreshUrl, error, currentToken, logoutWithoutReadyCheck, updateToken}: RefreshProps) {
		if (!isUnauthorizedHttpError(error)) throw error;

		try {
			const request = TokenRefresher.getRequest(refreshUrl, currentToken);
			const {token} = await request;
			updateToken(token);
			TokenRefresher.removeRequest();
			return token;
		} catch (error2) {
			if (!isUnauthorizedHttpError(error2)) throw error2;
			logoutWithoutReadyCheck();
			TokenRefresher.removeRequest();
			return null;
		} finally {
			TokenRefresher.removeRequest();
		}
	}
}

const useTokenRefresh = () => {
	const {currentUser, logoutWithoutReadyCheck, updateToken} = useAuth();
	const refreshUrl = useApiUrl('token/refresh');
	const currentToken = currentUser?.token ?? '';
	return useCallback(
		async (error: unknown) => {
			return await TokenRefresher.refresh({refreshUrl, error, currentToken, logoutWithoutReadyCheck, updateToken});
		},
		[currentToken, logoutWithoutReadyCheck, refreshUrl, updateToken],
	);
};

export const useCallWithRefreshedToken = <T>() => {
	const refresh = useTokenRefresh();
	return useCallback(
		async (normalCall: () => Promise<T>, refreshedCall: (newToken: string) => Promise<T>) => {
			try {
				return await normalCall();
			} catch (error) {
				const newToken = await refresh(error);
				if (newToken) return await refreshedCall(newToken);
				throw error;
			}
		},
		[refresh],
	);
};
