import clsx from 'clsx';
import {WithChildren} from '../../../../_metronic/helpers';
import {useLayout} from '../../../../_metronic/layout/core';
import {useAuth} from '../../../modules/auth';

const Content = ({children}: WithChildren) => {
	const {config, classes} = useLayout();
	const {shouldShowOfflineBanner} = useAuth();

	const appContentContainer = config.app?.content?.container;
	return (
		<div
			id='kt_app_content'
			className={clsx('app-content flex-column-fluid', classes.content.join(' '), config?.app?.content?.class)}
		>
			{appContentContainer ? (
				<div
					id='kt_app_content_container'
					className={clsx('app-container', classes.contentContainer.join(' '), {
						'container-xxl': appContentContainer === 'fixed',
						'container-fluid': appContentContainer === 'fluid',
					})}
					style={{marginTop: shouldShowOfflineBanner ? '50px' : '0'}}
				>
					{children}
				</div>
			) : (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>{children}</>
			)}
		</div>
	);
};

export {Content};
