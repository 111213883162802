import React from 'react';
import FileUpload from './FileUpload';
import DropFile from './DropFile';

const PosowUpload = () => {
	return (
		<div>
			{/* <FileUpload /> */}
			<DropFile />
		</div>
	);
};

export default PosowUpload;
