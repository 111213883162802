/* eslint-disable jsx-a11y/anchor-is-valid */
import {Route, Routes} from 'react-router-dom';
import {Error500} from './pages/Error500';
import {Error404} from './pages/Error404';
import {ErrorsLayout} from './components/ErrorsLayout';
import {Error403} from './pages/Error403';

const ErrorRoutes = () => (
	<Routes>
		<Route element={<ErrorsLayout />}>
			<Route path='404' element={<Error404 />} />
			<Route path='403' element={<Error403 />} />
			<Route path='500' element={<Error500 />} />
			<Route index element={<Error404 />} />
		</Route>
	</Routes>
);

export {ErrorRoutes};
