import {useCallback, useEffect, useState} from 'react';
import {useAuthedQuery} from '../../../common/hooks/QueryHooks';
import {QueryDropDownData, QueryTypes, UserTypes} from '../Types';

const useLogsSearch = () => {
	const {data: queryTypesDatas} = useAuthedQuery<QueryTypes>('logs/query-type', 'logs/query-type');
	const {data: userData} = useAuthedQuery<UserTypes>('/users', '/users');
	const [currentQueryType, setCurrentQueryType] = useState<QueryDropDownData | null>(null);

	useEffect(() => {
		if (!queryTypesDatas) return;
		if (queryTypesDatas.data.length === 0) return;
		setCurrentQueryType(queryTypesDatas.data[0]);
	}, [queryTypesDatas]);

	const changeCurrentQueryType = useCallback(
		(value: string) => {
			if (!queryTypesDatas) return;
			queryTypesDatas.data.forEach((v: QueryDropDownData) => {
				if (v.key === value) setCurrentQueryType(v);
			});
		},
		[queryTypesDatas],
	);

	return {queryTypesDatas, changeCurrentQueryType, currentQueryType, userData};
};

export default useLogsSearch;
