import {PageLink, PageTitle} from '../../../../common/providers/PageDataProvider';
import RoleTable from './partials/RoleTable';

const breadcrumbs: Array<PageLink> = [
	{
		title: 'Roles',
		path: '/roles',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
	{
		title: 'Create New Role',
		path: '',
		isSeparator: false,
		isActive: false,
	},
];

export default function CreateRole() {
	return (
		<>
			<PageTitle breadcrumbs={breadcrumbs} showTitleInBreadcrumb={false}>
				Roles
			</PageTitle>
			<RoleTable />
		</>
	);
}
