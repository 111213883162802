import {useEffect, useRef, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {toggleSortOrder} from '../../../../../common/components/data-table/Types';
import {trimCharStart} from '../../../../../common/helpers';
import {convertToMysqlDateString} from '../../../../../common/helpers/DateHelper';
import {addQueryParamsToUrl} from '../../../../../common/helpers/UrlHelper';
import {useAuthedMutation, useAuthedQuery} from '../../../../../common/hooks/QueryHooks';
import {Application, ApplicationTableData, SortColumns, Value} from '../Queuetypes';

const useApplicationPdfDownload = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(20);
	const [accountType, setAccountType] = useState<string | null>(null);
	const [status, setStatus] = useState<string | null>(null);
	const [mqcStatus, setMqcStatus] = useState<string | null>(null);
	const [queryType, setQueryType] = useState<string | null>(null);
	const [queryValue, setQueryValue] = useState<string | null>(null);
	const [sortColumns, setSortColumns] = useState<SortColumns>({});
	// const [daterange, setDaterange] = useState([getLastWeeksDate(), new Date()]);
	const [daterange, setDaterange] = useState<[Value, Value]>([null, null]);

	const [list, setList] = useState<Array<number>>([]);
	const [formatType, setFormatType] = useState<string | null>(null);
	const [pages, setPages] = useState<string[]>([]);
	const [success, setSuccess] = useState(undefined);
	const [openSuccessModal, setOpenSuccessModal] = useState(false);
	const [error, setError] = useState(undefined);
	const [openErrorModal, setErrorModal] = useState(false);

	const [selectedFile, setSelectedFile] = useState<File | null>(null);
	const fileInputRef = useRef<HTMLInputElement | null>(null);
	const [uploadedData, setUploadedData] = useState<Array<Application>>([]);
	const [isUploading, setIsUploading] = useState(false);
	const [uploadModal, setUploadModal] = useState(false);

	const {mutate: pdfUpload, isLoading: pdfUploading} = useAuthedMutation('/pdf/bulk-upload');

	const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
		const file = event.target.files?.[0];
		if (file) {
			setSelectedFile(file);
		}
	};

	const handleButtonClick = async () => {
		setIsUploading(true);
		if (selectedFile) {
			const formData = new FormData();
			formData.append('file', selectedFile);
			try {
				const response = await pdfUpload({data: formData});
				if (response) {
					setUploadedData((response as any).data);
					setIsUploading(false);
				}
			} catch (err) {
				console.error('Error uploading file:', err);
				setUploadedData([]);
				setIsUploading(false);
			}
			setUploadModal(false);
			setSelectedFile(null);
		}
	};

	const closeSuccessModal = () => {
		setSuccess(undefined);
		setOpenSuccessModal(false);
	};

	const closeErrorModal = () => {
		setError(undefined);
		setErrorModal(false);
	};

	useEffect(() => {
		setCurrentPage(1);
	}, [status, mqcStatus, accountType]);

	const url = addQueryParamsToUrl('/pdf/applications', {
		page: currentPage,
		limit: itemPerPage,
		account_type: accountType,
		status,
		mqc_status: mqcStatus,
		query_type: queryType,
		query_value: queryValue,
		sort: Object.entries(sortColumns),
		created_at_start_date: daterange && daterange[0] ? convertToMysqlDateString(daterange[0]) : '',
		created_at_end_date: daterange && daterange[1] ? convertToMysqlDateString(daterange[1]) : '',
	});
	const changeItemsPerpage = (page: number) => {
		setCurrentPage(1);
		setItemPerPage(page);
	};
	const {data, isLoading, refetch} = useAuthedQuery<ApplicationTableData>(trimCharStart(url, '/'), url);

	const changeQuery = (t: string | null, v: string) => {
		if (t === null || v === '') {
			setQueryType(null);
			setQueryValue(null);
		} else {
			setQueryType(t);
			setQueryValue(v);
		}
	};

	const sortColumn = (key: string) => {
		const newSortColumns = {...sortColumns};
		const castKey = key as keyof SortColumns;
		newSortColumns[castKey] = toggleSortOrder(sortColumns[castKey]);
		setSortColumns(newSortColumns);
	};

	const {mutate, isLoading: pdfLoading} = useAuthedMutation('/pdf/bulk-download');

	const downloadPdf = async () => {
		try {
			const res = await mutate({
				data: {
					application_ids: list,
					format_type: formatType,
					pages,
				},
			});
			setSuccess((res as any)?.message);
			setOpenSuccessModal(true);
		} catch (err) {
			// Handle error
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setError((err as any).response.data.message);
			setErrorModal(true);
		}
	};

	return {
		data,
		refetch,
		isLoading,
		setCurrentPage,
		changeItemsPerpage,
		accountType,
		setAccountType,
		setStatus,
		setMqcStatus,
		changeQuery,
		sortColumns,
		sortColumn,
		daterange,
		setDaterange,
		list,
		setList,
		formatType,
		setFormatType,
		pages,
		setPages,
		downloadPdf,
		closeSuccessModal,
		success,
		openSuccessModal,
		closeErrorModal,
		error,
		openErrorModal,
		pdfLoading,
		fileInputRef,
		handleFileChange,
		handleButtonClick,
		uploadedData,
		isUploading,
		selectedFile,
		setSelectedFile,
		uploadModal,
		setUploadModal,
	};
};

export default useApplicationPdfDownload;
