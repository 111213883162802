import {useCallback, useState} from 'react';
import {useAuth} from '../../modules/auth';
import {useAuthedMutation} from './QueryHooks';

interface Props {
	onLogout: () => void;
}

const useLogout = ({onLogout}: Props) => {
	const {currentUser, logout: logoutOnLocal} = useAuth();
	const [showError, setShowError] = useState(false);
	const [errorMessage, setErrorMessage] = useState<string>('');

	const {mutate, isLoading} = useAuthedMutation('/logout');

	const logout = useCallback(async () => {
		if (!currentUser || currentUser.isReady) return;

		try {
			await mutate({});
			logoutOnLocal();
			setErrorMessage('');
			setShowError(false);
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setErrorMessage(error.response.data.message);
			setShowError(true);
		}
		onLogout();
	}, [mutate, logoutOnLocal, currentUser, onLogout]);

	return {logout, isLoading, showError, errorMessage, setShowError};
};

export default useLogout;
