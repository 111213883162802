import {ReactNode} from 'react';
import {useLocation} from 'react-router-dom';
import {WithChildren} from '../../../../_metronic/helpers';

interface Props {
	leftHeader: ReactNode;
	rightHeader: ReactNode;
	extraClasses?: string;
}

export default function WhiteCard({leftHeader, rightHeader, extraClasses = '', children}: Props & WithChildren) {
	// const page = useLocation().pathname;
	// const pagination = [
	// 	'/applications/customer-support',
	// 	'/applications/all',
	// 	'/applications/in-queue',
	// 	'/applications/assigned-to-me',
	// 	'/applications/history',
	// ];
	// const is = pagination.includes(page);

	return (
		<div
			className={`card card-flush mb-5 mb-xl-10 p-7 ${extraClasses}`}
			style={{
				backgroundColor: '#FFFFFF',
			}}
		>
			<div className='d-flex flex-column'>
				{/* <div className={`d-flex ${is ? 'justify-content-end' : 'justify-content-between'} align-items-center mb-5`}>
					{leftHeader}
					{rightHeader}
				</div> */}
				<div className='d-flex justify-content-between align-items-center mb-5'>
					{leftHeader}
					{rightHeader}
				</div>
				<div>{children}</div>
			</div>
		</div>
	);
}
