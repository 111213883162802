type ArrayParamItem = string | number | Array<ArrayParamItem>;

function isNotEmpty(item: unknown) {
	if (Array.isArray(item)) return item.length !== 0;
	return item !== null && item !== undefined;
}

function makeValue(key: string, item: string | number) {
	return `${key}=${item}`;
}

export function stringifyQueryParamsArray(keyPrefix: string, arr: Array<ArrayParamItem>): string {
	return arr
		.filter((item) => isNotEmpty(item))
		.map((item, i) => {
			const key = `${keyPrefix}[${i}]`;
			if (Array.isArray(item)) {
				return stringifyQueryParamsArray(key, item);
			}
			return makeValue(key, item);
		})
		.join('&');
}

export function stringifyQueryParams(params: object): string {
	return Object.entries(params)
		.filter((item) => isNotEmpty(item[1]))
		.map((obj) => {
			if (Array.isArray(obj[1])) {
				return stringifyQueryParamsArray(obj[0], obj[1]);
			}
			return makeValue(obj[0], obj[1]);
		})
		.join('&');
}

export function addQueryParamsToUrl(url: string, paramsObj: object): string {
	const params = stringifyQueryParams(paramsObj);
	return params === '' ? url : `${url}?${params}`;
}
