import {useCallback, useState} from 'react';
import {useAuth} from '../../modules/auth';
import {useAuthedMutation} from './QueryHooks';

interface Props {
	onLogout: () => void;
}

const useIdleLogout = ({onLogout}: Props) => {
	const {currentUser, logout: logoutOnLocal, logoutWithoutReadyCheck} = useAuth();
	const [errorMessage, setErrorMessage] = useState<string>('');

	const {mutate, isLoading} = useAuthedMutation('/idle-logout');

	const logout = useCallback(async () => {
		if (!currentUser) return;

		try {
			await mutate({});
			// logoutOnLocal();
			logoutWithoutReadyCheck();
			setErrorMessage('');
		} catch (error) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			setErrorMessage(error.response.data.message);
		}
		onLogout();
	}, [mutate, currentUser, onLogout, logoutWithoutReadyCheck]);

	return {logout, isLoading, errorMessage};
};

export default useIdleLogout;
