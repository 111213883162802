import React, {useState, useRef, useEffect} from 'react';

interface TimeRangeButtonProps {
	onTimeChange: (range: [string, string]) => void;
}

const TimeRangeButton: React.FC<TimeRangeButtonProps> = ({onTimeChange}) => {
	const [isOpen, setIsOpen] = useState(false);
	const [buttonText, setButtonText] = useState('Today');
	const [startTime, setStartTime] = useState('00:00');
	const [endTime, setEndTime] = useState('23:59');
	const dropdownRef = useRef<HTMLDivElement>(null);

	useEffect(() => {
		if (buttonText === 'Today') {
			onTimeChange(['00:00', '23:59']);
		}
	}, [buttonText, onTimeChange]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
				setIsOpen(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const toggleDropdown = () => {
		setIsOpen(!isOpen);
	};

	const handleStartTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newStartTime = e.target.value;
		setStartTime(newStartTime);

		if (newStartTime > endTime) {
			setEndTime(newStartTime);
		}
	};

	const handleEndTimeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const newEndTime = e.target.value;

		if (newEndTime >= startTime) {
			setEndTime(newEndTime);
		} else {
			e.target.value = endTime;
		}
	};

	const applyTimeRange = () => {
		onTimeChange([startTime, endTime]);
		setButtonText(`${startTime} - ${endTime}`);
		setIsOpen(false);
	};

	const resetToToday = () => {
		setStartTime('00:00');
		setEndTime('23:59');
		onTimeChange(['00:00', '23:59']);
		setButtonText('Today');
		setIsOpen(false);
	};

	return (
		<div className='position-relative' ref={dropdownRef}>
			<button
				className='btn'
				type='button'
				onClick={toggleDropdown}
				style={{
					minWidth: '83px',
					width: 'auto',
					height: '36px',
					borderRadius: '8px',
					border: '1px solid #E5E7EB',
					padding: '0 16px',
					fontSize: '14px',
					fontWeight: '500',
					color: '#6B7280',
					backgroundColor: '#F9FAFB',
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					gap: '12px',
				}}
			>
				{buttonText}
				<i className={`bi bi-chevron-${isOpen ? 'up' : 'down'}`} />
			</button>

			{isOpen && (
				<div
					className='position-absolute end-0 mt-1 p-3 rounded shadow-sm bg-white'
					style={{
						width: '300px',
						zIndex: 1000,
						border: '1px solid #E5E7EB',
						borderRadius: '8px',
					}}
				>
					<div className='mb-3'>
						<div className='mb-2' style={{fontWeight: '500', color: '#374151'}}>
							Select Time Range
						</div>
						<div className='d-flex gap-3 mb-3'>
							<div>
								<label className='form-label' style={{fontSize: '14px', color: '#6B7280'}}>
									Start Time
								</label>
								<input
									type='time'
									className='form-control'
									value={startTime}
									onChange={handleStartTimeChange}
									style={{
										height: '36px',
										borderRadius: '8px',
										border: '1px solid #E5E7EB',
										fontSize: '14px',
									}}
								/>
							</div>
							<div>
								<label className='form-label' style={{fontSize: '14px', color: '#6B7280'}}>
									End Time
								</label>
								<input
									type='time'
									className='form-control'
									value={endTime}
									onChange={handleEndTimeChange}
									style={{
										height: '36px',
										borderRadius: '8px',
										border: '1px solid #E5E7EB',
										fontSize: '14px',
									}}
									min={startTime}
								/>
							</div>
						</div>
					</div>

					<div className='d-flex justify-content-between'>
						<button
							className='btn'
							onClick={resetToToday}
							style={{
								height: '36px',
								borderRadius: '8px',
								border: '1px solid #E5E7EB',
								padding: '0 16px',
								fontSize: '14px',
								fontWeight: '500',
								color: '#6B7280',
								backgroundColor: '#F9FAFB',
							}}
						>
							Reset
						</button>
						<button
							className='btn'
							onClick={applyTimeRange}
							style={{
								height: '36px',
								borderRadius: '8px',
								border: '1px solid #E57EAB',
								padding: '0 16px',
								fontSize: '14px',
								fontWeight: '500',
								color: '#FFFFFF',
								backgroundColor: '#E57EAB',
							}}
						>
							Apply
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default TimeRangeButton;
