import {useLocation} from 'react-router-dom';

interface Props {
	title: string;
}

const CardHeader = ({title}: Props) => {
	// const page = useLocation().pathname;
	// const pagination = [
	// 	'/applications/customer-support',
	// 	'/applications/all',
	// 	'/applications/in-queue',
	// 	'/applications/assigned-to-me',
	// 	'/applications/history',
	// ];

	// return pagination.includes(page) ? null : <div className='fw-bold fs-3 table-title'>{title}</div>;

	return <div className='fw-bold fs-3 table-title'>{title}</div>;
};

export default CardHeader;
