import {createContext, FC, useCallback, useContext, useMemo} from 'react';
import {WithChildren} from '../../../_metronic/helpers';

interface DataContextProps {
	make: () => void;
}

const ServiceLocatorContext = createContext<DataContextProps>({
	make: () => {},
});

export const useLocator = () => {
	return useContext(ServiceLocatorContext);
};

interface Props {
	services: any;
}

const ServiceLocatorProvider: FC<Props & WithChildren> = ({children, services}) => {
	const make = useCallback(() => {}, []);

	const value = useMemo(
		() => ({
			make,
		}),
		[make],
	);

	return <ServiceLocatorContext.Provider value={value}>{children}</ServiceLocatorContext.Provider>;
};

export default ServiceLocatorProvider;
