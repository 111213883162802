import {WithChildren} from '../../../../_metronic/helpers';
import WhiteCard from '../../../common/components/card/WhiteCard';
import DateFilterButtons from './DateFilters';

interface Props {
	title: string;
	isTodaySelected: boolean;
	setIsTodaySelected: (selectedState: boolean) => void;
}

export default function Card({title, children, isTodaySelected, setIsTodaySelected}: Props & WithChildren) {
	return (
		<WhiteCard
			leftHeader={
				<div className='fw-bold fs-3 text-uppercase' style={{color: '#464E5F'}}>
					{title}
				</div>
			}
			rightHeader={
				<div className='fw-semibold fs-7' style={{color: '#80808F'}}>
					<DateFilterButtons setIsTodaySelected={setIsTodaySelected} isTodaySelected={isTodaySelected} />
				</div>
			}
		>
			{children}
		</WhiteCard>
	);
}
