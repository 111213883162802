/* eslint-disable react/jsx-no-useless-fragment */
import {ReactElement, ReactNode} from 'react';

const convertNodeToElement = (node: ReactNode): ReactElement | null => {
	if (node === undefined) return null;

	return <>{node}</>;
};

export {convertNodeToElement};
