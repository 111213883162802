export enum ProductName {
	pra = 'pra',
	liteA = 'lite_a',
	liteB = 'lite_b',
	medium = 'medium',
}

export interface Product {
	name: string;
	display_name: string;
}

export const products: Array<Product> = [
	{
		name: ProductName.pra,
		display_name: 'PRA',
	},
	{
		name: ProductName.liteA,
		display_name: 'Lite A',
	},
	{
		name: ProductName.liteB,
		display_name: 'Lite B',
	},
	{
		name: ProductName.medium,
		display_name: 'Medium',
	},
];
