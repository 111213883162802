import {useEffect} from 'react';
import {Outlet, useLocation} from 'react-router-dom';
import {reInitMenu} from '../../../../_metronic/helpers';
import {ScrollTop} from '../../../../_metronic/layout/components/scroll-top';
import {PageDataProvider} from '../../providers/PageDataProvider';
import Breadcrumb from './breadcrumb/Breadcrumb';
import {HeaderWrapper} from './header';
import {Sidebar} from './sidebar';
import {Content} from './Content';
import IdleChecker from './idle-checker/IdleChecker';

const MasterLayout = () => {
	const location = useLocation();
	useEffect(() => {
		reInitMenu();
	}, [location.key]);

	return (
		<IdleChecker>
			<PageDataProvider>
				<div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
					<div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
						<HeaderWrapper />
						<div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
							<Sidebar />
							<div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
								<div className='d-flex flex-column flex-column-fluid'>
									<Content>
										<Breadcrumb />
										<Outlet />
									</Content>
								</div>
							</div>
						</div>
					</div>
				</div>

				<ScrollTop />
			</PageDataProvider>
		</IdleChecker>
	);
};

export {MasterLayout};
