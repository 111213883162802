import {useState} from 'react';
import {useAuthedMutation, useAuthedQuery} from './QueryHooks';
import {NotificationList} from '../types/Notifications';
import {addQueryParamsToUrl} from '../helpers/UrlHelper';
import {trimCharStart} from '../helpers';

interface Props {
	initialItemPerPage?: number;
}

const useNotifications = ({initialItemPerPage = 10}: Props) => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(initialItemPerPage);

	const url = addQueryParamsToUrl('/notifications', {
		page: currentPage,
		item_per_page: itemPerPage,
	});

	const changeItemPerPage = (page: number) => {
		setCurrentPage(1);
		setItemPerPage(page);
	};

	const {data, isLoading, refetch} = useAuthedQuery<NotificationList>(trimCharStart(url, '/'), url);
	return {
		data,
		isLoading,
		setCurrentPage,
		changeItemPerPage,
		refetch,
	};
};

export default useNotifications;
