import {Badge, ProgressBar} from 'react-bootstrap';
import WhiteCard from '../../../../common/components/card/WhiteCard';
import DataTable from '../../../../common/components/data-table/DataTable';
import {Header, Row} from '../../../../common/components/data-table/Types';
import {PageTitle} from '../../../../common/providers/PageDataProvider';
import useHistoryData from './hooks/useHistoryData';
import {History} from './Types';
import CardHeader from '../../../users/common/components/CardHeader';
import {config} from '../../../../common/providers/ConfigProvider';
import {useAuth} from '../../../auth';

const ApplicationPdfDownloadHistory = () => {
	const {currentUser} = useAuth();
	const {data, isLoading, setCurrentPage, changeItemPerPage} = useHistoryData();

	const headers: Array<Header> = [
		{
			text: 'Identifier',
			isSortable: false,
		},
		{
			text: 'Requested On Date',
			isSortable: false,
		},
		{
			text: 'Requested On Time',
			isSortable: false,
		},
		{
			text: 'Status',
			isSortable: false,
		},
	];

	const rows: Array<Row> = (data?.data || []).map((history: History) => {
		let item;
		if (history.is_failed) {
			item = <Badge bg='danger' style={{fontSize: '15px'}}>Failed</Badge>
		} else if (history.is_completed) {
			item =(<a href={`${config.apiUrl}/api/pdf/bulk-download/${history.key}?token=${currentUser?.token}`} download><Badge
					style={{fontSize: '15px'}}>Download</Badge></a>);
		} else {
			item = <ProgressBar style={{fontWeight: "bold"}} now={history.percentage} label={`${history.percentage}%`} variant={"success"} striped/>
		}
		return {
			key: history.id,
			items: [history.key, history.requested_on_date, history.requested_on_time, item],
		};
	});
	return (
		<>
			<PageTitle breadcrumbs={[]}>Download History</PageTitle>

			<WhiteCard
				leftHeader={<CardHeader title='Last 15 Days Download'/>}
				rightHeader={<div className='mb-10'/>}
				extraClasses='p-9'
			>
				<DataTable
					headers={headers}
					isLoading={isLoading}
					data={rows}
					changeItemPerPage={changeItemPerPage}
					meta={data?.meta}
					links={data?.links}
					setCurrentPage={setCurrentPage}
				/>
			</WhiteCard>
		</>
	);
};

export default ApplicationPdfDownloadHistory;
