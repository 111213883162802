import debounce from 'lodash.debounce';
import {ChangeEvent, useCallback, useMemo, useState} from 'react';
import WhiteCard from '../../common/components/card/WhiteCard';
import DataTable from '../../common/components/data-table/DataTable';
import {Header, Row} from '../../common/components/data-table/Types';
import {PageTitle} from '../../common/providers/PageDataProvider';
import CardHeader from '../users/common/components/CardHeader';
import './css/Logs.css';
import useLogs from './hooks/useLogs';
import TopHeader from './TopHeader';
// import TopHeader from './TopHeader';
import {Log} from './Types';

const Logs = () => {
	const {
		data,
		isLoading,
		dateRange,
		setCurrentPage,
		changeItemPerPage,
		setSearchTerm,
		changeQuery,
		setUserNameType,
		setDateRange,
	} = useLogs();
	const [logs, setLogs] = useState<string>();

	const debouncedSearch = useMemo(
		() =>
			debounce((val: string) => {
				setSearchTerm(val);
			}, 750),
		[setSearchTerm],
	);

	const handleSearch = useCallback(
		(e: ChangeEvent<HTMLInputElement>) => {
			debouncedSearch(e.target.value);
		},
		[debouncedSearch],
	);

	const sortColumn = (column: string) => {};

	const headers: Array<Header> = [
		{
			text: 'Application ID',
			isSortable: false,
		},
		{
			text: 'Date',
			isSortable: false,
		},
		{
			text: 'User',
			isSortable: false,
		},
		{
			text: 'Activity',
			isSortable: false,
		},
	];

	const rows: Array<Row> = (data?.data || []).map((log: Log) => {
		return {
			key: log.id,
			items: [log.application_id, log.created_at, log.user, log.log],
		};
	});

	return (
		<>
			<PageTitle breadcrumbs={[]}>Log</PageTitle>
			<TopHeader
				handleSearch={handleSearch}
				dateRange={dateRange}
				setDateRange={setDateRange}
				changeQuery={changeQuery}
				setUserNameType={setUserNameType}
				setLogs={setLogs}
			/>

			<WhiteCard
				leftHeader={<CardHeader title='Latest Activities' />}
				rightHeader={<div className='mb-10' />}
				extraClasses='p-9'
			>
				<DataTable
					headers={headers}
					isLoading={isLoading}
					data={logs ? rows : []}
					sortColumn={sortColumn}
					changeItemPerPage={changeItemPerPage}
					meta={data?.meta}
					links={data?.links}
					setCurrentPage={setCurrentPage}
				/>
			</WhiteCard>
		</>
	);
};

export default Logs;
