import {createContext, FC, useContext} from 'react';
import {WithChildren} from '../../../_metronic/helpers';
import {trimChar} from '../helpers';

export interface Config {
	publicUrl: string;
	apiUrl: string;
	reportApiUrl: string;
	makeApiUrl: (path: string) => string;
	makeReportApiUrl: (path: string) => string;

	azureLoginBaseUrl: string;
	azureLoginClientId: string;
	azureLoginTenantId: string;
	azureLoginRedirectUri: string;
	makeAzureLoginApiUrl: () => string;
}

const getEnvFromWindowOrProcess = (key: string) => {
	return Object.hasOwn(window, key) ? (window as any)[key] : process.env[key];
};

export const config: Config = {
	publicUrl: process.env.PUBLIC_URL,
	apiUrl: getEnvFromWindowOrProcess('REACT_APP_API_BASE_URL'),
	makeApiUrl: (path: string) => {
		return `${trimChar(getEnvFromWindowOrProcess('REACT_APP_API_BASE_URL') ?? '', '/')}/api/${trimChar(path, '/')}`;
	},

	reportApiUrl: getEnvFromWindowOrProcess('REACT_APP_API_REPORT_URL'),
	makeReportApiUrl: (path: string) => {
		return `${trimChar(getEnvFromWindowOrProcess('REACT_APP_API_REPORT_URL') ?? '', '/')}/api/${trimChar(path, '/')}`;
	},

	azureLoginBaseUrl: getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_BASE_URL'),
	azureLoginClientId: getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_CLIENT_ID'),
	azureLoginTenantId: getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_TENANT_ID'),
	azureLoginRedirectUri: getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_REDIRECT_URI'),

	makeAzureLoginApiUrl: () => {
		const baseUrl = trimChar(getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_BASE_URL') ?? '', '/');
		const tenantId = getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_TENANT_ID');
		const clientId = getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_CLIENT_ID');
		const redirectUrl = getEnvFromWindowOrProcess('REACT_APP_LDAP_REQUEST_REDIRECT_URI');

		return `${baseUrl}/${tenantId}/oauth2/v2.0/authorize?client_id=${clientId}&response_type=code&redirect_uri=${redirectUrl}&response_mode=query&scope=openid%20offline_access%20https%3A%2F%2Fgraph.microsoft.com%2Fmail.read&state=12345`;
	},
};

const ConfigContext = createContext<Config>(config);

export const useConfig = () => {
	return useContext(ConfigContext);
};

const ConfigProvider: FC<WithChildren> = ({children}) => {
	return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
};

export const useApiUrl = (path: string) => {
	const {makeApiUrl} = useConfig();
	return makeApiUrl(path);
};

export const useReportApiUrl = (path: string) => {
	const {makeReportApiUrl} = useConfig();
	return makeReportApiUrl(path);
};

export const useMakeAzureLoginApiUrl = () => {
	const {makeAzureLoginApiUrl} = useConfig();
	return makeAzureLoginApiUrl();
};

export default ConfigProvider;
