import {useFormik} from 'formik';
import {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {addOrRemove} from '../../../../common/helpers';
import {useAuthedMutation, useAuthedQuery} from '../../../../common/hooks/QueryHooks';
import {Permissions} from '../Permissions';

const schema = Yup.object().shape({
	name: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Name is required'),
	description: Yup.string().min(3, 'Minimum 3 symbols').max(250, 'Maximum 250 symbols'),
});

const initialValues = {
	name: undefined,
	description: undefined,
	permissions: [],
};

const useCreateRole = () => {
	const {mutate, isLoading} = useAuthedMutation('/users/role');
	const {data: permissions} = useAuthedQuery<Permissions>('permissions', `/users/role/permissions`);
	const [selectedPermissions, setSelectedPermissions] = useState<Array<string>>([]);
	const navigate = useNavigate();

	const togglePermission = (permission: string) => {
		setSelectedPermissions(addOrRemove(selectedPermissions, permission));
	};
	const formik = useFormik({
		initialValues,
		validationSchema: schema,
		onSubmit: async (values, {setStatus, setSubmitting}) => {
			try {
				const data = {name: values.name, description: values.description, permissions: selectedPermissions};
				await mutate({data});
				setStatus({message: 'Successfully Created', isError: false});
				setSubmitting(true);
				navigate('/roles');
			} catch (error) {
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment
				// @ts-ignore
				setStatus({message: error.response.data.message, isError: true});
				setSubmitting(false);
			}
		},
	});

	return {isLoading, formik, permissions, togglePermission};
};

export default useCreateRole;
