import AtomCard from '../AtomCard';

interface Props {
	header: string;
	stats: any[];
}

const LifecycleStat = ({stats, header}: Props) => {
	return (
		<div className='card pt-2 pb-6' style={{backgroundColor: '#FFFFFF'}}>
			<div className='d-flex flex-column gap-5'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex gap-5 align-items-center'>
						<span style={{color: '#0F172A', fontWeight: 'bold', fontSize: '16px'}}>{header}</span>
					</div>
				</div>

				<div className='d-flex gap-5 flex-wrap'>
					{stats.map((stat) => (
						<AtomCard key={`${stat.status}-${stat.amount}`} stats={stat} />
					))}
				</div>
			</div>
		</div>
	);
};

export default LifecycleStat;
