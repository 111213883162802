import {Modal} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import crossIcon from '../../../../../../assets/images/application/only_cross.svg';

interface Props {
	show: boolean;
	message: string;
	setShow: (show: boolean) => void;
}

const ErrorModal = ({show, message, setShow}: Props) => {
	return (
		<Modal show={show} aria-hidden='true' dialogClassName='modal-dialog modal-dialog-centered'>
			<div>
				<div className='modal-body scroll-y text-center'>
					<div className='container'>
						<div className='mb-12'>
							<SVG src={crossIcon} width={80} height={80} />
						</div>

						<div className='fs-1 fw-semibold mb-4' style={{color: '#3F4254'}}>
							{message}
						</div>
					</div>
				</div>
				<div className='modal-footer justify-content-center'>
					<button
						onClick={() => {
							setShow(false);
						}}
						type='submit'
						className='px-5 py-3 fw-bold fs-6 rounded-3	btn btn-success mx-4'
					>
						close
					</button>
				</div>
			</div>
		</Modal>
	);
};

export default ErrorModal;
