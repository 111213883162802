import {useState} from 'react';
import {ProductSlaData} from '../Types';
import Card from './Card';
import SLAItem from './SLAItem';

interface Props {
	sla: ProductSlaData;
}

export default function ProductSLACard({sla}: Props) {
	const [isTodaySelected, setIsTodaySelected] = useState(true);

	return (
		<Card
			title='Product wise Avg. Handling Time'
			setIsTodaySelected={setIsTodaySelected}
			isTodaySelected={isTodaySelected}
		>
			<div className='row'>
				<div className='col-6'>
					<SLAItem title='PRA' color='#00AFAF' sla={isTodaySelected ? sla.pra.today_count : sla.pra.yesterday_count} />
					<SLAItem
						title='MEDIUM'
						color='#4a7dff'
						sla={isTodaySelected ? sla.medium.today_count : sla.medium.yesterday_count}
					/>
				</div>
				<div className='col-6'>
					<SLAItem
						title='LITE A'
						color='#0095e8'
						sla={isTodaySelected ? sla.lite_a.today_count : sla.lite_a.yesterday_count}
					/>
					<SLAItem
						title='LITE B'
						color='#3F3CE1'
						sla={isTodaySelected ? sla.lite_b.today_count : sla.lite_b.yesterday_count}
					/>
				</div>
			</div>
		</Card>
	);
}
