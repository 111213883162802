// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".RoleTable_createRole__Ag5X7 input[type=checkbox] {\n  background-color: #F3F6F9;\n  border: 0;\n}\n.RoleTable_createRole__Ag5X7 input[type=checkbox]:checked {\n  background-color: #009ef7;\n  background-size: 14px;\n}", "",{"version":3,"sources":["webpack://./src/app/modules/users/pages/create-role/partials/RoleTable.module.scss"],"names":[],"mappings":"AACC;EACC,yBAAA;EACA,SAAA;AAAF;AAGC;EACC,yBAAA;EACA,qBAAA;AADF","sourcesContent":[".createRole {\n\tinput[type=\"checkbox\"] {\n\t\tbackground-color: #F3F6F9;\n\t\tborder: 0;\n\t}\n\n\t& input[type=\"checkbox\"]:checked {\n\t\tbackground-color: #009ef7;\n\t\tbackground-size: 14px;\n\t}\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"createRole": "RoleTable_createRole__Ag5X7"
};
export default ___CSS_LOADER_EXPORT___;
