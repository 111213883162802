import FilterButton from './FilterButton';

interface Props {
	isTodaySelected: boolean;
	setIsTodaySelected: (selectedState: boolean) => void;
}

const DateFilterButtons = ({isTodaySelected, setIsTodaySelected}: Props) => {
	const setToday = () => {
		setIsTodaySelected(true);
	};
	const setYesterday = () => {
		setIsTodaySelected(false);
	};

	return (
		<div className='d-flex justify-content-center'>
			<div className='me-2' role='button' tabIndex={0} onClick={setYesterday}>
				<FilterButton title='Yesterday' isActive={!isTodaySelected} />
			</div>

			<div role='button' tabIndex={0} onClick={setToday}>
				<FilterButton title='Today' isActive={isTodaySelected} />
			</div>
		</div>
	);
};
export default DateFilterButtons;
