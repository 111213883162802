import React, {useState, useEffect} from 'react';
import CustomBarChart from './CustomBar';
import DropButton from '../DropButton';
import useReportDashboard from '../../hooks/useReportDashboard';
import {filterBarByProduct} from '../../helpers/DashboardHelper';
import {BarPoint} from '../../Types';

const DashboardBars = () => {
	const header = 'Application Over Time';
	const productOptions = ['All Products', 'Lite-A', 'Lite-B', 'PRA'];
	const [selectedOption, setSelectedOption] = useState(productOptions[0]);

	// Fetch data from API
	const {appOverTime, data} = useReportDashboard();
	const [barData, setBarData] = useState<BarPoint[]>([]);

	// Update barData when appOverTime is available
	useEffect(() => {
		if (appOverTime.length > 0) {
			setBarData(appOverTime);
		}
	}, [appOverTime]);

	// Handle dropdown change and update barData
	const handleOptionChange = (option: string) => {
		setSelectedOption(option);
		if (option === productOptions[0]) {
			setBarData(appOverTime);
		} else {
			const filteredData = filterBarByProduct(data?.data.application_over_time, option);
			setBarData(filteredData);
		}
	};

	return (
		<div className='card px-8 pt-5 pb-8' style={{backgroundColor: '#FFFFFF'}}>
			<div className='d-flex flex-column gap-8'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex gap-5 align-items-center'>
						<span style={{color: '#0F172A', fontWeight: 'bold', fontSize: '16px'}}>{header}</span>
					</div>
					<div className='d-flex gap-4'>
						<DropButton selectedOption={selectedOption} options={productOptions} onOptionChange={handleOptionChange} />
					</div>
				</div>
				{/* Render bar chart only when data is available */}
				{barData.length > 0 ? <CustomBarChart barData={barData} /> : <p>No data available</p>}
			</div>
		</div>
	);
};

export default DashboardBars;
