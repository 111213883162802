import {Link} from 'react-router-dom';
import './EkycReports.css';

const EkycReports = () => {
	return (
		<div>
			<div className='row report-blocks'>
				<div className='col-lg-4'>
					<Link to='/reports/execution-detail-report'>
						<div className='report-block bg-white rounded'>
							<p>Execution</p>
							<h2>Execution Detail Report</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/daily-execution-report'>
						<div className='report-block bg-white rounded'>
							<p>Execution</p>
							<h2>Daily Execution Report</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/daily-execution-sla-report'>
						<div className='report-block bg-white rounded'>
							<p>Execution</p>
							<h2>Daily Execution SLA Report</h2>
						</div>
					</Link>
				</div>
			</div>

			<div className='row report-blocks'>
				<div className='col-lg-4'>
					<Link to='/reports/user-activity-report'>
						<div className='report-block bg-white rounded'>
							<p>Audit</p>
							<h2>User Activity Report</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/audit-log-report'>
						<div className='report-block bg-white rounded'>
							<p>Audit</p>
							<h2>Audit Log Report</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/bulk-accounts-audit-report'>
						<div className='report-block bg-white rounded'>
							<p>Audit</p>
							<h2>Bulk Accounts Audit Report</h2>
						</div>
					</Link>
				</div>
			</div>

			<div className='row report-blocks'>
				<div className='col-lg-4'>
					<Link to='/reports/merchant-ekyc-details'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>Merchant eKYC Details</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/bank-data-report'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>Bank Data Report</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/process-summary'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>Process Summary</h2>
						</div>
					</Link>
				</div>
			</div>

			<div className='row report-blocks'>
				<div className='col-lg-4'>
					<Link to='/reports/organization-settlement'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>Organization Settlement</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/footprint'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>Merchant Application Details</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/cs-execution-report'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>CS Execution Report</h2>
						</div>
					</Link>
				</div>
			</div>
			<div className='row report-blocks'>
				<div className='col-lg-4'>
					<Link to='/reports/gigatech-hit-report'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>Gigatech Hit Report</h2>
						</div>
					</Link>
				</div>
				<div className='col-lg-4'>
					<Link to='/reports/ec-hit-report'>
						<div className='report-block bg-white rounded'>
							<p>Application</p>
							<h2>Ec Hit Report</h2>
						</div>
					</Link>
				</div>
			</div>
		</div>
	);
};

export default EkycReports;
