interface Props {
	title: string;
	isActive?: boolean;
	optional?: string;
}

const FilterButton = ({title, isActive = true, optional = ''}: Props) => (
	<div
		className={`fs-7 p-1 rounded-2 cursor-pointer ${optional}`}
		style={isActive ? {background: '#3F4254'} : {background: '#FFFFFF'}}
		role='button'
		tabIndex={0}
	>
		<span className='fs-7 p-3' style={isActive ? {color: '#FFFFFF'} : {color: '#B5B5C3'}}>
			{title}
		</span>
	</div>
);

export default FilterButton;
