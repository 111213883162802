import {FC} from 'react';
import {WithChildren} from '../../../_metronic/helpers';

interface Props {
	isLoading: boolean;
	showIcon?: boolean;
	showText?: boolean;
}

const InButtonLoader: FC<WithChildren & Props> = ({children, isLoading, showIcon = true, showText = true}) => {
	return (
		<>
			{!isLoading && <span className='indicator-label'>{children}</span>}
			{isLoading && (
				<span className='indicator-progress' style={{display: 'block'}}>
					{showText && <>Please wait...</>}
					{showIcon && <span className='spinner-border spinner-border-sm align-middle ms-2' />}
				</span>
			)}
		</>
	);
};

export default InButtonLoader;
