import AtomCard from './AtomCard';

interface Props {
	header: string;
	userStats: any[];
	totalUsers: number | undefined;
	remainingTime: string;
}

const UserStats = ({header, userStats, totalUsers, remainingTime}: Props) => {
	return (
		<div className='card px-8 pt-5 pb-8' style={{backgroundColor: '#FFFFFF'}}>
			<div className='d-flex flex-column gap-8'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex gap-5 align-items-center'>
						<span style={{color: '#0F172A', fontWeight: 'bold', fontSize: '16px'}}>
							{header} (Total: {totalUsers ?? 0})
						</span>
						<span style={{color: '#64748B', fontSize: '14px', fontWeight: '500'}}>Refresh in {remainingTime} min</span>
					</div>
					<button
						className='btn dropdown-toggle'
						type='button'
						id='liveDropdown'
						data-bs-toggle='dropdown'
						aria-expanded='false'
						style={{
							minWidth: '83px',
							width: 'auto',
							height: '36px',
							borderRadius: '8px',
							border: '1px solid #E5E7EB',
							padding: '0 16px',
							fontSize: '14px',
							fontWeight: '500',
							color: '#6B7280',
							backgroundColor: '#F9FAFB',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							gap: '12px',
						}}
					>
						Live
					</button>
					{/* <DropButton selectedOption='Live' options={options} onOptionChange={() => {}} /> */}
				</div>

				<div className='d-flex gap-5 flex-wrap'>
					{userStats.map((stat) => (
						<AtomCard key={`${stat.status}-${stat.amount}`} stats={stat} />
					))}
				</div>
			</div>
		</div>
	);
};

export default UserStats;
