import {useFormik} from 'formik';
import {useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import * as Yup from 'yup';
import {useMutation} from '../../../../common/hooks/QueryHooks';
import {UserModel} from '../../common/Types';
import {useAuth} from '../../providers/AuthProvider';

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	password: Yup.string().min(3, 'Minimum 3 symbols').max(50, 'Maximum 50 symbols').required('Password is required'),
});

const initialValues = {
	email: undefined,
	password: undefined,
};

const useLogin = () => {
	const [loading, setLoading] = useState(false);
	const [azureLoading, setAzureLoading] = useState(false);
	const {login, logout} = useAuth();
	const {mutate} = useMutation<UserModel>('/login');
	const {mutate: mutateAzureToken} = useMutation<UserModel>('/login-with-azure/access-token');
	const {mutate: mutateAzureAuth} = useMutation<UserModel>('/login-with-azure/auth-code');
	const navigate = useNavigate();

	const loginWithAzureToken = async (accessToken: string | undefined, email: string | undefined) => {
		setAzureLoading(true);
		try {
			const res = await mutateAzureToken({
				data: {
					access_token: accessToken,
					email,
				},
			});
		} catch (error: any) {
			setAzureLoading(false);
		}
	};
	const loginWithAzureAuth = async (code: string | null, email?: string | undefined) => {
		try {
			const res = await mutateAzureAuth({
				data: {
					auth_code: code,
					email,
				},
			});
			login(res);
		} catch (error: any) {
			navigate('/auth', {state: {error: error.response.data.message}});
		}
	};

	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, {setStatus, setSubmitting}) => {
			setLoading(true);
			try {
				const data = {email: values.email, password: values.password};
				const res = await mutate({data});
				login(res);
			} catch (error: any) {
				if (error.response.status === 406) {
					setStatus(error.response.data.message);
				} else if (error.response.status === 500 && error.response.data.message === 'This user is already logged in.') {
					setStatus(error.response.data.message);
				} else if (error.response.status === 400) {
					setStatus(error.response.data.message);
				} else {
					setStatus('The login details are incorrect');
				}
				logout();
				setSubmitting(false);
				setLoading(false);
			}
		},
	});

	const isPasswordInvalid = formik.touched.password && formik.errors.password;

	const isPasswordValid = formik.touched.password && !formik.errors.password;

	return {
		loading,
		formik,
		isPasswordInvalid,
		isPasswordValid,
		loginWithAzureToken,
		azureLoading,
		loginWithAzureAuth,
	};
};

export default useLogin;
