import {useSearchParams} from 'react-router-dom';
import BkashLoader from '../../../../common/components/BkashLoader';
import useLogin from './useLogin';

const AzureAuth = () => {
	const [searchParams] = useSearchParams();
	const {loginWithAzureAuth} = useLogin();
	const code = searchParams.get('code');

	localStorage.setItem('code', JSON.stringify(code));

	loginWithAzureAuth(code);

	return (
		<div>
			<BkashLoader />
		</div>
	);
};

export default AzureAuth;
