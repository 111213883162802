import {lazy} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import {MasterLayout} from '../common/components/layout/MasterLayout';
import PermissionGuard from '../common/components/PermissionGuard';
import SuspensedView from '../common/components/SuspendedView';
import {getHomePageRoute} from '../common/helpers/RouteHelpers';
import {useAuth} from '../modules/auth';
import {Dashboard} from '../modules/dashboard/Dashboard';
import EkycReports from '../modules/ekyc-reports/pages';
import EkycReportTable from '../modules/ekyc-reports/pages/ekyc-report-section/EkycReportTable';
import Logs from '../modules/Logs/Logs';
import {Notifications} from '../modules/notifications/Notifications';
import RoleRoutes from '../modules/users/RoleRoutes';
import AutoVerifiedList from '../modules/auto-verified-list/AutoVerifiedList';
import ManuallyVerifiedList from '../modules/manually-verified-list/ManuallyVerifiedList';
import PosowUpload from '../modules/posow-upload/PosowUpload';

const PrivateRoutes = () => {
	const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'));
	const UserRoutes = lazy(() => import('../modules/users/UserRoutes'));
	const ConfigurationRoutes = lazy(() => import('../modules/configurations/ConfigurationRoutes'));
	const ApplicationRoutes = lazy(() => import('../modules/application/ApplicationRoutes'));
	const EkycReportBlocksRoutes = lazy(() => import('../modules/ekyc-reports/EkycReportsRoutes'));
	const {currentUserInfo} = useAuth();

	return (
		<Routes>
			<Route
				element={
					<PermissionGuard>
						<MasterLayout />
					</PermissionGuard>
				}
			>
				{/* Redirect to Dashboard after success login/registartion */}
				<Route path='auth/*' element={<Navigate to={getHomePageRoute(currentUserInfo)} />} />

				<Route path='dashboard' element={<Dashboard />} />
				<Route path='logs' element={<Logs />} />

				<Route
					path='applications/*'
					element={
						<SuspensedView>
							<ApplicationRoutes />
						</SuspensedView>
					}
				/>

				<Route
					path='cms/*'
					element={
						<SuspensedView>
							<ConfigurationRoutes />{' '}
						</SuspensedView>
					}
				/>

				<Route
					path='crafted/pages/profile/*'
					element={
						<SuspensedView>
							<ProfilePage />
						</SuspensedView>
					}
				/>
				<Route
					path='users/*'
					element={
						<SuspensedView>
							<UserRoutes />
						</SuspensedView>
					}
				/>
				<Route
					path='roles/*'
					element={
						<SuspensedView>
							<RoleRoutes />
						</SuspensedView>
					}
				/>
				<Route path='/auto-verified-list' element={<AutoVerifiedList />} />
				<Route path='/manually-verified-list' element={<ManuallyVerifiedList />} />
				<Route path='/posow-upload' element={<PosowUpload />} />

				<Route path='reports' element={<EkycReportTable />} />
				<Route
					path='reports/*'
					element={
						<SuspensedView>
							<EkycReportBlocksRoutes />
						</SuspensedView>
					}
				/>

				<Route path='ekyc-reports' element={<EkycReports />} />
				<Route path='notifications/*' element={<Notifications />} />

				<Route path='*' element={<Navigate to='/error/404' />} />
			</Route>
		</Routes>
	);
};

export default PrivateRoutes;
