import {FC} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from 'react-router-dom';
import App from '../App';
import {getHomePageRoute} from '../common/helpers/RouteHelpers';
import {useConfig} from '../common/providers/ConfigProvider';
import {AuthRoutes, useAuth} from '../modules/auth';
import {ErrorRoutes} from '../modules/errors';
import PrivateRoutes from './PrivateRoutes';

const AppRoutes: FC = () => {
	const {currentUser} = useAuth();
	const {publicUrl} = useConfig();

	return (
		<BrowserRouter basename={publicUrl}>
			<Routes>
				<Route element={<App />}>
					<Route path='error/*' element={<ErrorRoutes />} />
					{currentUser ? (
						<>
							<Route path='/*' element={<PrivateRoutes />} />
							<Route index element={<Navigate to={getHomePageRoute(currentUser.info)} />} />
						</>
					) : (
						<>
							<Route path='auth/*' element={<AuthRoutes />} />
							<Route path='*' element={<Navigate to='/auth' />} />
						</>
					)}
					<Route path='/health' element={<h3>Hey There !!! The App is Healthy</h3>} />
				</Route>
			</Routes>
		</BrowserRouter>
	);
};

export default AppRoutes;
