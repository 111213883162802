import Chart from 'react-apexcharts';

interface Props {
	labels: string[];
	series: number[];
}

export default function Donut({labels, series}: Props) {
	return (
		<div style={{position: 'relative'}}>
			<Chart
				options={{
					labels,
					dataLabels: {enabled: false},
					legend: {show: false},
					plotOptions: {
						pie: {
							donut: {
								labels: {
									show: true,
									name: {
										show: false,
									},
									value: {
										show: true,
										fontSize: '22px',
										fontFamily: 'Poppins, sans-serif',
										fontWeight: 700,
										color: '#464E5F',
									},
									total: {
										show: true,
									},
								},
							},
						},
					},
				}}
				series={series}
				type='donut'
				width='180'
			/>
		</div>
	);
}
