import {useEffect} from 'react';
import {useLocation} from 'react-router-dom';
import {hideSidebar} from '../../../../common/helpers/UIHelpers';
import {PageTitle} from '../../../../common/providers/PageDataProvider';
import {useAuth} from '../../../auth';
import {userHelpers} from '../../../auth/common/Types';
import ApplicationStats from '../../common/components/ApplicationStats';
import useApplicationPdfDownload from './hooks/useApplicationPdfDownload';
import PdfTablesection from '../../common/components/PdfTablesection';
import PdfSearchsection from '../../common/components/PdfSearchsection';
import OkayModal from '../../../../common/components/modals/OkayModal';
import ErrorModal from '../../../../common/components/modals/ErrorModal';
import tickIcon from '../../../../../assets/images/application/approved.svg';
import './ApplicationList.css';
import UploadModal from './UploadModal';
import { useNavigate } from "react-router-dom";

interface Props {
	pageName: string;
}

const ApplicationPdfDownload = ({pageName}: Props) => {
	const location = useLocation();
	const isPdfUpload = location.pathname.includes('pdf-upload');
	const navigate = useNavigate();
	const navigateTo = () => {
		navigate('/applications/download-history');
	};

	const {
		data,
		refetch,
		isLoading,
		setCurrentPage,
		changeItemsPerpage,
		setAccountType,
		setStatus,
		setMqcStatus,
		changeQuery,
		sortColumns,
		sortColumn,
		daterange,
		setDaterange,
		list,
		setList,
		setFormatType,
		pages,
		setPages,
		downloadPdf,
		closeSuccessModal,
		success,
		openSuccessModal,
		closeErrorModal,
		error,
		openErrorModal,
		pdfLoading,
		fileInputRef,
		handleFileChange,
		handleButtonClick,
		uploadedData,
		isUploading,
		selectedFile,
		formatType,
		uploadModal,
		setUploadModal,
		setSelectedFile,
		accountType
	} = useApplicationPdfDownload();

	const {currentUserInfo, currentUser} = useAuth();

	const isQc = currentUserInfo ? userHelpers.isQc(currentUserInfo) : false;

	useEffect(() => {
		if (!isLoading) hideSidebar();
	}, [isLoading]);

	useEffect(() => {
		if (currentUser?.isReady) {
			refetch();
		}
	}, [currentUser?.isReady, refetch]);

	const ids = isPdfUpload
		? uploadedData && uploadedData.map((item) => item.id)
		: data?.data.map((item) => item.id) ?? [];

	const handleSetPages = (page: string, checked: boolean) => {
		if (checked) {
			setPages([...pages, page]);
		} else {
			setPages([...pages.filter((item) => item !== page)]);
		}
	};

	useEffect(() => {
		setList([]);
		setFormatType(null);
		setPages([]);
	}, [pageName, setList, setFormatType, setPages]);

	return (
		<>
			<PageTitle breadcrumbs={[]}>{isPdfUpload ? 'Pdf Upload' : 'Pdf Download'}</PageTitle>
			<div>
				{isQc && <ApplicationStats />}

				<PdfSearchsection
					setAccountType={setAccountType}
					setStatus={setStatus}
					setMqcStatus={setMqcStatus}
					setFormatType={setFormatType}
					handleSetPages={handleSetPages}
					changeQuery={changeQuery}
					isPdfUpload={isPdfUpload}
					pages={pages}
					formatType={formatType}
					daterange={daterange}
					setDaterange={setDaterange}
					isQc={isQc}
					accountType={accountType}
				/>

				<PdfTablesection
					data={isPdfUpload ? uploadedData : data}
					refetch={refetch}
					isLoading={isLoading}
					setCurrentPage={setCurrentPage}
					changeItemsPerpage={changeItemsPerpage}
					sortColumns={sortColumns}
					sortColumn={sortColumn}
					downloadPdf={downloadPdf}
					isPdfUpload={isPdfUpload}
					selectedFile={selectedFile}
					isUploading={isUploading}
					pdfLoading={pdfLoading}
					fileInputRef={fileInputRef}
					setUploadModal={setUploadModal}
					handleFileChange={handleFileChange}
					handleButtonClick={handleButtonClick}
					ids={ids}
					list={list}
					setList={setList}
				/>
				<UploadModal
					show={uploadModal}
					setUploadModal={setUploadModal}
					selectedFile={selectedFile}
					isUploading={isUploading}
					handleFileChange={handleFileChange}
					handleButtonClick={handleButtonClick}
					setSelectedFile={setSelectedFile}
				/>
				<OkayModal icon={tickIcon} show={openSuccessModal} title={success} onClose={closeSuccessModal} onNavigate={navigateTo} navigateText="Go to History"/>
				<ErrorModal show={openErrorModal} title={error} onClose={closeErrorModal} />
			</div>
		</>
	);
};

export default ApplicationPdfDownload;
