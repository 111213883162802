import {ReactElement, useEffect} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import {useAuth} from '../../modules/auth';

interface Props {
	children: ReactElement;
}

export default function PermissionGuard({children}: Props) {
	const {currentUserInfo} = useAuth();
	const {pathname} = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		if (pathname === '/dashboard' && !currentUserInfo?.can_read_dashboard) {
			navigate('error/403');
		}
		if (pathname === '/logs' && !currentUserInfo?.can_read_log) {
			navigate('error/403');
		}
		if (pathname === '/applications/*' && !currentUserInfo?.can_read_application) {
			navigate('error/403');
		}
		if (pathname === '/cms/*' && !currentUserInfo?.can_read_configuration) {
			navigate('error/403');
		}
		if (pathname === '/users/*' && !currentUserInfo?.can_read_user) {
			navigate('error/403');
		}
		if (pathname === '/roles/*' && !currentUserInfo?.can_read_role) {
			navigate('error/403');
		}
		if (pathname === '/applications/all' && !currentUserInfo?.can_read_application) {
			navigate('error/403');
		}
		if (pathname === '/applications/in-queue' && !currentUserInfo?.can_read_queue_application) {
			navigate('error/403');
		}
	}, [pathname, currentUserInfo, navigate]);

	return children;
}
