import {useCallback} from 'react';
import {useAuth} from '../../modules/auth';
import {useAuthedMutation} from './QueryHooks';

const useReady = () => {
	const {currentUser, setReady, shouldShowOfflineBanner} = useAuth();
	const isReady = currentUser?.isReady ?? false;
	const canBeReady = currentUser?.info.can_be_ready ?? false;

	const {mutate, isLoading} = useAuthedMutation<{is_ready: boolean}>('/toggle-ready');

	const toggleReady = useCallback(async () => {
		const res = await mutate({});
		setReady(res.is_ready);
	}, [mutate, setReady]);

	const showOfflineBanner = shouldShowOfflineBanner && canBeReady;
	return {isReady, toggleReady, isLoading, showOfflineBanner};
};

export default useReady;
