import React from 'react';

interface Props {
	count: number;
	unit?: string;
	status: string;
	color: string;
	background: string;
	marginBottom?: boolean;
	colCount?: number;
	marginRight?: boolean;
}

const UserStatusItem = ({
	count,
	unit,
	status,
	color,
	background,
	marginBottom = false,
	colCount = 3,
	marginRight = false,
}: Props) => {
	return (
		<div className={`col-${colCount} flex-fill`}>
			<div
				className={`p-3 px-5 rounded-3 ${marginRight ? 'me-4' : ''} ${marginBottom ? 'mb-5' : ''} flex`}
				style={{background}}
			>
				<div className='fw-semibold fs-5' style={{color}}>
					{status}
				</div>
				<div className='fw-bold fs-2hx' style={{color}}>
					{count} <span className='fs-1'>{unit}</span>
				</div>
			</div>
		</div>
	);
};

export default UserStatusItem;
