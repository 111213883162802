import {useState} from 'react';
import {ProductCounter} from '../Types';
import Card from './Card';
import './Chart.css';
import CounterCard from './CounterCard';
import Donut from './Donut';

interface Props {
	counters: ProductCounter;
}

export default function ProductCountCard({counters}: Props) {
	const {pra, medium, lite_a: liteA, lite_b: liteB} = counters;
	const [isTodaySelected, setIsTodaySelected] = useState(true);

	return (
		<Card title='Total Applied' setIsTodaySelected={setIsTodaySelected} isTodaySelected={isTodaySelected}>
			<div className='row d-flex flex-row'>
				<div className='col-4'>
					<Donut
						series={
							isTodaySelected
								? [pra.today_count, medium.today_count, liteA.today_count, liteB.today_count]
								: [pra.yesterday_count, medium.yesterday_count, liteA.yesterday_count, liteB.yesterday_count]
						}
						labels={['PRA', 'Medium', 'Lite A', 'Lite B']}
					/>
				</div>
				<div className='col-4'>
					<CounterCard
						count={isTodaySelected ? pra.today_count : pra.yesterday_count}
						status='PRA'
						color='#0095E8'
						background='#ECF8FF'
						marginBottom
					/>
					<CounterCard
						count={isTodaySelected ? liteA.today_count : liteA.yesterday_count}
						status='Lite A'
						color='#F79B11'
						background='#FFF5E7'
					/>
				</div>
				<div className='col-4'>
					<CounterCard
						count={isTodaySelected ? medium.today_count : medium.yesterday_count}
						status='Medium'
						color='#47BE7D'
						background='#E8FFF3'
						marginBottom
					/>
					<CounterCard
						count={isTodaySelected ? liteB.today_count : liteB.yesterday_count}
						status='Lite B'
						color='#D9214E'
						background='#FFF5F8'
					/>
				</div>
			</div>
		</Card>
	);
}
