import React from 'react';
import ReactDOM from 'react-dom/client';
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReactQueryDevtools} from 'react-query/devtools';
import {PublicClientApplication} from '@azure/msal-browser';
import {MsalProvider} from '@azure/msal-react';
import ConfigProvider from './app/common/providers/ConfigProvider';
import DataProvider from './app/common/providers/DataProvider';
import ServiceLocatorProvider from './app/common/providers/ServiceLocatorProvider';
import {AuthProvider} from './app/modules/auth';
import AppRoutes from './app/routing/AppRoutes';
import bootstrap from './bootstrap';
import {MetronicI18nProvider} from './_metronic/i18n/Metronici18n';
import './_metronic/assets/sass/plugins.scss';
import './_metronic/assets/sass/style.react.scss';
import './_metronic/assets/sass/style.scss';
import './assets/styles/globals.scss';
import {msalConfig} from './app/modules/auth/authConfig';
import {MsalConfig} from './app/modules/auth/Types';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
const queryClient = new QueryClient();
const services = bootstrap();

const msalInstance = new PublicClientApplication(msalConfig as MsalConfig);

root.render(
	<React.StrictMode>
		<MsalProvider instance={msalInstance}>
			<ConfigProvider>
				<ServiceLocatorProvider services={services}>
					<QueryClientProvider client={queryClient}>
						<MetronicI18nProvider>
							<AuthProvider>
								<DataProvider>
									<AppRoutes />
								</DataProvider>
							</AuthProvider>
						</MetronicI18nProvider>
						<ReactQueryDevtools initialIsOpen={false} />
					</QueryClientProvider>
				</ServiceLocatorProvider>
			</ConfigProvider>
		</MsalProvider>
	</React.StrictMode>,
);
