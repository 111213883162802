import {Link} from 'react-router-dom';
import {getHomePageRoute} from '../../../common/helpers/RouteHelpers';
import {useAuth} from '../../auth';

const ReturnHomeButton = () => {
	const {currentUserInfo} = useAuth();
	return (
		<div className='mb-0'>
			<Link to={getHomePageRoute(currentUserInfo)} className='btn btn-sm btn-primary'>
				Return Home
			</Link>
		</div>
	);
};

export default ReturnHomeButton;
