import {Modal} from 'react-bootstrap';
import SVG from 'react-inlinesvg';
import {ModalProps} from './Types';

interface Props {
	icon: string;
}

export default function OkayModal({show, title, message, onClose, icon}: ModalProps & Props) {
	return (
		<Modal
			id='kt_modal_review_result_app'
			tabIndex={-1}
			aria-hidden='true'
			dialogClassName='modal-dialog modal-dialog-centered'
			show={show}
			onHide={onClose}
			backdrop
		>
			<div className='modal-body scroll-y text-center'>
				<div className='container pt-4'>
					<div className='mb-12'>
						<SVG height={80} width={80} src={icon} />
					</div>

					{title && (
						<div className='fs-1 fw-semibold mb-4' style={{color: '#3F4254'}}>
							{title}
						</div>
					)}
					<p className='fs-4' style={{color: '#3F4254'}}>
						{message}
					</p>
				</div>
			</div>

			<div className='modal-footer justify-content-center'>
				<button
					type='button'
					className='px-5 py-3 fw-bold fs-6 rounded-3 table-action-btn mx-4'
					onClick={() => onClose()}
				>
					Close
				</button>
			</div>
		</Modal>
	);
}
