import {FC, useEffect} from 'react';
import {ILayout, useLayout} from '../../../../../_metronic/layout/core';
import {usePageData} from '../../../providers/PageDataProvider';

const updateDOM = (config: ILayout) => {
	if (config.app?.header?.default?.fixed?.desktop) {
		document.body.setAttribute('data-kt-app-header-fixed', 'true');
	}

	if (config.app?.header?.default?.fixed?.mobile) {
		document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true');
	}

	if (config.app?.header?.default?.stacked) {
		document.body.setAttribute('data-kt-app-header-stacked', 'true');
	}

	const appHeaderDefaultStickyEnabled = config.app?.header?.default?.sticky?.enabled;
	let appHeaderDefaultStickyAttributes: {[attrName: string]: string} = {};
	if (appHeaderDefaultStickyEnabled) {
		appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky?.attributes as {
			[attrName: string]: string;
		};
	}

	const appHeaderDefaultMinimizeEnabled = config.app?.header?.default?.minimize?.enabled;
	let appHeaderDefaultMinimizeAttributes: {[attrName: string]: string} = {};
	if (appHeaderDefaultMinimizeEnabled) {
		appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize?.attributes as {
			[attrName: string]: string;
		};
	}

	setTimeout(() => {
		const headerElement = document.getElementById('kt_app_header');
		// header
		if (headerElement) {
			const headerAttributes = headerElement.getAttributeNames().filter((t) => t.indexOf('data-') > -1);
			headerAttributes.forEach((attr) => headerElement.removeAttribute(attr));

			if (appHeaderDefaultStickyEnabled) {
				Object.entries(appHeaderDefaultStickyAttributes).forEach(([key, value]) => {
					headerElement.setAttribute(key, value);
				});
			}

			if (appHeaderDefaultMinimizeEnabled) {
				Object.entries(appHeaderDefaultMinimizeAttributes).forEach(([key, value]) => {
					headerElement.setAttribute(key, value);
				});
			}
		}
	}, 0);
};

const Header: FC = () => {
	const {config} = useLayout();
	useEffect(() => {
		updateDOM(config);
	}, [config]);

	const {pageTitle} = usePageData();

	return (
		<div className='fs-2 fw-semibold my-6' style={{color: '#212121'}}>
			{pageTitle}
		</div>
	);
};

export {Header};
