import {FC, Suspense} from 'react';
import TopBarProgress from 'react-topbar-progress-indicator';
import {getCSSVariableValue} from '../../../_metronic/assets/ts/_utils';
import {WithChildren} from '../../../_metronic/helpers';

const SuspensedView: FC<WithChildren> = ({children}) => {
	const baseColor = getCSSVariableValue('--kt-primary');
	TopBarProgress.config({
		barColors: {
			'0': baseColor,
		},
		barThickness: 1,
		shadowBlur: 5,
	});
	return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export default SuspensedView;
