import DebouncedSearch from '../../../../common/components/DebouncedSearch';
import './EkycReportTable.css';

const EkycReportFilter = () => {
	return (
		<div className='row s-reportFilterSection my-9 flex-row d-flex g-0'>
			<div className='col-md-12 col-xl-7 d-flex'>
				<div className='mx-1'>
					<select className='form-select form-select-solid' style={{backgroundColor: '#ffffff', borderRadius: '6px'}}>
						<option value='volvo'>Filter 1</option>
						<option value='saab'>Saab</option>
						<option value='opel'>Opel</option>
						<option value='audi'>Audi</option>
					</select>
				</div>
				<div className='mx-2'>
					<select className='form-select form-select-solid' style={{backgroundColor: '#ffffff', borderRadius: '6px'}}>
						<option value='volvo'>Filter 1</option>
						<option value='saab'>Saab</option>
						<option value='opel'>Opel</option>
						<option value='audi'>Audi</option>
					</select>
				</div>
				<div className='mx-2'>
					<select className='form-select form-select-solid' style={{backgroundColor: '#ffffff', borderRadius: '6px'}}>
						<option value='volvo'>Filter 1</option>
						<option value='saab'>Saab</option>
						<option value='opel'>Opel</option>
						<option value='audi'>Audi</option>
					</select>
				</div>
				<div className='mx-2'>
					<select className='form-select form-select-solid' style={{backgroundColor: '#ffffff', borderRadius: '6px'}}>
						<option value='volvo'>Filter 1</option>
						<option value='saab'>Saab</option>
						<option value='opel'>Opel</option>
						<option value='audi'>Audi</option>
					</select>
				</div>
				<div className='mx-2'>
					<select className='form-select form-select-solid' style={{backgroundColor: '#ffffff', borderRadius: '6px'}}>
						<option value='volvo'>Filter 1</option>
						<option value='saab'>Saab</option>
						<option value='opel'>Opel</option>
						<option value='audi'>Audi</option>
					</select>
				</div>
			</div>
			<div className='col-md-12 col-xl-5 s-searchinput d-flex justify-content-xl-end'>
				<div className='d-flex margintopSearch'>
					<div className='d-flex align-items-center position-relative'>
						<DebouncedSearch placeholder='Search' setSearchTerm={() => {}} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default EkycReportFilter;
