import DropButton from '../../DropButton';
import LifecycleStat from './LifecycleStat';
import useLifecycleStats from '../../../hooks/useLifecycleStats';
import {AppLifecycleStats} from '../../../Types';

interface Props {
	header: string;
	lifecycleStats: AppLifecycleStats | undefined;
}

const LifecycleStats = ({header, lifecycleStats}: Props) => {
	const timeOptions = ['Today', 'Yesterday', 'MTD'];

	const {isLoading, handleOptionChange, selectedOption, monthlyLifecycleStats} = useLifecycleStats(
		timeOptions[1].toLowerCase(),
		lifecycleStats,
	);

	return (
		<div className='card px-8 pt-5 pb-8' style={{backgroundColor: '#FFFFFF'}}>
			<div className='d-flex flex-column gap-8'>
				<div className='d-flex justify-content-between align-items-center'>
					<div className='d-flex gap-5 align-items-center'>
						<span style={{color: '#0F172A', fontWeight: 'bold', fontSize: '16px'}}>{header}</span>
					</div>
					<DropButton selectedOption={selectedOption} options={timeOptions} onOptionChange={handleOptionChange} />
				</div>

				<div className='d-flex gap-5 flex-wrap'>
					{isLoading ? (
						<div
							style={{
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								width: '100%',
								height: '100%',
								textAlign: 'center',
								minHeight: '200px',
							}}
						>
							<span>Loading...</span>
						</div>
					) : (
						<div className='d-flex flex-column gap-1'>
							{monthlyLifecycleStats.map((lifecycleStat) => (
								<LifecycleStat key={lifecycleStat.header} header={lifecycleStat.header} stats={lifecycleStat.stats} />
							))}
						</div>
					)}
				</div>
			</div>
		</div>
	);
};

export default LifecycleStats;
