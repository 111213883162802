import {useState, useEffect} from 'react';
import {trimCharStart} from '../../../common/helpers';
import {convertToMysqlDateString} from '../../../common/helpers/DateHelper';
import {addQueryParamsToUrl} from '../../../common/helpers/UrlHelper';
import {useAuthedQuery} from '../../../common/hooks/QueryHooks';
import {LogTableData} from '../Types';

const useLogs = () => {
	const [currentPage, setCurrentPage] = useState(1);
	const [itemPerPage, setItemPerPage] = useState(10);
	const [searchTerm, setSearchTerm] = useState<string | undefined>();
	const [queryType, setQueryType] = useState(<string | null>null);
	const [queryValue, setQueryValue] = useState(<string | null>null);
	const [userNameType, setUserNameType] = useState(<number | null>null);
	const [dateRange, setDateRange] = useState([new Date(), new Date()]);

	// const [userOptionData, setUserOptiondata] = useState(<string | null>null);

	const url = addQueryParamsToUrl('/logs', {
		page: currentPage,
		limit: itemPerPage,
		search: searchTerm,
		query_type: queryType,
		query_value: queryValue,
		user_id: userNameType,
		created_at: [
			['start', convertToMysqlDateString(dateRange[0])],
			['end', convertToMysqlDateString(dateRange[1])],
		],
	});

	const changeItemPerPage = (page: number) => {
		setCurrentPage(1);
		setItemPerPage(page);
	};

	const {data, isLoading} = useAuthedQuery<LogTableData>(trimCharStart(url, '/'), url);

	const changeQuery = (t: string | null, v: string) => {
		if (t === null || v === '') {
			setQueryType(null);
			setQueryValue(null);
		} else {
			setQueryType(t);
			setQueryValue(v);
		}
	};

	return {
		data,
		isLoading,
		dateRange,
		setCurrentPage,
		setSearchTerm,
		changeItemPerPage,
		changeQuery,
		setUserNameType,
		setDateRange,
	};
};

export default useLogs;
