import clsx from 'clsx';
import {useState} from 'react';
import {useLocation} from 'react-router-dom';
import AzureLogo from '../../../../../assets/images/auth/azure.svg';
import InButtonLoader from '../../../../common/components/InButtonLoader';
import {useMakeAzureLoginApiUrl} from '../../../../common/providers/ConfigProvider';
import useLogin from './useLogin';

export default function Login() {
	const {loading, formik, isPasswordInvalid, isPasswordValid} = useLogin();
	const [showPassword, setShowPassword] = useState(false);
	const azureloginRedirectUrl = useMakeAzureLoginApiUrl();
	const location = useLocation();

	const toggleShowPassword = () => {
		setShowPassword((current) => !current);
	};

	const getPasswordToggleBtnBorder = () => {
		if (isPasswordInvalid) return '#f1416c';
		if (isPasswordValid) return '#50cd89';
		return 'var(--kt-input-border-color)';
	};

	const startAzureFlow = () => {
		window.location.replace(azureloginRedirectUrl);
	};

	return (
		<form className='form w-100' onSubmit={formik.handleSubmit} noValidate id='kt_login_signin_form'>
			<div className='mb-11'>
				<h1 className='fs-2hx fw-bold mb-5' style={{color: `#2F3044`}}>
					Login
				</h1>
				<p className='mb-2'>Continue with Azure or enter your details.</p>
				<button
					type='button'
					className='btn btn-outline-success my-2 w-100'
					style={{border: '1px solid'}}
					onClick={startAzureFlow}
				>
					<img src={AzureLogo} alt='' className='me-2' />
					Login with Azure AD
				</button>

				<div className='d-flex align-items-center mt-5'>
					<div style={{height: 1}} className='bg-primary w-50'>
						{' '}
					</div>
					<h4 className='px-2 text-center'>or</h4>
					<div style={{height: 1}} className='bg-primary w-50'>
						{' '}
					</div>
				</div>
			</div>

			{location.state && (location.state as any).error && (
				<div className='mb-lg-15 alert alert-danger'>
					<div className='alert-text font-weight-bold'>{(location.state as any).error}</div>
				</div>
			)}

			{formik.status && (
				<div className='mb-lg-15 alert alert-danger'>
					<div className='alert-text font-weight-bold'>{formik.status}</div>
				</div>
			)}

			<div className='fv-row mb-9'>
				<label className='form-label fs-6 fw-bold' style={{color: `#2F3044`}}>
					Email
				</label>
				<input
					placeholder='Email'
					{...formik.getFieldProps('email')}
					className={clsx(
						'form-control',
						{
							'is-invalid': formik.touched.email && formik.errors.email,
						},
						{
							'is-valid': formik.touched.email && !formik.errors.email,
						},
					)}
					style={{backgroundColor: '#EEF1F5', fontWeight: 900}}
					type='email'
					name='email'
					autoComplete='off'
				/>
				{formik.touched.email && formik.errors.email && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.email}</span>
						</div>
					</div>
				)}
			</div>

			<div className='fv-row mb-9'>
				<label className='form-label fs-6 fw-bold' style={{color: `#2F3044`}}>
					Password
				</label>
				<div className='input-group'>
					<input
						type={showPassword ? 'text' : 'password'}
						autoComplete='off'
						{...formik.getFieldProps('password')}
						className={clsx(
							'form-control',
							{
								'is-invalid': isPasswordInvalid,
							},
							{
								'is-valid': isPasswordValid,
							},
						)}
						style={{backgroundColor: '#EEF1F5', borderRight: 'none', fontWeight: 900}}
					/>
					<div className='input-group-append'>
						<button
							type='button'
							className='btn btn-light'
							onClick={toggleShowPassword}
							style={{
								backgroundColor: '#EEF1F5',
								border: `1px solid ${getPasswordToggleBtnBorder()}`,
								borderLeft: 'none',
								borderBottomLeftRadius: '0px',
								borderTopLeftRadius: '0px',
							}}
						>
							{showPassword ? <i className='bi bi-eye-slash' /> : <i className='bi bi-eye' />}
						</button>
					</div>
				</div>

				{isPasswordInvalid && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>

			<div className='mb-10'>
				<button
					type='submit'
					id='kt_sign_in_submit'
					className='btn btn-primary w-100'
					disabled={formik.isSubmitting || !formik.isValid}
				>
					<InButtonLoader isLoading={loading} showIcon showText>
						Login with Password
					</InButtonLoader>
				</button>
			</div>
		</form>
	);
}
