// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore

import DateRangePicker from '@wojtekmaj/react-daterange-picker';
import {useEffect} from 'react';
import DebouncedSearch from '../../common/components/DebouncedSearch';
import useAutoVerifiedListSearch from './useAutoVerifiedListSearch';

interface Props {
	dateRange: Array<Date>;
	setDateRange: (dates: Array<Date>) => void;
	setStatus: (status: string) => void;
	changeQuery: (queryType: string | null, queryValue: string) => void;
}

const AutoVerifiedListReportFilter = ({dateRange, setDateRange, setStatus, changeQuery}: Props) => {
	const {queryTypesData, changeCurrentQueryType, currentQueryType} = useAutoVerifiedListSearch();

	const handleQueryTypes = (e: any) => {
		changeCurrentQueryType(e.target.value);
	};

	useEffect(() => {
		(document?.getElementById('search-form') as HTMLFormElement).reset();
	}, [currentQueryType]);

	return (
		<div className='row  my-9 flex-row d-flex g-0'>
			<div className='d-flex justify-content-between'>
				<div className='d-flex'>
					<div className='calendarWrap'>
						<DateRangePicker
							calendarAriaLabel='Toggle calendar'
							clearAriaLabel='Clear value'
							dayAriaLabel='Day'
							monthAriaLabel='Month'
							nativeInputAriaLabel='Date'
							onChange={setDateRange}
							value={dateRange}
							yearAriaLabel='Year'
							clearIcon={null}
						/>
					</div>

					<div className='mx-2'>
						<select
							className='form-select form-select-solid'
							style={{backgroundColor: '#ffffff', borderRadius: '6px'}}
							onChange={(e) => {
								setStatus(e.target.value);
							}}
						>
							<option value=''>Status</option>
							<option value='Valid'>Valid</option>
							<option value='Error'>Error</option>
							<option value='Invalid'>Invalid</option>
						</select>
					</div>
				</div>
				<div className='d-flex'>
					<select
						className='form-select form-select-solid'
						data-kt-select2='true'
						data-placeholder='Role'
						data-allow-clear='true'
						data-kt-user-table-filter='role'
						data-hide-search='true'
						style={{backgroundColor: '#ffffff', borderRadius: '6px', width: 'auto'}}
						onChange={handleQueryTypes}
					>
						{queryTypesData &&
							queryTypesData?.data.map((po: any) => (
								<option value={po.key} key={po.key} style={{color: '#A1A5B7'}}>
									{po.value}
								</option>
							))}
					</select>
					<form id='search-form'>
						<div className='d-flex align-items-center position-relative'>
							<DebouncedSearch
								setSearchTerm={(searchTerm: string) => {
									changeQuery(currentQueryType?.key ?? null, searchTerm);
								}}
								placeholder={`Search by ${currentQueryType?.value}`}
							/>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default AutoVerifiedListReportFilter;
