import {LogLevel} from '@azure/msal-browser';

export const msalConfig = {
	auth: {
		clientId: '7972dc3d-9ef2-49bf-8278-0b1b419ce912',
		authority: 'https://login.microsoftonline.com/66c51543-b7d1-4e11-b289-a05a3cc0f143',
		redirectUri: 'http://localhost:3011',
	},
	cache: {
		cacheLocation: 'sessionStorage',
		storeAuthStateInCookie: false,
	},
	system: {
		loggerOptions: {},
	},
};

export const loginRequest = {
	scopes: ['User.Read'],
};

export const graphConfig = {
	graphMeEndpoint: 'https://graph.microsoft.com/v1.0/me',
};
