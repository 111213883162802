import {useState} from 'react';
import {toggleSortOrder} from '../../../common/components/data-table/Types';
import {trimCharStart} from '../../../common/helpers';
import {addQueryParamsToUrl} from '../../../common/helpers/UrlHelper';
import {useAuthedQuery} from '../../../common/hooks/QueryHooks';
import {SortColumns} from '../pages/role-list/Types';
import styles from '../pages/user-list/UserList.module.scss';
import {RoleList} from './Types';

const colorClassName = [
	`${styles.role1Tag}`,
	`${styles.role2Tag}`,
	`${styles.role3Tag}`,
	`${styles.role4Tag}`,
	`${styles.role5Tag}`,
	`${styles.role6Tag}`,
	`${styles.role7Tag}`,
	`${styles.role8Tag}`,
	`${styles.role9Tag}`,
	`${styles.role10Tag}`,
	`${styles.unknownTag}`,
];

const useRoleList = () => {
	const [sortColumns, setSortColumns] = useState<SortColumns>({});

	const url = addQueryParamsToUrl('/users/role', {
		sort: Object.entries(sortColumns),
	});

	const sortColumn = (key: keyof SortColumns) => {
		const newSortColumns = {...sortColumns};
		newSortColumns[key] = toggleSortOrder(sortColumns[key]);
		setSortColumns(newSortColumns);
	};
	const {data, isLoading, refetch} = useAuthedQuery<RoleList>(trimCharStart(url, '/'), url);

	const colorMap = new Map();
	if (data) {
		data.data.map((value, index) => colorMap.set(value.name, colorClassName[index]));
	}
	colorMap.set('N/A', colorClassName[10]);
	return {data, colorMap, isLoading, sortColumns, sortColumn, refetch};
};

export default useRoleList;
