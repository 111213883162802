import {useReportQuery} from '../../../common/hooks/QueryHooks';
import {getAvgQcTimes} from '../helpers/DashboardHelper';
import {AvgQcTimes, ChartPoint} from '../Types';

const useAvgQc = (timeRange: [string, string]) => {
	const [startTime, endTime] = timeRange;
	const url = `/dashboard/average-qc?start_time=${startTime}&end_time=${endTime}`;

	const {data, isLoading} = useReportQuery<AvgQcTimes>(url, url);

	const filteredAvgQcTimes: ChartPoint = getAvgQcTimes(data);

	return {isLoading, filteredAvgQcTimes, timeFilteredData: data};
};

export default useAvgQc;
