/* eslint-disable jsx-a11y/anchor-is-valid */

import useProductSwitch from '../../../hooks/useProductSwitch';
import {Product} from '../../../types/Products';
import InButtonLoader from '../../InButtonLoader';

interface Props {
	current?: Product | null;
	assigned?: Array<Product> | null;
}

const ProductSwitcher = ({current, assigned}: Props) => {
	const {switchProduct, isLoading} = useProductSwitch();
	return (
		<>
			<a
				href='#'
				className='btn px-5 py-3 fw-bold fs-6 btn-active-light-primary btn-custom'
				style={{color: '#0095E8', background: '#ECF8FF', border: '1px solid #0095E8', borderRadius: '8px'}}
				data-kt-menu-trigger='hover'
				data-kt-menu-attach='parent'
				data-kt-menu-placement='bottom-end'
			>
				<InButtonLoader isLoading={isLoading} showIcon showText>
					{current?.display_name ?? 'All Products'}
					<i className='fa-sharp fa-solid fa-caret-down ms-3' style={{marginTop: '-4px'}} />
				</InButtonLoader>
			</a>

			<div
				className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold py-4 fs-base w-175px'
				data-kt-menu='true'
			>
				{assigned?.map((product) => (
					<div key={product.name} className='menu-item px-3 my-0'>
						<a
							href='#'
							className='menu-link px-3 py-2'
							onClick={() => {
								switchProduct(product);
							}}
						>
							<span className='menu-title'>{product.display_name}</span>
						</a>
					</div>
				))}
			</div>
		</>
	);
};

export default ProductSwitcher;
