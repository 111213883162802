import DashboardBars from './partials/bars/DashboardBars';
import DashboardCharts from './partials/charts/DashboardCharts';
import DashboardStats from './partials/statistics/DashboardStats';

const NewDashboard = () => {
	return (
		<div className='d-flex flex-column gap-10'>
			<DashboardStats />
			<DashboardCharts />
			<DashboardBars />
		</div>
	);
};

export default NewDashboard;
