import {useState} from 'react';
import useReady from '../../../hooks/useReady';
import ReadyModal from './ReadyModal';

const ReadyButton = () => {
	const {isReady, toggleReady, isLoading} = useReady();
	const [show, setShow] = useState(false);

	const style = {
		color: isReady ? '#D9214E' : '#2BA579',
		background: isReady ? '#FFF5F8' : '#DFF1EB',
		border: `1px solid ${isReady ? '#D9214E' : '#2BA579'}`,
		borderRadius: '8px',
	};

	return (
		<>
			<button
				className='px-5 py-3 fw-bold fs-6'
				style={style}
				onClick={() => {
					setShow(true);
				}}
				disabled={isLoading}
			>
				Go {isReady ? 'Offline' : 'Online'}
			</button>
			<ReadyModal isReady={isReady} isLoading={isLoading} show={show} setShow={setShow} toggleReady={toggleReady} />
		</>
	);
};

export default ReadyButton;
