import React from 'react';
import {SlaData} from '../Types';
import ProductSLACard from './ProductSlaCard';
import SLACard from './SLACard';

interface SLADataProps {
	sla: SlaData;
}

export function SlaArea({sla}: SLADataProps) {
	return (
		<div className='row mb-0'>
			<div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
				<ProductSLACard sla={sla.product_wise_sla} />
			</div>
			<div className='col-md-6 col-lg-6 col-xl-6 col-xxl-6'>
				<SLACard sla={sla.user_wise_sla} />
			</div>
		</div>
	);
}
