import {useCallback} from 'react';
import {useAuth} from '../../modules/auth';
import {Product} from '../types/Products';
import {useAuthedMutation} from './QueryHooks';

const useProductSwitch = () => {
	const {switchProduct: switchProductOnLocal} = useAuth();
	const {mutate, isLoading} = useAuthedMutation('/switch-product');

	const switchProduct = useCallback(
		async (product: Product) => {
			const data = {
				product: product.name,
			};
			await mutate({data});
			switchProductOnLocal(product);
		},
		[mutate, switchProductOnLocal],
	);

	return {switchProduct, isLoading};
};

export default useProductSwitch;
