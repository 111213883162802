import {useState} from 'react';
import {useReportQuery} from '../../../common/hooks/QueryHooks';
import {getLifecycleStats} from '../helpers/DashboardHelper';

import {AppLifecycleStats, FilteredAppLifecycleStats} from '../Types';

const useLifecycleStats = (timeline: string, lifecycleStats: AppLifecycleStats | undefined) => {
	const [time, setTime] = useState(timeline);

	const url = `/dashboard/lifecycle-stats?timeline=${time}`;

	const {data, isLoading} = useReportQuery<FilteredAppLifecycleStats | undefined>(url, url);

	const timeOptions = ['Today', 'Yesterday', 'MTD'];

	const [selectedOption, setSelectedOption] = useState(timeOptions[0]);

	const handleOptionChange = (option: string) => {
		setSelectedOption(option);
		if (option !== timeOptions[0]) {
			setTime(option.toLowerCase());
		}
	};

	const monthlyLifecycleStats =
		selectedOption === timeOptions[0] ? getLifecycleStats(lifecycleStats) : getLifecycleStats(data?.data);

	return {data, isLoading, handleOptionChange, selectedOption, monthlyLifecycleStats};
};

export default useLifecycleStats;
