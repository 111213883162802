import {useCallback, useState} from 'react';
import {useAuth} from '../../modules/auth';
import {RoleMode, userHelpers} from '../../modules/auth/common/Types';
import {useAuthedMutation} from './QueryHooks';

const getToggleText = (targetMode: RoleMode) => {
	if (targetMode === RoleMode.ECAD) return 'Switch to ECAD Mode';
	if (targetMode === RoleMode.Supervisor) return 'Switch to Supervising Mode';
	return 'Switch to QC Mode';
};

const useRoleMode = () => {
	const {currentUserInfo, setRoleMode} = useAuth();
	const {getToggleRoleModeTarget} = userHelpers;
	const [errorModal, setErrorModal] = useState(false);

	const toggleText = currentUserInfo ? getToggleText(getToggleRoleModeTarget(currentUserInfo)) : '';

	const {mutate, isLoading} = useAuthedMutation<{current_mode: RoleMode}>('/toggle-role-mode');

	const toggleRoleMode = useCallback(async () => {
		try {
			const res = await mutate({});
			setRoleMode(res.current_mode);
		} catch (err) {
			setErrorModal(true);
		}
	}, [mutate, setRoleMode]);

	return {toggleText, toggleRoleMode, isLoading, errorModal, setErrorModal};
};

export default useRoleMode;
