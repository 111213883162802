import {useState} from 'react';
import {UserSLAData} from '../Types';
import Card from './Card';
import SLAItem from './SLAItem';

interface Props {
	sla: UserSLAData;
}

export default function SLACard({sla}: Props) {
	const [isTodaySelected, setIsTodaySelected] = useState(true);

	return (
		<Card
			title='User wise Avg. Handling Time'
			setIsTodaySelected={setIsTodaySelected}
			isTodaySelected={isTodaySelected}
		>
			<div className='row'>
				<div className='col-6'>
					<SLAItem
						title='QC'
						color='#00AFAF'
						sla={isTodaySelected ? sla.avg_qc.today_count : sla.avg_qc.yesterday_count}
					/>
					<SLAItem
						title='QC Pool 1'
						color='#4a7dff'
						sla={isTodaySelected ? sla.pool1_qc.today_count : sla.pool1_qc.yesterday_count}
					/>
				</div>
				<div className='col-6'>
					<SLAItem
						title='QC Pool 2'
						color='#0095e8'
						sla={isTodaySelected ? sla.pool2_qc.today_count : sla.pool2_qc.yesterday_count}
					/>
					<SLAItem
						title='ECAD'
						color='#3F3CE1'
						sla={isTodaySelected ? sla.ecad.today_count : sla.ecad.yesterday_count}
					/>
				</div>
			</div>
		</Card>
	);
}
