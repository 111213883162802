import {createContext, FC, useContext, useEffect, useMemo, useState} from 'react';
import {WithChildren} from '../../../_metronic/helpers';

export interface PageLink {
	title: string;
	path: string;
	isActive: boolean;
	isSeparator?: boolean;
}

export interface PageDataContextModel {
	pageTitle?: string;
	setPageTitle: (_title: string) => void;
	pageDescription?: string;
	setPageDescription: (_description: string) => void;
	pageBreadcrumbs?: Array<PageLink>;
	setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => void;
	showTitleInBreadcrumb?: boolean;
	setShowTitleInBreadcrumb: (_value: boolean) => void;
}

const PageDataContext = createContext<PageDataContextModel>({
	setPageTitle: (_title: string) => {
		console.log(_title);
	},
	setPageBreadcrumbs: (_breadcrumbs: Array<PageLink>) => {
		console.log(_breadcrumbs);
	},
	setPageDescription: (_description: string) => {
		console.log(_description);
	},
	showTitleInBreadcrumb: true,
	setShowTitleInBreadcrumb: (_value: boolean) => {
		console.log(_value);
	},
});

const PageDataProvider: FC<WithChildren> = ({children}) => {
	const [pageTitle, setPageTitle] = useState<string>('');
	const [pageDescription, setPageDescription] = useState<string>('');
	const [pageBreadcrumbs, setPageBreadcrumbs] = useState<Array<PageLink>>([]);
	const [showTitleInBreadcrumb, setShowTitleInBreadcrumb] = useState<boolean>(true);
	const value: PageDataContextModel = useMemo(() => {
		return {
			pageTitle,
			setPageTitle,
			pageDescription,
			setPageDescription,
			pageBreadcrumbs,
			setPageBreadcrumbs,
			showTitleInBreadcrumb,
			setShowTitleInBreadcrumb,
		};
	}, [pageTitle, pageDescription, pageBreadcrumbs, showTitleInBreadcrumb]);
	return <PageDataContext.Provider value={value}>{children}</PageDataContext.Provider>;
};

function usePageData() {
	return useContext(PageDataContext);
}

type Props = {
	description?: string;
	breadcrumbs?: Array<PageLink>;
	showTitleInBreadcrumb?: boolean;
};

const PageTitle: FC<Props & WithChildren> = ({children, description, breadcrumbs, showTitleInBreadcrumb = true}) => {
	const {setPageTitle, setPageDescription, setPageBreadcrumbs, setShowTitleInBreadcrumb} = usePageData();
	useEffect(() => {
		if (children) {
			setPageTitle(children.toString());
		}
		return () => {
			setPageTitle('');
		};
	}, [children, setPageTitle]);

	useEffect(() => {
		if (description) {
			setPageDescription(description);
		}
		return () => {
			setPageDescription('');
		};
	}, [description, setPageDescription]);

	useEffect(() => {
		if (breadcrumbs) {
			setPageBreadcrumbs(breadcrumbs);
		}
		return () => {
			setPageBreadcrumbs([]);
		};
	}, [breadcrumbs, setPageBreadcrumbs]);

	useEffect(() => {
		if (showTitleInBreadcrumb) {
			setShowTitleInBreadcrumb(true);
		}
		return () => {
			setShowTitleInBreadcrumb(false);
		};
	}, [showTitleInBreadcrumb, setShowTitleInBreadcrumb]);

	return null;
};

const PageDescription: FC<WithChildren> = ({children}) => {
	const {setPageDescription} = usePageData();
	useEffect(() => {
		if (children) {
			setPageDescription(children.toString());
		}
		return () => {
			setPageDescription('');
		};
	}, [children, setPageDescription]);

	return null;
};

export {PageDescription, PageTitle, PageDataProvider, usePageData};
