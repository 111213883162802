import React from 'react';

interface Props {
	status: string;
	amount: number | undefined;
	percentage: number | undefined;
	circleColor: string;
}

interface AtomCardProps {
	stats: Props;
}

const AtomCard = ({stats}: AtomCardProps) => {
	const {status, amount, percentage, circleColor} = stats;

	return (
		<div
			className='px-4 py-5 d-flex justify-content-between align-items-center'
			style={{
				width: '205px',
				height: '110px',
				border: '1px solid #D1D1D1',
				borderRadius: '10px',
			}}
		>
			<div className='d-flex flex-column' style={{color: '#515151'}}>
				<div style={{fontSize: '20px', lineHeight: '27px', fontWeight: '500'}}>{status}</div>
				<div className='fw-bold' style={{fontSize: '48px', lineHeight: '52px'}}>
					{amount ?? 0}
				</div>
			</div>

			<div className='d-flex flex-column gap-5 align-items-end'>
				<span
					className='rounded-circle mb-2'
					style={{
						width: '16px',
						height: '16px',
						backgroundColor: circleColor,
					}}
				/>
				<span style={{color: '#7E8299', fontWeight: 500, fontSize: '20px'}}>{percentage ?? 0}%</span>
			</div>
		</div>
	);
};

export default AtomCard;
