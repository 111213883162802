import {PaginationLinks, PaginationMeta} from '../components/data-table/Types';

export interface Notification {
	id: string;
	title: string;
	created_at: Date;
	is_read: boolean;
	target_type: 'application' | null;
	target_id: number | null;
}

export interface NotificationList {
	data: Array<Notification>;
	links: PaginationLinks;
	meta: PaginationMeta;
	unread_count?: number | undefined;
}

export interface UpdatableNotificationData {
	id: string;
}

export function buildTargetUrl(notification: Notification): string {
	if (notification.target_type === null) return '#';

	return `applications/${notification.target_id}`;
}
